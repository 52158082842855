import axios from 'axios';

export const performRequest = async (method, url, data = null, options = {}) => {
    try {
        const config = {
            method: method,
            url: url,
            ...(data && { data: data }),
            ...options, // 전달된 옵션 추가
        };
        const response = await axios(config);

        if (response.status === 200 || response.status === 201) {
            if (options.responseType == 'blob') {
                return {
                    success: true,
                    data: response.data,
                    error: '',
                };
            }
            else {
                return {
                    success: response.data.success,
                    data: response.data.data,
                    error: '',
                };
            }


        } else {
            return {
                success: response.data.success,
                error: response.data.data,
            };
        }
    } catch (err) {
        return {
            success: false,
            error: err.response ? err.response.data : err.message,
        };
    }
};
