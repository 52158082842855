import React, { useEffect, useState } from 'react';
import '../styles/Header.scss';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const [logoImage, setLogoImage] = useState();
    const logos = [
        '/images/logo-1.svg',
        '/images/logo-2.svg',
        '/images/logo-4.svg',
    ];
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[randomIndex]);
    }, []);

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleGoHome = () => {
        navigate('/');
    };

    // const handleMembershipClick = () => {
    //     navigate('/membership');
    // };

    const handleHomeClick = () => {
        navigate('/');
    };

    const handleInfoClick = () => {
        navigate('/information');
    };

    const handleFunctionClick = () => {
        navigate('/function');
    };

    const handlePriceClick = () => {
        navigate('/price');
    };

    const handleGuideClick = () => {
        navigate('/guide');
    };

    const handleContactClick = () => {
        navigate('/contact');
    };

    const toggleHamburgerMenu = () => {
        setShowHamburgerMenu(!showHamburgerMenu); // 햄버거 메뉴 표시 상태 토글
    };

    return (
        <header id="header">
            <div className="inner flex">
                <h1
                    className="logo"
                    onClick={handleGoHome}
                >
                    <img
                        src={logoImage}
                        alt="logo"
                    />
                </h1>
                <div className="header-left">
                    <ul className="navi">
                        <li onClick={handleInfoClick}>플랫폼 소개</li>
                        <li onClick={handleFunctionClick}>주요기능</li>
                        <li onClick={handlePriceClick}>요금제</li>
                        <li onClick={handleGuideClick}>가이드</li>
                        <li onClick={handleContactClick}>문의하기</li>
                    </ul>
                    <ul className="utils">
                        <li
                            className="btn login-btn"
                            onClick={handleLoginClick}
                        >
                            시작하기
                        </li>
                        {/* <li
                            className="btn sign-btn"
                            onClick={handleMembershipClick}
                        >
                            회원가입
                        </li> */}
                    </ul>
                </div>
                <div className="hamburger-mobile">
                    <button onClick={toggleHamburgerMenu}>
                        <img
                            src={'/images/nav/ham.svg'}
                            alt="hamburger"
                        />
                    </button>
                    {showHamburgerMenu && (
                        <div className="ham-fixed">
                            <div
                                className="close-m"
                                onClick={() => {
                                    {
                                        setShowHamburgerMenu(false);
                                    }
                                }}
                            >
                                <button>
                                    <figure>
                                        <img
                                            src={'/images/nav/close-m.svg'}
                                            alt=""
                                        />
                                    </figure>
                                </button>
                            </div>
                            <ul className="navi">
                                <li onClick={handleHomeClick}>홈</li>
                                <li onClick={handleInfoClick}>플랫폼 소개</li>
                                <li onClick={handleFunctionClick}>주요기능</li>
                                <li onClick={handlePriceClick}>요금제</li>
                                <li onClick={handleGuideClick}>가이드</li>
                                <li onClick={handleContactClick}>문의하기</li>
                                <li className="utils">
                                    <div
                                        className="btn login-btn"
                                        onClick={handleLoginClick}
                                    >
                                        시작하기
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
