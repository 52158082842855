import React, { useState } from 'react';
import '../styles/ViewAllOptionsModal.scss';

const ViewAllOptionsModal = ({ selection_list, onClose, onSelect }) => {
    const selection_titles = selection_list.selection_title;
    const selection_images = selection_list.selection_image;
    const [selectedItem, setSelectedItem] = useState(null);

    console.log(selection_list);

    const handleItemClick = (title, index) => {
        const selectedOption = {
            kr: title.kr,
            en: title.en,
            image: selection_images[index],
        };
        setSelectedItem(selectedOption);
    };

    const handleConfirmClick = () => {
        if (selectedItem) {
            onSelect(selectedItem);
        }
        onClose();
    };

    return (
        <div
            className="modal-overlay"
            onClick={onClose}
        >
            <div
                className="modal-contents"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    className="close-button"
                    onClick={onClose}
                >
                    X
                </button>
                <h3>{selection_list.title}을 골라볼까요?</h3>
                <div className="modal-items">
                    {selection_titles.map((title, index) => (
                        <div
                            className={`modal-item ${
                                selectedItem && selectedItem.kr === title.kr
                                    ? 'selected'
                                    : ''
                            }`}
                            key={index}
                            onClick={() => handleItemClick(title, index)}
                        >
                            <figure>
                                <img
                                    src={selection_images[index]}
                                    alt={title.kr}
                                    onError={(e) => {
                                        e.target.src =
                                            '/images/default-image.jpg';
                                    }}
                                />
                            </figure>
                            <p>{title.kr}</p>
                        </div>
                    ))}
                </div>
                <button
                    className="confirm-button"
                    onClick={handleConfirmClick}
                >
                    확인
                </button>
            </div>
        </div>
    );
};

export default ViewAllOptionsModal;
