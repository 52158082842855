import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/TrainingHeader.scss';

const HubHeader = () => {
    const navigation = useNavigate();
    const [logoImage, setLogoImage] = useState();

    // 랜덤 로고 이미지
    const logos = [
        '/images/logo-6.svg',
        '/images/logo-5.svg',
        '/images/logo-3.svg',
    ];

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[randomIndex]);
    }, []);

    const handleGoHome = async () => {
        navigation('/home');
    };

    return (
        <div className="drawing-header">
            <div className="inner">
                <button className="drawing-logo">
                    <img
                        src={logoImage}
                        alt="logo"
                    />
                </button>
                <button
                    onClick={handleGoHome}
                    className="go-hub-btn"
                >
                    홈으로 돌아가기
                </button>
            </div>
        </div>
    );
};

export default HubHeader;
