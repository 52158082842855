import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/WritingList.scss';

const WritingList = ({
    creationList,
    randomImage,
    openModal,
    page_type,
    showCheckboxes,
    selectedItems = [],
    setSelectedItems,
    onSelectItem,
}) => {
    const navigate = useNavigate();

    const printDate = (date) => {
        const result_date = date.split('T');
        return result_date[0];
    };

    const handleWritingClick = () => {
        navigate('/promptCategory/writing');
    };

    const handleCheckboxChange = (creation_id, isChecked) => {
        onSelectItem(creation_id, isChecked);
    };

    useEffect(() => {
        if (!showCheckboxes) {
            setSelectedItems([]);
        }
    }, [showCheckboxes, setSelectedItems]);

    if (creationList.length === 0) {
        return (
            <>
                <div className="empty-contents">
                    <figure>
                        <img
                            src={'/images/twins_2_test.svg'}
                            alt=""
                        />
                    </figure>
                    <p>
                        아직 글 만들기 작품이 없어요!
                        <br className="mobile-only" />
                        <span>작품을 만들러 가볼까요?</span>
                    </p>
                    <button onClick={handleWritingClick}>
                        글 만들기 체험하러 가기
                    </button>
                </div>
            </>
        );
    }

    return (
        <ul className="writing-list">
            {creationList.map((creation, index) => {
                const isChecked = selectedItems.includes(creation.creation_id);
                return (
                    <li
                        key={index}
                        className={`writing ${showCheckboxes && isChecked ? 'border' : ''
                            }`}
                    >
                        {showCheckboxes && (
                            <>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={(e) =>
                                        handleCheckboxChange(
                                            creation.creation_id,
                                            e.target.checked
                                        )
                                    }
                                />
                                <label
                                    htmlFor={`checkbox-${creation.creation_id}`}
                                >
                                    <p className="check">✔</p>
                                </label>
                            </>
                        )}
                        <div
                            onClick={() => {
                                if (!showCheckboxes) {
                                    openModal({
                                        title: creation.creation_title,
                                        creation: creation.creation,
                                    });
                                } else {
                                    handleCheckboxChange(
                                        creation.creation_id,
                                        !isChecked
                                    );
                                }
                            }}
                        >
                            <figure className="paper">
                                <img
                                    src={randomImage}
                                    alt="writing-paper"
                                />
                            </figure>
                            <div className="writing-txt">
                                <h2 className="writing-tit">
                                    {creation.creation_title}
                                </h2>
                                {page_type === 'school' && (
                                    <h2 className="writing-tit">
                                        [{creation.name}]
                                    </h2>
                                )}
                                <p className="peom-create-date">
                                    {printDate(creation.creation_date)}
                                </p>
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default WritingList;
