import { SUPPORT_ENDPOINT } from './utils/EndPoint';
import { performRequest } from './utils/RequestUtils';

export const getPDFDownloadURL = async () => {
    return performRequest(
        'get',
        `${SUPPORT_ENDPOINT}/pdf`
    );
};

export const sendEmail = async (contact_point, username, company, email, phone, region, contents) => {
    return performRequest('post', `${SUPPORT_ENDPOINT}/send/email`, {
        contact_point,
        username,
        company,
        email,
        phone,
        region,
        contents
    });
};