import { MAGICBEAN_ENDPOINT } from './utils/EndPoint';
import { performRequest } from './utils/RequestUtils';

export const getTotalMagicbean = async (user_id) => {
    return performRequest('get', `${MAGICBEAN_ENDPOINT}/total/${user_id}`);
};

export const getMagicbeanHistory = async (user_id) => {
    return performRequest('get', `${MAGICBEAN_ENDPOINT}/history/${user_id}`);
};
