import React, { useRef, useEffect } from 'react';
import '../styles/TrCompleteComponent.scss';
import confetti from 'canvas-confetti';

const TrCompleteComponent = ({ onRestart, onEnd, selected_magickid }) => {
    const isTeacher = localStorage.getItem('isTeacher') === 'true';
    const drawingAudioRef = useRef(null);

    useEffect(() => {
        if (drawingAudioRef.current) {
            drawingAudioRef.current.play().catch((error) => {
                console.error('오디오 재생에 실패했습니다.', error);
            });
        }
        firework();
    }, []);

    function firework() {
        confetti({
            particleCount: 100,
            spread: 160,
            origin: { x: 0.5, y: 0.5 },
        });
    }

    function handleComponentClick(event) {
        event.stopPropagation();
        firework();
    }

    return (
        <>
            <audio
                ref={drawingAudioRef}
                src="/audio/success-trumpets.mp3"
                preload="auto"
            />
            <div
                className="tr-bg"
                onClick={handleComponentClick}
            >
                <div className="inner">
                    <figure className="experience-flag">
                        <img
                            src={'/images/experience.svg'}
                            alt="체험완료"
                        />
                    </figure>
                    <h2 className="experience-txt">참 잘했어요!</h2>
                    <p className="experience-sub-txt">체험이 재미있었나요?</p>
                    <div className="char-says-bubble">
                        <div className="say-bubble">
                            <button
                                onClick={onRestart}
                                className="start-all-over"
                            >
                                처음부터 다시하기
                            </button>
                            <button
                                onClick={onEnd}
                                className="another-experience"
                            >
                                다른체험 하기
                            </button>
                            {/* {isTeacher && (
                                <button className="print-experience">
                                    프린트하기
                                </button>
                            )} */}
                        </div>
                        <figure>
                            <img
                                src={
                                    selected_magickid === 'arial'
                                        ? '/images/arial-pink.svg'
                                        : '/images/nikki.svg'
                                }
                                alt=""
                            />
                        </figure>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrCompleteComponent;
