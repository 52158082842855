import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BigSizeModal from './BigSizeModal';
import '../styles/DrawingList.scss';

const DrawingList = ({
    creationList,
    openModal,
    onSelectItem,
    showCheckboxes,
    selectedItems,
    setSelectedItems,
}) => {
    const navigate = useNavigate();
    const [showBigModal, setShowBigModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleDrawingClick = () => {
        navigate('/promptCategory/drawing');
    };

    const handleBigViewClick = (image) => {
        setSelectedImage(image);
        setShowBigModal(true);
    };

    const handleCheckboxChange = (creation_id, isChecked) => {
        onSelectItem(creation_id, isChecked);
    };

    const handleTestClick = (creation_id, isChecked) => {
        handleCheckboxChange(creation_id, !isChecked);
    };

    useEffect(() => {
        if (!showCheckboxes) {
            setSelectedItems([]); // 선택 모드를 취소할 때 모든 선택을 초기화
        }
    }, [showCheckboxes]);

    if (creationList.length === 0) {
        return (
            <>
                <div className="empty-contents">
                    <figure className="drawing">
                        <img
                            src={'/images/twins_3_test.svg'}
                            alt=""
                        />
                    </figure>
                    <p>
                        아직 그림 작품이 없어요!
                        <br className="mobile-only" />
                        <span>작품을 만들러 가볼까요?</span>
                    </p>
                    <button onClick={handleDrawingClick}>
                        그림그리기 체험하러 가기
                    </button>
                </div>
            </>
        );
    }

    return (
        <ul className="drawing-list">
            {creationList.map((creation, index) => {
                const isChecked = selectedItems.includes(creation.creation_id);
                return (
                    <li key={index}>
                        <div className="polaroid-bg">
                            {showCheckboxes && (
                                <>
                                    <input
                                        id={`checkbox-${creation.creation_id}`}
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                creation.creation_id,
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <label
                                        htmlFor={`checkbox-${creation.creation_id}`}
                                    >
                                        <p className="check">✔</p>
                                    </label>
                                </>
                            )}
                            <div
                                className={`test`}
                                onClick={() => {
                                    if (showCheckboxes) {
                                        handleTestClick(
                                            creation.creation_id,
                                            isChecked
                                        );
                                    }
                                }}
                            >
                                <img
                                    className={`${showCheckboxes && isChecked
                                        ? 'border'
                                        : ''
                                        }`}
                                    src={creation.creation}
                                    alt={`작품 ${index + 1}`}
                                />
                                <div
                                    className="wide-big-btn"
                                    onClick={() =>
                                        handleBigViewClick(creation.creation)
                                    }
                                >
                                    <figure>
                                        <img
                                            src={'/images/big-white.svg'}
                                            alt=""
                                        />
                                    </figure>
                                    <span>크게보기</span>
                                </div>
                                <div className="text-info">
                                    <p>{creation.creation_title}</p>
                                    <p>
                                        {new Date(
                                            creation.creation_date
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            })}
            {showBigModal && (
                <BigSizeModal
                    resultContent={selectedImage}
                    onClose={() => setShowBigModal(false)}
                />
            )}
        </ul>
    );
};

export default DrawingList;
