import React, { useState, useEffect } from 'react';
import { toggleFavoriteTraining } from '../api/ClassAPI';

const TrViewAllTrainingComponent = ({
    userId,
    trainingData,
    toggleFavorites,
    favorites,
    onItemClick,
    hubType,
    isTeacher,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const handleFavoriteClick = (event, trainingId) => {
        event.stopPropagation();
        callToggleFavoriteAPIFunc(trainingId);
    };

    const callToggleFavoriteAPIFunc = async (training_id) => {
        const response = await toggleFavoriteTraining(
            userId,
            hubType,
            training_id
        );

        if (response.success) {
            console.log(response.data);
            //NOTE: favorite toggle에 하트 채워지기
            toggleFavorites(training_id); // 즐겨찾기 상태 토글
        } else {
            console.log(response.error.errMsg);
        }
    };

    const handleSearch = () => {
        setSearchQuery(searchTerm);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        if (searchTerm === '') {
            setSearchQuery('');
        }
    }, [searchTerm]);

    const filteredTrainingData = trainingData.filter((item) =>
        item.training_topic_title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            {isTeacher && (
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="검색어를 입력해주세요"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="search-input"
                    />
                    <button
                        onClick={handleSearch}
                        className="search-button"
                    >
                        검색
                    </button>
                </div>
            )}

            <ul className="all-view-training-container">
                {filteredTrainingData.map((item, index) => (
                    <li
                        key={index}
                        onClick={() => onItemClick(item.training_id)}
                    >
                        <div className="badge-container">
                            {item.badge === 'new' && (
                                <figure className="badge new-badge">
                                    <img
                                        src={'/images/new-badge.svg'}
                                        alt=""
                                    />
                                </figure>
                            )}
                            {item.badge === 'best' && (
                                <figure className="badge best-badge">
                                    <img
                                        src={'/images/best-badge.svg'}
                                        alt=""
                                    />
                                </figure>
                            )}
                        </div>
                        <div className={`square-image-box`}>
                            <figure>
                                <img
                                    src={item.training_image}
                                    alt={item.training_topic_title}
                                />
                            </figure>
                        </div>
                        <p className="title">{item.training_topic_title}</p>
                        <div
                            className="circle"
                            onClick={(event) =>
                                handleFavoriteClick(event, item.training_id)
                            }
                        >
                            {favorites[item.training_id] ? (
                                <img
                                    src={'/images/heart-filled.svg'}
                                    alt="Unfavorite"
                                />
                            ) : (
                                <img
                                    src={'/images/heart-empty.svg'}
                                    alt="Favorite"
                                />
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TrViewAllTrainingComponent;
