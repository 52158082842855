import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/TestPage.scss';

const TestPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const manualType = params.get('manual');

    const getImageSrc = (type) => {
        switch (type) {
            case 'teacher':
                return '/images/teacher-manual.svg';
            case 'drawing':
                return '/images/drawing-manual.svg';
            case 'writing':
                return '/images/writing-manual.svg';
            default:
                return;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <figure>
            <img
                src={getImageSrc(manualType)}
                alt=""
            />
        </figure>
    );
};

export default TestPage;
