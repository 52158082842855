import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    EffectCoverflow,
    Navigation,
    Pagination,
    A11y,
    Autoplay,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { toggleFavoriteTraining } from '../api/ClassAPI';

const HubList = ({
    userId,
    trainingData,
    toggleFavorites,
    favorites,
    onItemClick,
    hubType,
}) => {
    const handleFavoriteClick = (event, trainingId) => {
        event.stopPropagation();
        callToggleFavoriteAPIFunc(trainingId);
    };

    console.log('trainingData', trainingData);

    const callToggleFavoriteAPIFunc = async (training_id) => {
        const response = await toggleFavoriteTraining(
            userId,
            hubType,
            training_id
        );

        if (response.success) {
            console.log(response.data);
            //NOTE: favorite toggle에 하트 채워지기
            toggleFavorites(training_id); // 즐겨찾기 상태 토글
        } else {
            console.log(response.error.errMsg);
        }
    };

    return (
        <Swiper
            // modules={[Navigation, Pagination]}
            // loop={true}
            // spaceBetween={80}
            // slidesPerView={3}
            // navigation={true}
            // pagination={{ clickable: true }}
            // className="square-swiper-wrapper"

            // effect
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }}
            pagination={{ clickable: true }}
            loop={true}
            modules={[EffectCoverflow, Pagination]}
            className="square-swiper-wrapper"
        >
            {trainingData.map((item, index) => (
                <SwiperSlide
                    key={index}
                    className={`square-slide ${hubType === 'writing' ? 'circle-shape' : ''
                        }`}
                    onClick={() => onItemClick(item.training_id)}
                >
                    {/* NOTE : 음성 버튼 주석처리 */}
                    {/* <div
                        className={`music-state-btn-container ${hubType === 'drawing' ? 'music-drawing' : ''
                            }`}
                    >
                        <button>
                            <img
                                src={'/images/music-on.svg'}
                                alt="music-on"
                            />
                        </button>
                        <button>
                            <img
                                src={'/images/music-off.svg'}
                                alt="music-off"
                            />
                        </button>
                    </div> */}
                    {/* NOTE : New, Best badge */}
                    <div className="badge-container">
                        {item.badge === 'new' && (
                            <figure className="badge new-badge">
                                <img
                                    src={'/images/new-badge.svg'}
                                    alt=""
                                />
                            </figure>
                        )}
                        {item.badge === 'best' && (
                            <figure className="badge best-badge">
                                <img
                                    src={'/images/best-badge.svg'}
                                    alt=""
                                />
                            </figure>
                        )}
                    </div>
                    <div
                        className={`square-image-box ${hubType === 'writing' ? 'circle-image-box' : ''
                            }`}
                    >
                        <figure>
                            <img
                                src={item.training_image}
                                alt={item.training_topic_title}
                            />
                        </figure>
                    </div>
                    <p>{item.training_topic_title}</p>
                    <div
                        className="circle"
                        onClick={(event) =>
                            handleFavoriteClick(event, item.training_id)
                        }
                    >
                        {favorites[item.training_id] ? (
                            <img
                                src={'/images/heart-filled.svg'}
                                alt="Unfavorite"
                            />
                        ) : (
                            <img
                                src={'/images/heart-empty.svg'}
                                alt="Favorite"
                            />
                        )}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default HubList;
