import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/WritingHub.scss';
import HubCategory from '../components/HubCategory';
import HubList from '../components/HubList';
import { getTrainingList } from '../api/ClassAPI';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Hubheader from '../components/HubHeader';
import SchoolTitle from '../components/SchoolTitle';

const WritingHub = () => {
    const USER_ID = localStorage.getItem('userID');
    const USER_SCHOOLNAME = localStorage.getItem('userSchoolName');
    const USER_TYPE = localStorage.getItem('userType');
    const trainingType = 'writing';
    const [trainingData, setTrainingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [favorites, setFavorites] = useState({});

    const Navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            const response = await getTrainingList(USER_ID, trainingType, USER_TYPE);
            if (response.success) {
                setTrainingData(response.data);
                setFilteredData(response.data);
                const favs = response.data.reduce((acc, cur) => {
                    acc[cur.training_id] = cur.is_favorite;
                    return acc;
                }, {});
                setFavorites(favs);
            } else {
                console.log(response.error.errMsg);
            }
        };
        fetchData();
    }, [trainingType]);

    const toggleFavorites = (training_id) => {
        setFavorites((prev) => ({
            ...prev,
            [training_id]: !prev[training_id],
        }));
    };

    const handleCategoryChange = (category) => {
        if (category === '전체') {
            setFilteredData(trainingData);
        } else {
            setFilteredData(
                trainingData.filter(
                    (item) => item.training_category === category
                )
            );
        }
    };

    const handleItemClick = (trainingId) => {
        Navigate(`/writingtraining/writing/${trainingId}`);
    };

    const handleViewAll = () => {
        Navigate('/fav-conts', { state: { initialCategory: 1 } });
    };

    const handleViewAllTrainings = () => {
        Navigate('/allview', { state: { trainingType: 'writing' } });
    };

    return (
        <>
            <Hubheader />
            <div className="wrapper">
                <div className="inner">
                    <SchoolTitle school_name={USER_SCHOOLNAME} user_type={USER_TYPE} />
                    <h2 className="hub-title">어떤 주제로 글을 써볼까요?</h2>
                    <HubCategory
                        trainingData={trainingData}
                        onCategoryChange={handleCategoryChange}
                    />
                    <HubList
                        userId={USER_ID}
                        trainingData={filteredData}
                        toggleFavorites={toggleFavorites}
                        favorites={favorites}
                        onItemClick={handleItemClick}
                        hubType={trainingType}
                    />
                    <div
                        className="all-conts-btn"
                        onClick={handleViewAll}
                    >
                        <figure>
                            <img
                                src={'/images/fav-heart.svg'}
                                alt=""
                            />
                        </figure>
                        <p>즐겨찾기</p>
                    </div>
                    <div
                        className="all-view-btn"
                        onClick={handleViewAllTrainings}
                    >
                        <figure>
                            <img
                                src={'/images/grid.svg'}
                                alt=""
                            />
                        </figure>
                        <p>전체보기</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WritingHub;
