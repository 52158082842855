import React, { useRef, useEffect } from 'react';
import '../styles/AnswerCorrectModal.scss';
import confetti from 'canvas-confetti';

const AnswerCorrectModal = ({ onClose }) => {
    const audioRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play().catch((error) => {
                console.error('오디오 재생에 실패했습니다.', error);
            });
        }
        firework();
    }, []);

    function firework() {
        const myConfetti = confetti.create(canvasRef.current, {
            resize: true,
            useWorker: true,
        });
        myConfetti({
            particleCount: 100,
            spread: 160,
            origin: { x: 0.5, y: 0.5 },
            zIndex: 9999,
        });
    }

    function handleComponentClick(event) {
        event.stopPropagation();
        firework();
    }

    return (
        <>
            <audio
                ref={audioRef}
                src="/audio/success-trumpets.mp3"
                preload="auto"
            />
            <canvas
                ref={canvasRef}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 10000,
                }}
            ></canvas>
            <section
                className="correct-section"
                onClick={handleComponentClick}
                style={{ zIndex: 9999 }}
            >
                <div className="inner">
                    <h2>
                        <span>정답이에요!</span> 대단한걸요?
                    </h2>
                    <figure>
                        <img
                            src={'/images/correct.svg'}
                            alt="Correct"
                        />
                    </figure>
                </div>
            </section>
        </>
    );
};

export default AnswerCorrectModal;
