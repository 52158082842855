import React from 'react';
import '../styles/TrAlarmComponent.scss';

const TrAlarmComponent = ({ selected_magickid, title, onClose }) => {
    // 선택된 매직키드에 따라 말풍선 바뀌기
    const imageSrc =
        selected_magickid === 'arial'
            ? '/images/arial-alarm.svg'
            : '/images/nikki-alarm.svg';

    return (
        <section className="alarm-section">
            <div className="inner">
                <figure>
                    <img
                        src={imageSrc}
                        alt={selected_magickid}
                    />
                </figure>
                <div className="alarm-txt-container">
                    <p>
                        친구들! {title}을/를 고르지 않았어요!
                        <br />
                        {title}을/를 골라볼까요?
                    </p>
                    <button onClick={onClose}>선택지 고르기</button>
                </div>
            </div>
        </section>
    );
};

export default TrAlarmComponent;
