import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/DrawingTraining.scss';

import ErrorModal from '../components/ErrorModal';
import loginSessionCheck from '../components/LoginSessionCheck';

import { getTrainingInfo, callFalAI } from '../api/ClassAPI';
import { saveCreation } from '../api/WorkAPI';
import { ERROR } from '../utils/Constant';
import TrStartComponent from '../components/TrStartComponent';
import TrGuideComponent from '../components/TrGuideComponent';
import TrSelectionComponent from '../components/TrSelectionComponent';
import TrAfterSelectionComponent from '../components/TrAfterSelectionComponent';
import TrFinishSelectionComponent from '../components/TrFinishSelectionComponent';
import TrDrawingResultComponent from '../components/TrDrawingResultComponent';
import TrDiscussionTalkingComponent from '../components/TrDiscussionTalkingComponent';
// import CompleteModal from '../components/CompleteModal';
import Loading from '../components/LongLoading';
import ShortLoading from '../components/Loading';
import TrCompleteComponent from '../components/TrCompleteComponent';
import TrainingHeader from '../components/TrainingHeader';
import SchoolTitle from '../components/SchoolTitle';
import TrUploadImageComponent from '../components/TrUploadImageComponent';

const DrawingTraining = () => {
    const navigation = useNavigate();

    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const USER_SCHOOLNAME = localStorage.getItem('userSchoolName');
    const USER_TYPE = localStorage.getItem('userType');
    const { trainingId, trainingType } = useParams();

    const [step, setStep] = useState(0);
    const [finalStep, setFinalStep] = useState(0);
    const [selectionStep, setSelectionStep] = useState(0);
    const [isRevisiting, setIsRevisiting] = useState(false);
    const [revisitingIndex, setRevisitingIndex] = useState(null);

    const [selectedmagickid, setSelectedMagickidName] = useState('');
    const [trainingTopicTitle, setTrainingTopicTitle] = useState('');
    const [trainingDescription, setTrainingDescription] = useState('');
    const [discussionTopic, setDiscussionTopic] = useState('');
    const [discussionExample, setDiscussionExample] = useState('');
    const [promptContent, setPromptContent] = useState('');
    const [selectionList, setSelectionList] = useState(null);
    const [selectedTitleKr, setSelectedTitleKr] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState([]);
    const [AIResult, setAIResult] = useState(null);
    const [AIResultList, setAIResultList] = useState([]);
    const drawingAudioRef = useRef(null);
    const [imageFilePath, setImageFilePath] = useState('');

    const [showLoading, setShowLoading] = useState(false);
    const [showSaveLoading, setShowSaveLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        callTrainingInfoAPIFunc(trainingType, trainingId);
        window.scrollTo(0, 0);
    }, []);

    // NOTE 0801 : 상태 업데이트 후 AI 호출 -> 이게 잘 안되는거같음
    useEffect(() => {
        const executeAsync = async () => {
            if (isRevisiting) {
                await executeAI();
                setStep(finalStep - 1); // 바로 그림 결과 화면으로 이동
                setIsRevisiting(false);
            }
        };

        executeAsync();
    }, [selectedPrompt]);

    const handleRegenerate = async () => {
        await executeAI();
        setStep(finalStep - 1); // 그림 결과 화면으로 이동
    };

    const handleClearSelections = () => {
        setStep(2);
        setSelectionStep(0);
        setSelectedTitleKr([]);
        setSelectedPrompt([]);
    };

    const handleRestart = () => {
        setStep(0);
        setSelectionStep(0);
        setSelectedTitleKr([]);
        setSelectedPrompt([]);
        setAIResult(null);
        setAIResultList([]);
        setSelectedMagickidName('');
    };

    const handleGoHubDrawing = () => {
        navigation('/dhub/drawing');
    };

    const callTrainingInfoAPIFunc = async (training_type, training_id) => {
        const response = await getTrainingInfo(training_type, training_id);

        if (response.success) {
            if (training_type === 'drawing') {
                console.log(response.data);
                setSelectionList(
                    response.data.training_prompt_json.selection_list
                );
                setPromptContent(response.data.training_prompt_json.content);
                setTrainingTopicTitle(response.data.training_topic_title);
                setTrainingDescription(response.data.training_description);
                setDiscussionTopic(response.data.discussion_topic);
                setDiscussionExample(response.data.discussion_example);
                setFinalStep(
                    response.data.training_prompt_json.selection_list.length *
                    2 +
                    4
                );
                console.log(
                    'final step: ',
                    response.data.training_prompt_json.selection_list.length *
                    2 +
                    4
                );
            }
        } else {
            setShowErrorModal(true);
            console.log(response.error.errMsg);
        }
    };

    // NOTE 0801 : 부분 수정
    const handleNextButtonClick = async (selected_info) => {
        if (isRevisiting) {
            const updatedPrompt = [...selectedPrompt];
            const updatedTitleKr = [...selectedTitleKr];
            updatedPrompt[revisitingIndex] = selected_info.en;
            updatedTitleKr[revisitingIndex] = selected_info.kr;

            setSelectedPrompt(updatedPrompt);
            setSelectedTitleKr(updatedTitleKr);
            console.log('Updated selectedPrompt:', updatedPrompt);
            console.log('Updated selectedTitleKr:', updatedTitleKr);

            setRevisitingIndex(null);
            // setIsRevisiting(false); // AI 호출 후 isRevisiting을 false로 설정

            // await executeAI(); // 새로운 선택지를 반영하여 AI 호출
            // setStep(finalStep - 1); // 바로 그림 결과 화면으로 이동
        } else {
            if (step === 0) {
                setSelectedMagickidName(selected_info);
            }
            if (
                step >= 2 &&
                step % 2 === 0 &&
                selectionStep < selectionList.length
            ) {
                setSelectionStep((prevIndex) => ++prevIndex);
                setSelectedPrompt((prev) => {
                    if (selectionStep === prev.length) {
                        return [...prev, selected_info.en];
                    } else {
                        return [
                            ...prev.slice(0, selectionStep),
                            selected_info.en,
                            ...prev.slice(selectionStep + 1),
                        ];
                    }
                });
                setSelectedTitleKr((prev) => {
                    if (selectionStep === prev.length) {
                        return [...prev, selected_info.kr];
                    } else {
                        return [
                            ...prev.slice(0, selectionStep),
                            selected_info.kr,
                            ...prev.slice(selectionStep + 1),
                        ];
                    }
                });
            } else if (step === finalStep - 1) {
                await handleSaveButtonClick(AIResultList);
            }
            setStep((prevIndex) => {
                if (prevIndex === finalStep - 1) return finalStep;
                else return ++prevIndex;
            });
        }
    };

    const handlePreviousButtonClick = () => {
        if (!isRevisiting) {
            if (step > 2 && selectionStep <= selectionList.length) {
                setSelectionStep((prevIndex) =>
                    selectionStep !== 0 ? --prevIndex : prevIndex
                );
                setSelectedPrompt((prev) =>
                    selectedPrompt.length > 0 ? prev.slice(0, -1) : [...prev]
                );
                setSelectedTitleKr((prev) =>
                    selectedPrompt.length > 0 ? prev.slice(0, -1) : [...prev]
                );
            }
            setStep((prevIndex) => {
                if (prevIndex === 0) return 0;
                else if (
                    step > 2 &&
                    step % 2 === 0 &&
                    selectionStep <= selectionList.length
                ) {
                    let newIndex = prevIndex - 2;
                    return newIndex;
                } else return --prevIndex;
            });
        } else {
            setIsRevisiting(false);
            setStep(finalStep - 1);
        }
    };

    // NOTE 0801 : 부분 수정
    const executeAI = async () => {
        setShowLoading(true);
        let body;
        console.log(selectedPrompt);
        let updatedPrompt = [...selectedPrompt];
        console.log(updatedPrompt);
        if (
            trainingId === 1001 ||
            (trainingId >= '6000' && trainingId < '7000')
        ) {
            updatedPrompt = [...updatedPrompt, imageFilePath];
            console.log(
                'trainingId 조건 내에서, updatedPrompt 값 => ',
                updatedPrompt
            );
        } else {
            console.log(
                'trainingId 조건 외에서, updatedPrompt 값 => ',
                updatedPrompt
            );
        }

        body = {
            prompt: updatedPrompt,
        };

        console.log('AI 호출 전의 prompt 값 => ', body);

        const response = await callFalAI(
            USER_ID,
            trainingType,
            trainingId,
            body
        );
        if (response.success) {
            console.log('callFalAI 응답 데이터 => ', response.data);
            setTimeout(() => {
                var result;
                if (
                    trainingId === 1001 ||
                    (trainingId >= '6000' && trainingId < '7000')
                ) {
                    result = response.data.url;
                } else {
                    result = response.data[0].url;
                }
                setAIResult(result);
                setAIResultList((prev) => [...prev, result]);
                console.log('AI 결과값:', result);
                setStep(finalStep - 1); // 그림 결과 화면으로 이동
                if (drawingAudioRef.current) {
                    drawingAudioRef.current.play().catch((error) => {
                        console.error('오디오 재생에 실패했습니다.', error);
                    });
                }
                setShowLoading(false);
            }, 2000); // 2000ms = 2초
        } else {
            console.log('오류 메시지:', response.error.errMsg);
            console.log('오류 세부 정보:', response.error);
            setShowLoading(false);
            if (response.error.errCode === 'Magicbean_ERR_00') {
                setShowErrorModal(true);
                setErrorMessage(response.error.errMsg);
            } else {
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
            }
        }
    };

    const handleSaveButtonClick = async (selectedResults) => {
        console.log('selectedResults: ', selectedResults);
        setShowSaveLoading(true);

        for (const selectedResult of selectedResults) {
            const response = await saveCreation(
                USER_ID,
                trainingType,
                trainingTopicTitle,
                selectedResult,
                trainingId
            );

            if (!response.success) {
                console.log(response.error);
                setShowSaveLoading(false);
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
                return;
            }

            console.log('Save => ', response.data);
        }

        setShowSaveLoading(false);
    };

    const handleWordClick = (index) => {
        setIsRevisiting(true);
        setRevisitingIndex(index);
        setStep(index * 2 + 2); // 선택된 단어의 옵션으로 이동
        setSelectionStep(index);
    };

    return (
        <>
            <TrainingHeader hubPath="/dhub/drawing" />
            <SchoolTitle school_name={USER_SCHOOLNAME} user_type={USER_TYPE} />

            {showLoading && (
                <Loading
                    type={trainingType}
                    selectedTitleKr={selectedTitleKr}
                ></Loading>
            )}
            {showSaveLoading && <ShortLoading></ShortLoading>}
            {showErrorModal && (
                <ErrorModal
                    error_message={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                ></ErrorModal>
            )}
            <audio
                ref={drawingAudioRef}
                src="/audio/twinkle-drawing.mp3"
            />

            {step === 0 &&
                (trainingId >= '6000' &&
                    trainingId < '7000' &&
                    imageFilePath === '' ? (
                    <TrUploadImageComponent
                        onNext={(path) => setImageFilePath(path)}
                    />
                ) : (
                    <TrStartComponent
                        onNext={handleNextButtonClick}
                        training_topic_title={trainingTopicTitle}
                    />
                ))}

            {step === 1 && (
                <TrGuideComponent
                    onNext={handleNextButtonClick}
                    onPrevious={handlePreviousButtonClick}
                    training_description={trainingDescription}
                    selected_magickid={selectedmagickid}
                />
            )}
            {step >= 2 && step % 2 === 0 && step < finalStep - 2 && (
                <TrSelectionComponent
                    onNext={handleNextButtonClick}
                    onPrevious={handlePreviousButtonClick}
                    title={selectionList[selectionStep].title}
                    selection_list={selectionList[selectionStep]}
                />
            )}
            {step >= 2 && step % 2 !== 0 && step < finalStep - 2 && (
                <>
                    <TrAfterSelectionComponent
                        onNext={handleNextButtonClick}
                        onPrevious={handlePreviousButtonClick}
                        prev_title={
                            selectionList[selectionStep - 1]
                                ? selectionList[selectionStep - 1].title
                                : ''
                        }
                        next_title={
                            selectionList[selectionStep]
                                ? selectionList[selectionStep].title
                                : ''
                        }
                        selectedTitleKr={selectedTitleKr[selectionStep - 1]}
                        selected_magickid={selectedmagickid}
                    />
                </>
            )}
            {step === finalStep - 2 && (
                <>
                    <TrFinishSelectionComponent
                        onNext={executeAI}
                        onPrevious={handlePreviousButtonClick}
                        selected_magickid={selectedmagickid}
                        prompt_content={promptContent}
                        selected_kr_list={selectedTitleKr}
                        selectedType="drawing"
                    />
                </>
            )}
            {step === finalStep - 1 && (
                <>
                    <TrDrawingResultComponent
                        clearSelections={handleClearSelections}
                        onRegenerate={handleRegenerate}
                        onNext={handleNextButtonClick}
                        selectedKRList={selectedTitleKr}
                        resultContent={AIResult}
                        resultContentList={AIResultList}
                        onWordClick={handleWordClick} // 새로운 핸들러 전달
                        selected_magickid={selectedmagickid}
                    />
                </>
            )}
            {step === finalStep && (
                <>
                    <TrDiscussionTalkingComponent
                        onNext={handleNextButtonClick}
                        selected_magickid={selectedmagickid}
                        discussionTopic={discussionTopic}
                        discussionExample={discussionExample}
                        AIResultList={AIResultList}
                        TalkingType="drawing"
                    />
                </>
            )}
            {step > finalStep && (
                <>
                    <TrCompleteComponent
                        onRestart={handleRestart}
                        onEnd={handleGoHubDrawing}
                        selected_magickid={selectedmagickid}
                    />
                </>
            )}
        </>
    );
};

export default loginSessionCheck(DrawingTraining);
