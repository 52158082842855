import React, { useState } from 'react';
import '../styles/TrAfterSelectionComponent.scss';
import TypingAnimation from './TypingAnimation';

const TrAfterSelectionComponent = ({
    onNext,
    onPrevious,
    prev_title,
    next_title,
    selectedTitleKr,
    selected_magickid,
}) => {
    // NOTE : 타이핑 애니메이션 테스트를 위한 변수
    // const fullText = `멋지다~!
    // 네가 고른 ${prev_title}은/는 ${selectedTitleKr}
    // 이야!
    // 자, 그럼 이제 아래의 다음 버튼을 눌러서 ${next_title}을 골라볼까?`;

    console.log('prev_title', prev_title);
    console.log('selectedTitleKr', selectedTitleKr);

    return (
        <div className="tr-bg after-selcomplete">
            <div className="inner">
                <div className="tr-box after-selcomplete">
                    <h3 className="complete-txt">{prev_title} 선택 완료</h3>
                    <div className="divider"></div>
                    <div className="guide-long-txt">
                        멋지다~!
                        <br /> 네가 고른 {prev_title}은/는{' '}
                        <span className="select-box">{selectedTitleKr}</span>
                        (이)야!
                        <br />
                        {next_title ? (
                            <p>
                                자, 그럼 이제 아래의 다음 버튼을 눌러서{' '}
                                {next_title}을/를 골라볼까?
                            </p>
                        ) : (
                            <p>
                                마지막까지 다 골랐어! <br /> 우리 같이
                                확인해볼까?
                            </p>
                        )}
                    </div>

                    {/* NOTE : 타이핑 애니메이션 테스트 */}
                    {/* <TypingAnimation
                        fullText={fullText}
                        typingSpeed={50}
                    >
                        {next_title ? (
                            <TypingAnimation
                                fullText={fullText}
                                typingSpeed={50}
                            />
                        ) : (
                            <p>
                                마지막까지 다 골랐어! <br /> 우리 같이
                                확인해볼까?
                            </p>
                        )}
                    </TypingAnimation> */}
                    <figure className="speaking-char">
                        <img
                            src={
                                selected_magickid === 'arial'
                                    ? '/images/arial.svg'
                                    : '/images/nikki.svg'
                            }
                            alt=""
                        />
                    </figure>
                </div>
                <div className="step-btns">
                    <div
                        className="button button-prev"
                        onClick={onPrevious}
                    >
                        ◀ 이전
                    </div>
                    <div
                        className="button button-next"
                        onClick={onNext}
                    >
                        다음 ▶
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrAfterSelectionComponent;
