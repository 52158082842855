import { performRequest } from './utils/RequestUtils';
import { CLASS_ENDPOINT } from './utils/EndPoint';

export const getLessonList = async () => {
    return performRequest('get', `${CLASS_ENDPOINT}/lesson`);
};

export const getLessonContentJSON = async (lesson_id) => {
    return performRequest('get', `${CLASS_ENDPOINT}/lesson/${lesson_id}`);
};

export const getTrainingList = async (user_id, training_type, user_type) => {
    return performRequest('get', `${CLASS_ENDPOINT}/training/all/${training_type}/${user_id}/${user_type}`);
};

export const getTrainingInfo = async (training_type, training_id) => {
    return performRequest(
        'get',
        `${CLASS_ENDPOINT}/training/${training_type}/${training_id}`
    );
};

export const getFavoriteTrainingList = async (user_id) => {
    return performRequest(
        'get',
        `${CLASS_ENDPOINT}/training/favorite/${user_id}`
    );
};

export const callChatGPT = async (
    user_id,
    training_type,
    training_id,
    data
) => {
    return performRequest(
        'post',
        `${CLASS_ENDPOINT}/training/${training_type}/${training_id}/chatgpt`,
        {
            prompt: data?.prompt ?? '',
            user_id: user_id,
        }
    );
};

export const callFalAI = async (user_id, training_type, training_id, data) => {
    return performRequest(
        'post',
        `${CLASS_ENDPOINT}/training/${training_type}/${training_id}/falai`,
        {
            prompt: data?.prompt ?? '',
            user_id: user_id,
        }
    );
};

export const toggleFavoriteTraining = async (user_id, training_type, ref_id) => {
    return performRequest(
        'post',
        `${CLASS_ENDPOINT}/training/favorite/${training_type}`,
        {
            user_id: user_id,
            ref_id: ref_id,
            training_type: training_type
        }
    );
};

export const uploadImageFile = async (formData) => {
    return performRequest(
        'post',
        `${CLASS_ENDPOINT}/training/upload`,
        formData
    );
};
