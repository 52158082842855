import React, { useState, useEffect } from 'react';
import '../styles/TermsNoticeModal.scss';

const TermsUpdateCheckModal = ({ show, onClose, onAllChecked }) => {
    const [privacyContent, setPrivacyContent] = useState('');
    const [termContents, setTermContents] = useState('');
    // 이용약관 체크 확인
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    // 개인정보처리방침 체크 확인
    const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

    useEffect(() => {
        // 개인정보 처리방침
        const fetchPrivacyPolicy = async () => {
            try {
                const response = await fetch('/utils/PrivacyPolicy.txt');
                const text = await response.text();
                setPrivacyContent(text);
            } catch (error) {
                console.error('Failed to fetch the privacy policy file', error);
            }
        };

        // 이용약관
        const fetchTermsOfService = async () => {
            try {
                const response = await fetch('/utils/TermsOfService.txt');
                const text = await response.text();
                setTermContents(text);
            } catch (error) {
                console.error(
                    'Failed to fetch the terms of service file',
                    error
                );
            }
        };

        if (show) {
            fetchPrivacyPolicy();
            fetchTermsOfService();
        }
    }, [show]);

    // 약관동의 둘 다 체크되면 부모컴포넌트(Home)로 전달
    useEffect(() => {
        const isAllChecked = isTermsChecked && isPrivacyPolicyChecked;
        onAllChecked(isAllChecked);
    }, [isTermsChecked, isPrivacyPolicyChecked, onAllChecked]);

    if (!show) {
        return null;
    }

    const isAllChecked = isTermsChecked && isPrivacyPolicyChecked;

    const handleTermsCheckboxChange = (event) => {
        setIsTermsChecked(event.target.checked);
    };

    const handlePrivacyPolicyCheckboxChange = (event) => {
        setIsPrivacyPolicyChecked(event.target.checked);
    };

    return (
        <div className="terms-notice-modal">
            <div className="modal-container">
                <div className="notice-header">
                    <h2>공지사항</h2>
                </div>
                <div className="notice-contents">
                    <h2>개인정보 처리방침</h2>
                    <p>{privacyContent}</p>
                </div>
                <div className="notice-contents">
                    <h2>이용약관</h2>
                    <p>{termContents}</p>
                </div>
                <div className="terms-container">
                    <div className="check-inputs flex">
                        <input
                            type="checkbox"
                            id="terms"
                            onChange={handleTermsCheckboxChange}
                            checked={isTermsChecked}
                        />
                        <label
                            htmlFor="terms"
                            className="term-box"
                        >
                            <span>
                                이용약관
                                <em>&nbsp;&#40;필수&#41;</em>
                            </span>
                        </label>
                    </div>
                    <div className="check-inputs flex">
                        <input
                            type="checkbox"
                            id="privacy"
                            onChange={handlePrivacyPolicyCheckboxChange}
                            checked={isPrivacyPolicyChecked}
                        />
                        <label
                            htmlFor="privacy"
                            className="term-box"
                        >
                            <span>
                                개인정보처리방침
                                <em>&nbsp;&#40;필수&#41;</em>
                            </span>
                        </label>
                    </div>
                </div>
                <div className="close-cover">
                    <button
                        className={`close-btn-bottom ${
                            !isAllChecked ? 'disabled' : ''
                        }`}
                        onClick={onClose}
                        disabled={!isAllChecked}
                    >
                        확인
                    </button>
                </div>
                <p className="small-notice">
                    관련 문의사항이 있으시면 체인트리(주)로 문의해주시기
                    바랍니다.
                </p>
            </div>
        </div>
    );
};

export default TermsUpdateCheckModal;
