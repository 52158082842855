import React, { useState, useEffect } from 'react';
import '../styles/EmailVerification.scss';

const EmailVerification = ({ email, setEmail, user_id, setUserID, onNext, onFailed, onBackToLogin }) => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setErrorMessage('이메일을 입력해주세요.');
            return;
        }
        if (!user_id) {
            setErrorMessage('ID를 입력해주세요.');
            return;
        }

        onNext();
    };

    useEffect(() => {
        setErrorMessage(onFailed)
    }, [onFailed]);

    const handleIdChange = (event) => {
        //input change되면 에러메세지 사라짐
        let value = event.target.value;
        const numericValue = value.replace(/[^0-9-]/g, '');

        if (value !== numericValue) {
            setErrorMessage('* 숫자만 입력해주세요');
            setUserID(numericValue);
        } else {
            setErrorMessage('');
            if (value.length > 4 && value[4] !== '-') {
                value = value.slice(0, 4) + '-' + value.slice(4);
            }
            if (value.length > 8 && value[8] !== '-') {
                value = value.slice(0, 8) + '-' + value.slice(8);
            }
            if (value.length > 12) {
                value = value.slice(0, 12);
            }
            setUserID(value); // 변환된 값을 상태에 설정
        }
    };

    return (
        <>
            <div className="email-verification-container">
                <h2>비밀번호 찾기</h2>
                <p className="sub-text">
                    비밀번호를 잊어버리셨나요?
                    <br />
                    새로운 비밀번호 설정을 위해 이메일을 입력해주세요.
                </p>
                <form onSubmit={handleSubmit}>
                    <p>이메일</p>
                    <input
                        type="email"
                        placeholder="가입한 이메일 주소를 입력하세요"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <p>ID</p>
                    <input
                        type="text"
                        placeholder="사용하시는 ID를 입력하세요"
                        value={user_id}
                        onChange={handleIdChange}
                    />
                    <button type="submit">계속</button>
                </form>
                {errorMessage && <p className="error-text">{errorMessage}</p>}
                <div className="find-pw">
                    <p>비밀번호가 생각나셨나요?</p>
                    <p
                        onClick={onBackToLogin}
                        className="back-to-login"
                    >
                        로그인
                    </p>
                </div>
            </div>
        </>
    );
};

export default EmailVerification;
