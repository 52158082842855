import { USER_ENDPOINT } from './utils/EndPoint';
import { performRequest } from './utils/RequestUtils';

export const getRandomNickname = async () => {
    return performRequest('get', `${USER_ENDPOINT}/nickname`);
};

export const getSchoolName = async (user_id) => {
    return performRequest('get', `${USER_ENDPOINT}/info/${user_id}/school`);
};

export const getTermsCheck = async (user_id) => {
    return performRequest('get', `${USER_ENDPOINT}/check/terms/${user_id}`);
};

export const updateNickname = async (user_id, nickname) => {
    return performRequest('post', `${USER_ENDPOINT}/nickname`, {
        user_id,
        nickname,
    });
};

export const studentLogin = async (user_id) => {
    return performRequest('post', `${USER_ENDPOINT}/login/student`, {
        user_id,
    });
};

export const teacherLogin = async (user_id, password) => {
    return performRequest('post', `${USER_ENDPOINT}/login/teacher`, {
        user_id,
        password,
    });
};

export const checkValidUserID = async (user_id) => {
    return performRequest('post', `${USER_ENDPOINT}/check/user_id`, {
        user_id,
    });
};

export const checkDuplicateEmail = async (email) => {
    return performRequest('post', `${USER_ENDPOINT}/check/email`, {
        email,
    });
};

export const checkPassworkChange = async (user_id, email) => {
    return performRequest('post', `${USER_ENDPOINT}/check/password`, {
        user_id,
        email,
    });
};

export const updatePassword = async (user_id, password) => {
    return performRequest('post', `${USER_ENDPOINT}/update/password`, {
        user_id,
        password,
    });
};


export const teacherSignup = async (user_id, email, username, password, phone, school_name, grade_class, tos_check, pp_check) => {
    return performRequest('post', `${USER_ENDPOINT}/signup/teacher`, {
        user_id,
        email,
        username,
        password,
        phone,
        school_name,
        grade_class,
        tos_check,
        pp_check
    });
};

export const updateTermsCheck = async (user_id) => {
    return performRequest('post', `${USER_ENDPOINT}/update/terms`, {
        user_id,
    });
};

