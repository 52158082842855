import React, { useEffect } from 'react';
import '../styles/Guide.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import InqueryButton from '../components/InqueryButton';
import Footer from '../components/Footer';

const Guide = () => {
    const navigate = useNavigate();

    const handleManualClick = (manualType) => {
        navigate(`/test?manual=${manualType}`);
    };

    useEffect(() => {
        AOS.init();
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id="wrap">
            <Header />
            <section className="guide-section-1">
                <div
                    className="inner flex"
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <h2 className="tit">
                            더 쉽게 사용하는
                            <br />
                            AI 매직포유
                        </h2>
                        <p>가이드를 보고 더 재미있고 유익하게 활용해보아요!</p>
                        <button
                            className="requst-btn"
                            onClick={() => {
                                navigate('/contact');
                            }}
                        >
                            도입문의
                        </button>
                    </div>
                    <div className="right">
                        <figure>
                            <img
                                src={'/images/guide-books.svg'}
                                alt="section-1-image"
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="guide-section-2">
                <div className="inner">
                    <ol className="service-list">
                        <li onClick={() => handleManualClick('teacher')}>
                            <div className="num">01</div>
                            <div className="service-title">선생님 가이드</div>
                            <div className="service-description">
                                선생님 계정 가입부터 수업 활용 예시까지!
                                <br />
                                선생님용 가이드북
                            </div>
                            <div className="service-img">
                                <img
                                    src={'/images/teacher-guide.svg'}
                                    alt=""
                                />
                            </div>
                        </li>
                        <li onClick={() => handleManualClick('drawing')}>
                            <div className="num">02</div>
                            <div className="service-title">
                                그림 그리기
                                <br />
                                AI 체험 메뉴얼
                            </div>
                            <div className="service-description">
                                재미있는 AI 그림 그리기 체험,
                                <br />
                                알고보면 더 재밋어요!
                            </div>
                            <div className="service-img">
                                <img
                                    src={'/images/drawing-guide.svg'}
                                    alt=""
                                />
                            </div>
                        </li>
                        <li onClick={() => handleManualClick('writing')}>
                            <div className="num">03</div>
                            <div className="service-title">
                                글 만들기
                                <br />
                                AI 체험 메뉴얼
                            </div>
                            <div className="service-description">
                                지루한 글 만들기는 이제 그만!
                                <br />
                                AI와 대화형식으로 즐겁게 글을 만들어봐요
                            </div>
                            <div className="service-img">
                                <img
                                    src={'/images/writing-guide.svg'}
                                    alt=""
                                />
                            </div>
                        </li>
                    </ol>
                </div>
            </section>
            <InqueryButton />
            <Footer />
        </div>
    );
};

export default Guide;
