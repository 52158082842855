import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Print from './Print';
import '../styles/WideViewModal.scss';

const WideViewModal = ({ image, onClose, checkPrint }) => {
    console.log('checkPrint: ', checkPrint);

    const printRef = useRef();

    return (
        <>
            <section className="wide-view-modal">
                <div className="inner">
                    <figure
                        className="close-modal-btn"
                        onClick={onClose}
                    >
                        <img
                            src={'/images/close.png'}
                            alt=""
                        />
                    </figure>

                    <figure className="wide-image">
                        <img
                            src={image}
                            alt="image"
                        />
                    </figure>
                    {/* 2024-02-16 | 버튼 위치 수정(이미지 위 -> 이미지 아래) */}
                    <span
                        className="btn close-btn"
                        onClick={onClose}
                    >
                        확인
                    </span>
                    {checkPrint && (
                        <ReactToPrint
                            trigger={() => (
                                <button className="btn close-btn">
                                    인쇄하기
                                </button>
                            )}
                            content={() => printRef.current}
                        />
                    )}
                </div>
                <Print
                    ref={printRef}
                    type="drawing"
                    image={image}
                    onClose={onClose}
                />
            </section>
        </>
    );
};

export default WideViewModal;
