import React, { useState, useEffect } from 'react';
import '../styles/ViewAllTrainings.scss';
import { useNavigate } from 'react-router-dom';
import { getTrainingList } from '../api/ClassAPI';
import TrViewAllTrainingComponenet from '../components/TrViewAllTrainingComponenet';
import { useLocation } from 'react-router-dom';

const ViewAllTrainings = () => {
    const USER_ID = localStorage.getItem('userID');
    const isTeacher = localStorage.getItem('isTeacher') === 'true';
    const location = useLocation();
    const trainingType = location.state.trainingType;
    const [trainingData, setTrainingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [favorites, setFavorites] = useState({});
    const navigate = useNavigate();

    console.log('isTeacher', isTeacher);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getTrainingList(USER_ID, trainingType);
            if (response.success) {
                setTrainingData(response.data);
                setFilteredData(response.data);
                const favs = response.data.reduce((acc, cur) => {
                    acc[cur.training_id] = cur.is_favorite;
                    return acc;
                }, {});
                setFavorites(favs);
            } else {
                console.log(response.error.errMsg);
            }
        };
        fetchData();
    }, [trainingType]);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleItemClick = (trainingId) => {
        if (trainingType === 'drawing') {
            navigate(`/drawingtraining/drawing/${trainingId}`);
        } else {
            navigate(`/writingtraining/writing/${trainingId}`);
        }
    };

    const toggleFavorites = (training_id) => {
        setFavorites((prev) => ({
            ...prev,
            [training_id]: !prev[training_id],
        }));
    };

    return (
        <section className="all-view-section">
            <figure className="absolute cloud-1">
                <img
                    src={'/images/fav-cloud-1.svg'}
                    alt=""
                />
            </figure>
            <figure className="absolute cloud-2">
                <img
                    src={'/images/fav-cloud-2.svg'}
                    alt=""
                />
            </figure>
            <figure className="absolute dumble-1">
                <img
                    src={'/images/fav-dumble-1.svg'}
                    alt=""
                />
            </figure>
            <figure className="absolute dumble-2">
                <img
                    src={'/images/fav-dumble-2.svg'}
                    alt=""
                />
            </figure>
            <div className="inner">
                <div className="all-view-title-container">
                    <h2>전체보기</h2>
                    <figure
                        className="fav-arrow-back"
                        onClick={handleBackClick}
                    >
                        <img
                            src={'/images/backarrow-gray.svg'}
                            alt=""
                        />
                    </figure>
                </div>
                {/* 허브카테고리 넣을지 말지 생각해보기 */}
                <TrViewAllTrainingComponenet
                    userId={USER_ID}
                    trainingData={filteredData}
                    toggleFavorites={toggleFavorites}
                    favorites={favorites}
                    onItemClick={handleItemClick}
                    hubType={trainingType}
                    isTeacher={isTeacher}
                />
            </div>
        </section>
    );
};

export default ViewAllTrainings;
