import React, { useEffect } from 'react';
import '../styles/Price.scss';
import Header from '../components/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import InqueryButton from '../components/InqueryButton';

const Price = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id="wrap">
            <Header />
            <section className="price-section-1">
                <div
                    className="inner flex"
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <h2 className="tit">
                            교재부터 체험까지,
                            <br />
                            AI 매직포유
                        </h2>
                        <p>
                            아이들이 AI와 함께 열어갈 세상을 위한 생성형 AI 체험
                            플랫폼 'AI매직포유'
                        </p>
                        <button
                            className="requst-btn"
                            onClick={() => {
                                navigate('/contact');
                            }}
                        >
                            도입문의
                        </button>
                    </div>
                    <div className="right">
                        <figure>
                            <img
                                src={'/images/price.svg'}
                                alt="section-1-image"
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="price-section-2">
                <div className="inner">
                    <div className="price-box-container">
                        <div className="price-box basic">
                            <div className="recommand-tag">추천</div>
                            <h2 className="plan-tit">BASIC</h2>
                            <dl className="plan-desc">
                                <dt>이런 분들에게 추천해요!</dt>
                                <dd>30인 미만 사용자</dd>
                            </dl>
                            <div className="divider"></div>
                            <ul className="plan-reward">
                                <li>
                                    월 결제시, 매월 학생 1인당 10,000 마법콩
                                    제공
                                </li>
                                <li>40종 이상의 다양한 창작그림동화 제공</li>
                            </ul>
                            <button
                                className="request-small-btn"
                                onClick={() => {
                                    navigate('/contact');
                                }}
                            >
                                문의하기
                            </button>
                        </div>
                        <div className="price-box lisence">
                            <h2 className="plan-tit">LISENCE</h2>
                            <dl className="plan-desc">
                                <dt>이런 분들에게 추천해요!</dt>
                                <dd>시도교육청 단위로 라이센스 단체 구매</dd>
                            </dl>
                            <div className="divider"></div>
                            <ul className="plan-reward">
                                <li>계정당 제공 마법콩 협의</li>
                                <li>프리미엄 기능 제공</li>
                            </ul>
                            <button
                                className="request-small-btn"
                                onClick={() => {
                                    navigate('/contact');
                                }}
                            >
                                문의하기
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <InqueryButton />
        </div>
    );
};

export default Price;
