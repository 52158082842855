import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sessionTimeout } from '../utils/SessionCheck';

const loginSessionCheck = (WrappedComponent) => {
    return (props) => {
        const navigate = useNavigate();
        // const sessionTimeout = 1000; // 테스트 세션 시간 1초

        useEffect(() => {
            const loginTime = parseInt(localStorage.getItem('loginTime'), 10);
            const currentTime = new Date().getTime();

            if (localStorage.getItem('userSchoolName') == '경상남도교육청 미래교육원') {
                return <WrappedComponent {...props} />;
            }
            // 세션 만료 확인
            else if (currentTime - loginTime > sessionTimeout) {
                localStorage.removeItem('userID');
                localStorage.removeItem('userName');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('loginTime');
                navigate('/logout'); // 로그인 페이지로 리다이렉트를 위한 알림창
            }
        }, [navigate]); // 네비게이션 함수를 종속성 배열에 추가

        // 세션이 유효하면 원래 컴포넌트 렌더링
        return <WrappedComponent {...props} />;
    };
};

export default loginSessionCheck;
