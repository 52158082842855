import React, { useRef } from 'react';
import '../styles/BigSizeModal.scss';
import ReactToPrint from 'react-to-print';
import Print from './Print';

const BigSizeModal = ({ resultContent, onClose }) => {
    const isTeacher = localStorage.getItem('isTeacher') === 'true';
    const printRef = useRef();

    return (
        <section className="big-section">
            <img
                className="fullscreen"
                src={resultContent}
                alt="fullscreen Image"
            />
            <button
                className="close-big-section"
                onClick={onClose}
            >
                <img
                    src={'/images/close.png'}
                    alt=""
                />
            </button>
            {isTeacher && (
                <>
                    <ReactToPrint
                        trigger={() => (
                            <button className="print-btn">
                                <figure>
                                    <img
                                        src={'/images/print-icon.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>프린트하기</span>
                            </button>
                        )}
                        content={() => printRef.current}
                    />
                    <div style={{ display: 'none' }}>
                        <Print
                            ref={printRef}
                            type="drawing"
                            image={resultContent}
                            onClose={onClose}
                        />
                    </div>
                </>
            )}

        </section>
    );
};

export default BigSizeModal;
