import React, { useState } from 'react';
import '../styles/ResetPassword.scss';

const ResetPassword = ({ newPassword, setNewPassword, onComplete }) => {
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!newPassword || !confirmPassword) {
            setErrorMessage('모든 필드를 입력해주세요.');
            return;
        }
        if (newPassword === confirmPassword) {
            onComplete();
        } else {
            setErrorMessage('비밀번호가 일치하지 않습니다.');
        }
    };

    return (
        <div className="reset-container">
            <h2>비밀번호 변경</h2>
            <p className="sub-text">새로운 비밀번호를 입력해주세요.</p>
            <form onSubmit={handleSubmit}>
                <p>비밀번호 변경</p>
                <input
                    type="password"
                    placeholder="변경할 비밀번호를 입력해주세요."
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <p>비밀번호 확인</p>
                <input
                    type="password"
                    placeholder="비밀번호 확인"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                )}
                <button type="submit">완료</button>
                <button className="cancel-btn">이전으로</button>
            </form>
        </div>
    );
};

export default ResetPassword;
