import React from 'react';
import '../styles/TcFilterOptions.scss';

const TcFilterOptions = ({
    selectedMonth,
    selectedLevel,
    selectedType,
    setSelectedMonth,
    setSelectedLevel,
    setSelectedType,
    handleResetFilters,
    isChanged,
    searchTerm,
    setSearchTerm,
}) => {
    const handleMonthChange = (e) => setSelectedMonth(parseInt(e.target.value));
    const handleLevelChange = (e) => setSelectedLevel(e.target.value);
    const handleTypeChange = (e) => setSelectedType(e.target.value);
    const handleSearchTerm = (e) => setSearchTerm(e.target.value);

    return (
        <div className="filter-options">
            <div className="input-field">
                <input
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    value={searchTerm}
                    onChange={handleSearchTerm}
                />
            </div>
            <div className="select-field">
                <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                >
                    <option value={0}>월별 교육 선택</option>
                    <option value={0}>전체</option>
                    <option value={1}>1월</option>
                    <option value={2}>2월</option>
                    <option value={3}>3월</option>
                    <option value={4}>4월</option>
                    <option value={5}>5월</option>
                    <option value={6}>6월</option>
                    <option value={7}>7월</option>
                    <option value={8}>8월</option>
                    <option value={9}>9월</option>
                    <option value={10}>10월</option>
                    <option value={11}>11월</option>
                    <option value={12}>12월</option>
                </select>
                <select
                    value={selectedLevel}
                    onChange={handleLevelChange}
                >
                    <option value="all">교육 난이도 선택</option>
                    <option value="all">전체</option>
                    <option value="easy">쉬움</option>
                    <option value="common">보통</option>
                    <option value="hard">어려움</option>
                </select>
                <select
                    value={selectedType}
                    onChange={handleTypeChange}
                >
                    <option value="all">교육 유형 선택</option>
                    <option value="all">전체</option>
                    <option value="life_edu">생활교육</option>
                    <option value="edu">교육</option>
                    <option value="fun">재미</option>
                    <option value="ai_edu">AI교육</option>
                </select>
                <button
                    className="reset-button"
                    onClick={handleResetFilters}
                    disabled={!isChanged}
                >
                    <figure>
                        <img
                            src={'/images/remake.svg'}
                            alt=""
                        />
                    </figure>
                    <span>초기화</span>
                </button>
            </div>
        </div>
    );
};

export default TcFilterOptions;
