import React from 'react';
import '../styles/CategoryBox.scss';
import { useNavigate } from 'react-router-dom';
import TextToSpeech from './TextToSpeech';

const CategoryBox = ({
    topicTitle,
    category,
    image,
    trainingType,
    trainingId,
    checkEnable,
}) => {
    const navigation = useNavigate();
    const handleGoPrompt = () => {
        // merryTest 여기 고쳐야함!
        if (checkEnable)
            navigation(`/drawingtraining/${trainingType}/${trainingId}`);
        else alert('곧 오픈될 예정입니다.');
    };

    return (
        <>
            {/* 2024-02-06 | li 내부 내용 변경함 (li내부에 div를 추가해서 onClick 이벤트를 붙여줌) */}
            <li className="category-item">
                <div
                    className="category-item-wrapper"
                    onClick={handleGoPrompt}
                >
                    <figure className="category-thumb">
                        {image && (
                            <img
                                src={image}
                                alt={topicTitle}
                            />
                        )}
                    </figure>
                    <div className="category-name-wrapper">
                        <div className="category-name">
                            <div className="topic-container">
                                <p className="category">{category}</p>
                                <p className="topic-title">{topicTitle}</p>
                            </div>
                            <div className="btn make-btn">만들기</div>
                        </div>
                    </div>
                </div>
                {/* 2024-02-06 | TextToSpeech 추가 */}
                <TextToSpeech
                    text={topicTitle}
                    enabledButton={true}
                />
            </li>
        </>
    );
};

export default CategoryBox;
