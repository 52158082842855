import { WORK_ENDPOINT } from './utils/EndPoint';
import { performRequest } from './utils/RequestUtils';

export const getUserCreationList = async (user_id, creation_type) => {
    return performRequest(
        'get',
        `${WORK_ENDPOINT}/my/${user_id}/${creation_type}`
    );
};

export const getSchoolCreationList = async (user_id, creation_type) => {
    return performRequest(
        'get',
        `${WORK_ENDPOINT}/school/${user_id}/${creation_type}`
    );
};

export const getSchoolCreationListForLoading = async (user_id) => {
    return performRequest('get', `${WORK_ENDPOINT}/loading/${user_id}`);
};

export const saveCreation = async (
    creator_user_id,
    creation_type,
    creation_title,
    creation,
    creation_training_id
) => {
    const postData = {
        creator_user_id: creator_user_id,
        creation_type: creation_type,
        creation_title: creation_title,
        creation: creation,
        creation_training_id: creation_training_id,
    };
    return performRequest('post', `${WORK_ENDPOINT}/save`, postData);
};

export const downloadCreation = async (
    user_id,
    creation_urls,
) => {
    const postData = {
        user_id: user_id,
        creation_urls: creation_urls,
    };
    const options = {
        responseType: 'blob' // responseType 설정
    };
    return performRequest('post', `${WORK_ENDPOINT}/download`, postData, options);
};


export const deleteCreation = async (creator_user_id, creation_training_ids) => {
    const path = `${WORK_ENDPOINT}/delete/${creator_user_id}/${creation_training_ids.join(',')}`;
    return performRequest('delete', path);
};
