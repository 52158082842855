import React, { useRef, useState, useEffect } from 'react';
import '../styles/TrUploadImageComponent.scss';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { uploadImageFile } from '../api/ClassAPI';

const TrUploadImageComponent = ({ onNext }) => {
    const USER_ID = localStorage.getItem('userID');
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        return () => {
            if (file) {
                URL.revokeObjectURL(file.preview);
            }
        };
    }, [file]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            resizeFile(selectedFile);
        }
    };

    const resizeFile = (file) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            'JPEG',
            100,
            0,
            (uri) => {
                const img = new Image();
                img.src = uri;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const size = Math.max(img.width, img.height);
                    canvas.width = size;
                    canvas.height = size;

                    ctx.drawImage(img, 0, 0, size, size);

                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name, {
                            type: file.type,
                        });
                        resizedFile.preview = URL.createObjectURL(resizedFile);
                        setFile(resizedFile);
                    }, file.type);
                };
            },
            'base64'
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setMessage('파일을 선택하세요.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('user_id', USER_ID);

            // FormData 내용을 확인하는 로그 추가
            for (let [key, value] of formData.entries()) {
                if (value instanceof File) {
                    console.log(
                        `${key}: File - name: ${value.name}, size: ${value.size}, type: ${value.type}`
                    );
                } else {
                    console.log(`${key}: ${value}`);
                }
            }

            await callUploadAPI(formData);
        } catch (error) {
            setMessage('파일 업로드 중 오류 발생');
        }
    };

    const callUploadAPI = async (formData) => {
        console.log('@@@@@callUploadAPI', formData);

        const response = await uploadImageFile(formData);
        if (response.success) {
            console.log('uploadImageFile => ', response.data);
            onNext(response.data);
        } else {
            setMessage('업로드 실패');
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div className="tr-bg-yellow">
                <div className="inner">
                    <div className="tr-box-yellow my-pic">
                        <div className="upload-box">
                            <p className="guide-txt">내 사진으로 그림 그리기</p>
                            <div className="upload-box">
                                {file ? (
                                    <figure className="upload-pic">
                                        <img
                                            src={file.preview}
                                            alt="Uploaded Preview"
                                            style={{
                                                width: '300px',
                                                height: '300px',
                                            }}
                                        />
                                    </figure>
                                ) : (
                                    <>
                                        <figure>
                                            <img
                                                src={'/images/folder-open.svg'}
                                                alt="폴더 아이콘"
                                            />
                                        </figure>
                                        <p>
                                            원하는 내 사진을 업로드하세요 :
                                            &#41;
                                        </p>
                                        <p className="sub-txt">
                                            * 증명사진 처럼 얼굴이 크게 나오면
                                            결과가 더 잘나와요!
                                        </p>
                                    </>
                                )}
                            </div>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                            <button
                                className="upload-pic-btn"
                                onClick={handleUploadClick}
                            >
                                <figure>
                                    <img
                                        src={'/images/cloud.svg'}
                                        alt=""
                                    />
                                </figure>
                                <p>업로드</p>
                            </button>
                        </div>
                    </div>
                    <div className="step-btns">
                        <div
                            className="button button-next"
                            onClick={handleSubmit}
                        >
                            다음 ▶
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrUploadImageComponent;
