import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/Work.scss';
import Navigation from '../components/Navigation';
import WorkTabs from '../components/WorkTabs';
import ReactToPrint from 'react-to-print';
import Floating from '../components/Floating';
import loginSessionCheck from '../components/LoginSessionCheck';
import LandScapeModal from '../components/LandScapeModal';

import { getSchoolName } from '../api/UserAPI';
import NewHeader from '../components/NewHeader';
import ScrollToTopButtonComponent from '../components/ScrollToTopButtonComponent';

const Work = () => {
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const USER_TYPE = localStorage.getItem('userType');
    const IS_TEACHER = localStorage.getItem('isTeacher') === 'true';
    const USER_SCHOOLNAME = localStorage.getItem('userSchoolName');

    const { pageType } = useParams();
    const [schoolName, setSchoolName] = useState('');

    useEffect(() => {
        if (pageType === 'school') {
            callGetSchoolNameAPI();
            //NOTE: 2024-01-31 활성화 필요! 수정!
        }
    }, []);

    const callGetSchoolNameAPI = async () => {
        let res = await getSchoolName(USER_ID);
        if (res.success) {
            console.log('SchoolName : ', res.data);
            setSchoolName(res.data.school_name);
        } else {
            //에러표시
            console.log('get err');
        }
    };

    return (
        <>
            <section className="contents">
                {/* <div className="left-contents">
                    <Navigation />
                </div> */}
                <div className="right-contents work-con">
                    <NewHeader />
                    <div className="inner">
                        {pageType === 'school' && (
                            <>
                                <h3 className="tit tit-nps centered">
                                    {USER_TYPE == 'others' ? <>
                                        <em className="blue-bold">{USER_SCHOOLNAME}</em>{' '}
                                        에서 체험한 멋진 작품들이에요!
                                    </> : <>
                                        <em className="blue-bold">{USER_NAME}</em>{' '}
                                        {IS_TEACHER && '선생'}님,{' '}
                                        <em className="blue-bold">{schoolName}</em>{' '}
                                        학생들의 멋진 작품들이에요!
                                    </>}

                                </h3>
                                <WorkTabs page_type={pageType} />
                            </>
                        )}
                        {pageType === 'my' && (
                            <>

                                <h3 className="tit tit-nps centered">
                                    {USER_TYPE == 'others' ? <>
                                        <em className="blue-bold">{USER_SCHOOLNAME}</em>{' '}
                                        에서 체험한 멋진 작품들이에요!
                                    </> : <>
                                        <h3 className="tit tit-nps centered">
                                            <em className="blue-bold">{USER_NAME}</em>{' '}
                                            {IS_TEACHER && '선생'}님의 멋진
                                            작품들이에요!
                                        </h3>
                                    </>}

                                </h3>
                                <WorkTabs page_type={pageType} />
                            </>
                        )}
                    </div>
                </div>
                <Floating />
            </section>
            <LandScapeModal />
            <ScrollToTopButtonComponent />
        </>
    );
};

export default loginSessionCheck(Work);
