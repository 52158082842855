import React, { useEffect, useState } from 'react';
import '../styles/UsageBtnComponent.scss';
import UsageModal from './UsageModal';

const UsageBtnComponent = ({ hideOnScrollPage }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    const handleScroll = () => {
        if (window.pageYOffset > 290) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <div
                    className="usage-btn"
                    onClick={handleButtonClick}
                >
                    <figure>
                        <img
                            src={'./images/bean-white.svg'}
                            alt=""
                        />
                    </figure>
                    <span>
                        콩 소모 정책
                        <br /> 보기
                    </span>
                </div>
            )}
            {isModalOpen && <UsageModal onClose={handleCloseModal} />}
        </>
    );
};

export default UsageBtnComponent;
