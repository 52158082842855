import React, { useEffect, useState } from 'react';
import '../styles/TcWorkSheetDetailComponent.scss';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import NewHeader from './NewHeader';
import { getTeachingPlanInfo, downloadMainWorksheet, downloadAllWorksheet } from '../api/TeacherAPI';
import TcLoadingSpinnerComponent from './TcLoadingSpinnerComponent';
import BigSizeModal from './BigSizeModal';

const TcWorkSheetDetailComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const LOGIN_TIME = localStorage.getItem('loginTime');
    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;
    const [teachingPlanInfo, setTeachingPlanInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalImage, setModalImage] = useState(null);
    const {
        drawing = [],
        writing = [],
        storybook = [],
        storyland = [],
    } = teachingPlanInfo?.training_list || {};

    const levelTranslations = {
        easy: '쉬움',
        common: '보통',
        hard: '어려움',
    };

    const typeTranslations = {
        life_edu: '생활교육',
        fun: '재미',
        ai_edu: 'AI교육',
        edu: '교육',
        all: '전체',
    };

    const handleImageClick = (url) => {
        setModalImage(url);
    };

    const handleCloseModal = () => {
        setModalImage(null);
    };

    useEffect(() => {
        callGetteachingPlanInfoAPIFunc();
    }, []);

    const callGetteachingPlanInfoAPIFunc = async () => {
        setLoading(true);
        setError(null);

        const response = await getTeachingPlanInfo(USER_ID, id);

        if (response.success) {
            let plan_json = response.data;
            plan_json.hashtags_level =
                levelTranslations[response.data.hashtags_level];
            plan_json.hashtags_type =
                typeTranslations[response.data.hashtags_type];
            setTeachingPlanInfo(plan_json);
            setLoading(false);
        } else {
            setError(response.error.errMsg);
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('@@@id=>', id);
        console.log('@@@teachingPlanInfo=>', teachingPlanInfo);
        window.scrollTo(0, 0);
    }, [id, teachingPlanInfo]);

    const handleListClick = () => {
        navigate('/worksheet');
    };

    // console.log(' {item.title}', { teachingPlanInfo });

    const getLevelClass = (level) => {
        switch (level) {
            case '쉬움':
                return 'easy';
            case '보통':
                return 'normal';
            case '어려움':
                return 'hard';
            default:
                return '';
        }
    };

    // NOTE : 2024-08-02 연계학습 링크 추가
    const handleRelationItemClick = (type, id) => {
        if (type === 'storybook' || type === 'storyland') {
            // const params = {
            //     userID: USER_ID,
            //     userName: USER_NAME,
            //     bookID: id,
            //     flag: type,
            // };
            // const query = new URLSearchParams(params).toString();
            // window.open(
            //     `${story4uURL}:${story4uPort}/reading-aimagickid?${query}`,
            //     '_blank',
            //     'noopener,noreferrer'
            // );
            const params = {
                userID: USER_ID,
                userName: USER_NAME,
                loginTime: LOGIN_TIME,
                page: type,
            };
            const query = new URLSearchParams(params).toString();
            window.open(
                `${story4uURL}:${story4uPort}/check?${query}`,
                '_blank',
                'noopener,noreferrer'
            );
        } else {
            navigate(`/${type}training/${type}/${id}`);
        }
    };

    const handleDownloadMainWorksheet = async () => {
        const worksheet_main_url = teachingPlanInfo.worksheet_main_url;
        // 파일 이름 추출
        const filePath = worksheet_main_url.substring(
            worksheet_main_url.lastIndexOf('/') + 1
        );

        // // 확장자 제거
        const fileName = filePath.substring(0, filePath.lastIndexOf('.'));

        try {
            const result = await downloadMainWorksheet(USER_ID, fileName);
            if (result.success) {
                const blob = new Blob([result.data], {
                    type: 'application/pdf',
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    const handleDownloadAllWorksheet = async () => {
        const worksheet_all_urls = [teachingPlanInfo.worksheet_main_url, ...teachingPlanInfo.worksheet_sub_url];
        console.log("worksheet_all_urls: ", worksheet_all_urls)

        try {
            const result = await downloadAllWorksheet(USER_ID, worksheet_all_urls);
            if (result.success && result.data instanceof Blob) {
                const url = window.URL.createObjectURL(result.data);
                // const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');

                // ZIP 파일의 이름을 서버에서 받은 파일 이름으로 설정
                const contentDisposition = result.headers?.['content-disposition'];
                const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'download.zip';

                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();

                // URL 객체 메모리 해제
                window.URL.revokeObjectURL(url);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    if (!teachingPlanInfo) {
        //NOTE: 2024-08-01 전체적으로 해당 처리 필요
        //NOTE: 추가로 로딩중인 페이지 필요할 것 같음
        return <TcLoadingSpinnerComponent />;
    }

    return (
        <section className="worksheet-detail">
            <NewHeader />
            <div className="inner">
                <div className="worksheet-prev">
                    <button
                        className="back-to-list"
                        onClick={handleListClick}
                    >
                        <figure>
                            <img
                                src={'/images/burger.svg'}
                                alt="list"
                            />
                        </figure>
                        <span>목록</span>
                    </button>
                    <div className="prev-container">
                        <div className="prev-left">
                            <figure>
                                <img
                                    src={teachingPlanInfo.worksheet_main_url}
                                    alt={teachingPlanInfo.plan_title}
                                />
                            </figure>
                            {/* NOTE: 2024-08-19 수정
                                pdf 다운 활성화시, 해당 주석 활성화
                            */}
                            {/* <div
                                className="download-btn"
                                onClick={handleDownloadMainWorksheet}
                            >
                                <figure>
                                    <img
                                        src={'/images/download-icon.svg'}
                                        alt=""
                                    />
                                </figure>
                                <p>워크지 다운로드</p>
                            </div> */}

                            <div className="download-btn all-download"
                                onClick={handleDownloadAllWorksheet}
                            >
                                <figure>
                                    <img
                                        src={'/images/download-icon.svg'}
                                        alt=""
                                    />
                                </figure>
                                <p>워크지 전체 다운로드</p>
                            </div>
                        </div>
                        <div className="prev-right">
                            <div className="plan-tit-container">
                                <h2>{teachingPlanInfo.plan_title}</h2>
                                <div className="hashtags">
                                    <button className="calendar">
                                        🗓️
                                        {teachingPlanInfo.months == 0
                                            ? '전'
                                            : teachingPlanInfo.months}
                                        월의 컨텐츠
                                    </button>
                                    <p
                                        className={`level ${getLevelClass(
                                            teachingPlanInfo.hashtags_level
                                        )}`}
                                    >
                                        #{teachingPlanInfo.hashtags_level}
                                    </p>
                                    <p>#{teachingPlanInfo.hashtags_type}</p>
                                </div>
                            </div>

                            <h3>교육목표</h3>
                            {teachingPlanInfo.plan_goal.map((goal, index) => (
                                <dl key={index}>
                                    <dt>
                                        {/* <span>{index + 1}.</span> */}
                                        {goal.edu_goal}
                                    </dt>
                                    {goal.description.map((desc, descIndex) => (
                                        <dd key={descIndex}>{desc}</dd>
                                    ))}
                                </dl>
                            ))}
                            <h3 className="extra">부록</h3>
                            <div className="area">
                                {teachingPlanInfo.worksheet_sub_url.length >
                                    0 &&
                                    teachingPlanInfo.worksheet_sub_url[0] ? (
                                    teachingPlanInfo.worksheet_sub_url.map(
                                        (url, index) => (
                                            <figure key={index}>
                                                <img
                                                    key={index}
                                                    src={url}
                                                    alt="부록"
                                                    onClick={() =>
                                                        handleImageClick(url)
                                                    }
                                                />
                                            </figure>
                                        )
                                    )
                                ) : (
                                    <p className="no-extra">
                                        별도의 부록이 없습니다
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-plan-container">
                    <div className="realtion">
                        <h3>이렇게 연계학습 해주세요 :)</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>글 쓰기</th>
                                    <th>그림 그리기</th>
                                    <th>동화 만들기</th>
                                    <th>스토리랜드</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td>
                                    {writing.length > 0 ? (
                                        writing.map((item, index) => (
                                            <>
                                                <span
                                                    key={index}
                                                    n
                                                    onClick={() =>
                                                        handleRelationItemClick(
                                                            'writing',
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    {item.title}
                                                </span>
                                                <br />
                                            </>
                                        ))
                                    ) : (
                                        <span>-</span>
                                    )}
                                </td>
                                <td>
                                    {drawing.length > 0 ? (
                                        drawing.map((item, index) => (
                                            <>
                                                <span
                                                    key={index}
                                                    onClick={() =>
                                                        handleRelationItemClick(
                                                            'drawing',
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    {item.title}
                                                </span>
                                                <br />
                                            </>
                                        ))
                                    ) : (
                                        <span>-</span>
                                    )}
                                </td>
                                <td>
                                    {storybook.length > 0 ? (
                                        storybook.map((item, index) => (
                                            <>
                                                <span
                                                    key={index}
                                                    onClick={() =>
                                                        handleRelationItemClick(
                                                            'storybook',
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    {item.title}
                                                </span>
                                                <br />
                                            </>
                                        ))
                                    ) : (
                                        <span>-</span>
                                    )}
                                </td>
                                <td>
                                    {storyland.length > 0 ? (
                                        storyland.map((item, index) => (
                                            <>
                                                <span
                                                    key={index}
                                                    onClick={() =>
                                                        handleRelationItemClick(
                                                            'storyland',
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    {item.title}
                                                </span>
                                                <br />
                                            </>
                                        ))
                                    ) : (
                                        <span>-</span>
                                    )}
                                </td>
                            </tbody>
                        </table>
                    </div>
                    <div className="prepare">
                        <h3>이런걸 준비하면 더 좋아요!</h3>
                        {teachingPlanInfo.description.map((desc, index) => (
                            <React.Fragment key={index}>
                                <p>{`${desc}`}</p>
                                {index <
                                    teachingPlanInfo.description.length - 1 && (
                                        <br />
                                    )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            {error && <TcLoadingSpinnerComponent />}
            {modalImage && (
                <BigSizeModal
                    resultContent={modalImage}
                    onClose={handleCloseModal}
                />
            )}
        </section>
    );
};

export default TcWorkSheetDetailComponent;
