import React from 'react';
import '../styles/InqueryButton.scss';
import { useNavigate } from 'react-router-dom';

const InqueryButton = () => {
    const navigate = useNavigate();
    return (
        <>
            <button
                className="floating-chat-btn"
                onClick={() => {
                    navigate('/contact');
                }}
            >
                <figure>
                    <img
                        src={'/images/chat-button.png'}
                        alt=""
                    />
                    {/* <img
                        src={'/images/in-chat.svg'}
                        alt=""
                    /> */}
                </figure>
            </button>
        </>
    );
};

export default InqueryButton;
