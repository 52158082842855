import React from 'react';
import { useClass } from '../context/ClassContext';
import { useNavigate } from 'react-router-dom';

const TrainingBox = ({ trainingData }) => {
    const navigation = useNavigate();
    const { setTrainingType } = useClass();

    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const LOGIN_TIME = localStorage.getItem('loginTime');

    const handleTrainingClick = () => {
        setTrainingType(trainingData.training_type);
        console.log(`/promptCategory/${trainingData.training_type}`);
        if (trainingData.training_type === 'storybook') {
            const params = {
                userID: USER_ID,
                userName: USER_NAME,
                loginTime: LOGIN_TIME,
                page: 'selecting',
            };
            const query = new URLSearchParams(params).toString();
            window.open(
                `${story4uURL}:${story4uPort}/check?${query}`,
                '_blank',
                'noopener,noreferrer'
            );
        } else {
            navigation(`/promptCategory/${trainingData.training_type}`);
        }
    };

    return (
        <>
            <div className="training">
                <p className="todays-training">오늘의 실습</p>
                <div className="prac-1">
                    <h3 className="training-tit">{trainingData.title}</h3>
                    <p className="desc">{trainingData.description}</p>
                </div>
                <div className="prac-2">
                    {trainingData.content_list.map((content, index) => (
                        <p
                            className="training-guide"
                            key={index}
                        >
                            {content}
                        </p>
                    ))}
                </div>
                {/* <div className="prac-3">
                    <div className="prac-3-tit">
                        <span>링크</span>
                        <figure>
                            <img
                                src={'/images/link.svg'}
                                alt="link"
                            />
                        </figure>
                    </div>
                    <ul className="training-link-list">
                        <li onClick={handleTrainingClick}>
                            <span>실습하러 가기</span>
                            <figure>
                                <img
                                    src={'/images/link-arrow.svg'}
                                    alt="link-arrow"
                                />
                            </figure>
                        </li>
                        <li className="recommend-training">
                            오늘의 추천 학습 주제 : 
                            <em> {trainingData.recommendation_training ? trainingData.recommendation_training : '오늘은 자유롭게 실습해봐요!'}</em>
                        </li>
                    </ul>
                </div> */}
            </div>
        </>
    );
};

export default TrainingBox;
