import React, { useState } from 'react';

const HubCategory = ({ trainingData, onCategoryChange }) => {
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

    const uniqueCategories = [
        '전체',
        ...new Set(trainingData.map((item) => item.training_category)),
    ];

    const handleCategoryClick = (index) => {
        setSelectedCategoryIndex(index);
        onCategoryChange(uniqueCategories[index]);
    };

    console.log('@@@!', trainingData);
    return (
        <div className="category-map">
            {uniqueCategories.map((category, index) => (
                <p
                    key={index}
                    onClick={() => handleCategoryClick(index)}
                    className={
                        selectedCategoryIndex === index
                            ? 'category-btn selected'
                            : 'category-btn'
                    }
                >
                    {category}
                </p>
            ))}
        </div>
    );
};

export default HubCategory;
