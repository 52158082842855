import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PrivacyPolicy from '../components/terms/PrivacyPolicy';
import TermsOfService from '../components/terms/TermsOfService';

const TermsPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const initialType = searchParams.get('type');

    const [type, setType] = useState(initialType);
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setType(null);
        setShow(false);
        window.close();
    };

    return (
        <>
            {show && (
                <>
                    {type === 'privacy' && (
                        <PrivacyPolicy onClose={handleClose} />
                    )}
                    {type === 'terms' && (
                        <TermsOfService onClose={handleClose} />
                    )}
                </>
            )}
        </>
    );
};

export default TermsPage;
