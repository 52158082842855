const LOADING = {
    title: "AI 마법사와 함께하는 동화 나라",
    message: [
        "AI 마법사가 동화 속 세상을 그리고 있어요!",
        "AI가 상상력을 가득 담아 그림을 그리고 있어요!",
        "동화 속 세계를 AI가 색칠 중이에요. 조금만 기다려주세요!",
        "잠시만 기다려주세요. AI가 펼치는 환상적인 동화 세계로 초대할게요!",
        "AI 마법사가 요술지팡이를 휘두르는 중! 마법 같은 이야기가 시작되어요.",
        "AI 마법사가 만든 환상 세계, 곧 그 문이 열려요!"
    ]
};

const ALERT = {
    warningTitle: "AI 마법사가 마법으로 그림을 그리지만, 가끔은 마법이 잘못되어 그림이 이상해질 수 있어요.",
    blackScreenTips: "검은 배경그림이 나오면, 배경그림 새로만들기를 해주세요."
};

const ERROR = {
    busy: "AI 마법사가 너무 바빠서 잠시 쉬고 있어요. 조금만 기다렸다가 다시 시도해주세요.",
};


module.exports = { LOADING, ALERT, ERROR };
