import React, { useState } from 'react';
import '../styles/ContestConts.scss';
import BigSizeModal from './BigSizeModal';

const ContestConts = ({ creationList, openModal }) => {
    const [showBigModal, setShowBigModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const printDate = (date) => {
        const result_date = date.split('T');
        return result_date[0];
    };

    const handleBigViewClick = (image) => {
        setSelectedImage(image);
        setShowBigModal(true);
    };

    console.log('creationList: ', creationList);

    return (
        <>
            <ul className="conts-list">
                {creationList.map((creation, index) => (
                    <li
                        className="individual-conts"
                        // onClick={() => openModal(creation.creation)}
                    >
                        <figure className="work-image">
                            <img
                                src={creation.creation}
                                alt={`작품 ${index + 1}`}
                            />
                            <div
                                className="wide-big-btn"
                                onClick={() =>
                                    handleBigViewClick(creation.creation)
                                }
                            >
                                <figure>
                                    <img
                                        src={'/images/big-white.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>크게보기</span>
                            </div>
                        </figure>
                        {/* <div className="polaroid-bg"></div> */}
                        <div className="work-desc">
                            <p className="work-tit">
                                {creation.creation_title}
                            </p>
                            <div className="work-info flex">
                                <p className="worker"> [ {creation.name} ] </p>
                                <p className="work-date black">
                                    {printDate(creation.creation_date)}
                                </p>
                            </div>
                        </div>
                    </li>
                ))}
                {showBigModal && (
                    <BigSizeModal
                        resultContent={selectedImage}
                        onClose={() => setShowBigModal(false)}
                    />
                )}
            </ul>
        </>
    );
};

export default ContestConts;
