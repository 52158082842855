import React, { useEffect, useState } from 'react';
import '../styles/FavoriteTraining.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFavoriteTrainingList } from '../api/ClassAPI';
import TrFavoriteComponent from '../components/TrFavoriteComponent';

const FavoriteTraining = () => {
    const USER_ID = localStorage.getItem('userID');
    const location = useLocation();
    const { initialCategory } = location.state || { initialCategory: 0 };
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(initialCategory);
    const [randomImage, setRandomImage] = useState();
    const [drawingData, setDrawingData] = useState([]); //그림 그리기
    const [writingData, setWritingData] = useState([]); //글 만들기

    console.log(drawingData);

    const navigate = useNavigate();

    const tempCategory = ['그림 그리기', '글 만들기']; //기타 다른 실습들 추가 될 예정
    const randomChar = ['/images/fav-nikki.svg', '/images/fav-arial.svg'];

    const handleCategoryClick = (index) => {
        setSelectedCategoryIndex(index);
    };

    useEffect(() => {
        const random_image = Math.floor(Math.random() * randomChar.length);
        setRandomImage(randomChar[random_image]);
        callFavoriteTrainingListAPI();
    }, []);

    const callFavoriteTrainingListAPI = async () => {
        const response = await getFavoriteTrainingList(USER_ID);

        if (response.success) {
            console.log('favorite list: ', response.data);

            // training_type에 따라 데이터를 분류
            const drawingData = response.data.filter(
                (item) => item.training_type === 'drawing'
            );
            const writingData = response.data.filter(
                (item) => item.training_type === 'writing'
            );

            setDrawingData(drawingData);
            setWritingData(writingData);
        } else {
            console.log(response.error.errMsg);
        }
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const getCurrentCategoryData = () => {
        return selectedCategoryIndex === 0 ? drawingData : writingData;
    };

    const currentCategoryData = getCurrentCategoryData();

    return (
        <section className="favorite-section">
            <div className="inner">
                <div className="fav-title-container">
                    <h2>즐겨찾기</h2>
                    <figure
                        className="fav-arrow-back"
                        onClick={handleBackClick}
                    >
                        <img
                            src={'/images/backarrow-gray.svg'}
                            alt=""
                        />
                    </figure>
                </div>
                <div className="categories-container">
                    {tempCategory.map((category, index) => (
                        <p
                            key={index}
                            onClick={() => {
                                handleCategoryClick(index);
                            }}
                            className={
                                selectedCategoryIndex === index
                                    ? 'category-btn on'
                                    : 'category-btn'
                            }
                        >
                            {category}
                        </p>
                    ))}
                </div>
                {currentCategoryData.length > 0 ? (
                    <TrFavoriteComponent data={currentCategoryData} />
                ) : (
                    <>
                        <figure>
                            <img
                                src={randomImage}
                                alt="Random Character"
                            />
                        </figure>
                        <div className="fav-txt-container">
                            <h4>즐겨찾기를 한 목록이 없습니다.</h4>
                            <p>마음에 드는 콘텐츠를 즐겨찾기 해보세요.</p>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default FavoriteTraining;
