import React, { useEffect, useState } from 'react';
import '../styles/Contact.scss';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { sendEmail } from '../api/SupportAPI';

const Contact = () => {
    const navigate = useNavigate();

    const [selectedRequest, setSelectedRequest] = useState('price');
    const [name, setName] = useState('');
    const [organization, setOrganization] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [region, setRegion] = useState('seoul');
    const [content, setContent] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);

    const handleRequestChange = (e) => {
        setSelectedRequest(e.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle form submission
    const handleContact = (e) => {
        e.preventDefault();
        // Validate required fields
        if (!name || !organization || !email || !phone || !content) {
            alert('모든 필수 항목을 입력해주세요.');
            return;
        }

        if (!isAgreed) {
            alert('개인정보 수집에 동의해주세요.');
            return;
        }

        // API call or form processing here
        console.log('Sending contact information:', {
            name,
            organization,
            email,
            phone,
            region,
            content,
        });
        callSendEmailAPI();
    };

    // 휴대폰 번호 숫자만 입력되었는지 확인하는 핸들러
    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setPhone(value);
        } else {
            setPhone('');
        }
    };

    const callSendEmailAPI = async () => {
        const result = await sendEmail(
            selectedRequest,
            name,
            organization,
            email,
            phone,
            region,
            content
        );
        if (result.success) {
            alert(
                '정상적으로 문의되었습니다. 빠른 시일 내로 연락드리겠습니다. 감사합니다.'
            );
            navigate('/');
        } else {
            //에러처리 필요
        }
    };

    return (
        <div id="wrap">
            <Header />
            <section className="inquery-section">
                <div className="inner">
                    <div className="tit-area">
                        <h5>AI 매직포유 문의하기</h5>
                        <h2>
                            아래 양식을 작성한 후 메시지를 보내주세요!
                            <br />
                        </h2>
                    </div>
                    <form onSubmit={handleContact}>
                        <div className="contact-form">
                            <h4>문의사항 선택</h4>
                            <div className="form-check-container">
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="price"
                                        value="price"
                                        name="request-check"
                                        checked={selectedRequest === 'price'}
                                        onChange={handleRequestChange}
                                    ></input>
                                    <label htmlFor="price">가격문의</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="teacher"
                                        value="teacher"
                                        name="request-check"
                                        checked={selectedRequest === 'teacher'}
                                        onChange={handleRequestChange}
                                    ></input>
                                    <label htmlFor="teacher">
                                        강사연수문의
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="ad"
                                        value="ad"
                                        name="request-check"
                                        checked={selectedRequest === 'ad'}
                                        onChange={handleRequestChange}
                                    ></input>
                                    <label htmlFor="ad">광고문의</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="document"
                                        value="document"
                                        name="request-check"
                                        checked={selectedRequest === 'document'}
                                        onChange={handleRequestChange}
                                    ></input>
                                    <label htmlFor="document">
                                        소개자료요청
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="platform"
                                        value="platform"
                                        name="request-check"
                                        checked={selectedRequest === 'platform'}
                                        onChange={handleRequestChange}
                                    ></input>
                                    <label htmlFor="platform">
                                        플랫폼 이용문의
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form">
                            <h4>문의사항 정보입력</h4>
                            <div className="form-row-container">
                                <div className="form-row">
                                    <label className="form-label">
                                        성함 <span>*</span>
                                    </label>
                                    <input
                                        placeholder="성함을 입력해주세요.(특수문자/숫자 불가능)"
                                        className="form-control"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    ></input>
                                </div>
                                <div className="form-row">
                                    <label className="form-label">
                                        문의기관명 <span>*</span>
                                    </label>
                                    <input
                                        placeholder="성함을 입력해주세요.(특수문자/숫자 불가능)"
                                        className="form-control"
                                        name="name"
                                        value={organization}
                                        onChange={(e) =>
                                            setOrganization(e.target.value)
                                        }
                                        type="text"
                                    ></input>
                                </div>
                                <div className="form-row">
                                    <label className="form-label">
                                        이메일 <span>*</span>
                                    </label>
                                    <input
                                        placeholder="이메일을 입력해주세요."
                                        className="form-control"
                                        name="name"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        type="text"
                                    ></input>
                                </div>
                                <div className="form-row">
                                    <label className="form-label">
                                        연락처 <span>*</span>
                                    </label>
                                    <input
                                        placeholder="연락처를 입력해주세요."
                                        className="form-control"
                                        name="name"
                                        value={phone}
                                        onChange={handlePhoneNumberChange}
                                        type="text"
                                    ></input>
                                    {/* 번호 */}
                                </div>
                                <div className="form-row">
                                    <label className="form-label">
                                        지역명 <span>*</span>
                                    </label>
                                    <select
                                        value={region}
                                        onChange={(e) =>
                                            setRegion(e.target.value)
                                        }
                                    >
                                        <option value="seoul">
                                            서울특별시
                                        </option>
                                        <option value="busan">
                                            부산광역시
                                        </option>
                                        <option value="deagu">
                                            대구광역시
                                        </option>
                                        <option value="incheon">
                                            인천광역시
                                        </option>
                                        <option value="gwangju">
                                            광주광역시
                                        </option>
                                        <option value="deajeon">
                                            대전광역시
                                        </option>
                                        <option value="ulsan">
                                            울산광역시
                                        </option>
                                        <option value="sejong">
                                            세종특별자지시
                                        </option>
                                        <option value="seoul">경기도</option>
                                        <option value="gangwondo">
                                            강원특별자치도
                                        </option>
                                        <option value="chungcheongbuk-do">
                                            충청북도
                                        </option>
                                        <option value="chungcheongnam-do">
                                            충청남도
                                        </option>
                                        <option value="jeollabuk-do">
                                            전북특별자치도
                                        </option>
                                        <option value="jeollanam-do">
                                            전라남도
                                        </option>
                                        <option value="gyeongsangbuk-do">
                                            경상북도
                                        </option>
                                        <option value="gyeongsangnam-do">
                                            경상남도
                                        </option>
                                        <option value="jeju-do">
                                            제주특별자치도
                                        </option>
                                    </select>
                                    {/* 지역명 */}
                                </div>
                                <div className="form-row">
                                    <label className="form-label">
                                        문의사항 <span>*</span>
                                    </label>
                                    <textarea
                                        rows="6"
                                        placeholder="문의내용을 입력해주세요."
                                        name="content"
                                        className="form-control"
                                        value={content}
                                        onChange={(e) =>
                                            setContent(e.target.value)
                                        }
                                    ></textarea>
                                </div>
                                <div className="form-row agree-check">
                                    {/* <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={isAgreed}
                                        onChange={(e) =>
                                            setIsAgreed(e.target.checked)
                                        }
                                        id="agreeCheckbox"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="agreeCheckbox"
                                    >
                                        <span className="test">
                                            도입문의를 위한 개인정보 수집에
                                            동의합니다. <em>*</em>
                                        </span>
                                    </label> */}
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="agreeCheckbox"
                                        hidden
                                        value={isAgreed}
                                        onChange={(e) =>
                                            setIsAgreed(e.target.checked)
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="agreeCheckbox"
                                    >
                                        <span className="checkbox-custom"></span>
                                        <span className="text">
                                            도입문의를 위한 개인정보 수집에
                                            동의합니다. <em>*</em>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form btn-area">
                            <button
                                type="submit"
                                className="submit-btn"
                            >
                                문의하기
                            </button>
                        </div>
                    </form>
                    <div className="more-info">
                        <div className="email-info">
                            <h5>간편하게 메일로 문의하세요!</h5>
                            <strong>chaintree0818@gmail.com</strong>
                        </div>
                        <div className="visit-info">
                            <h5>방문을 원하신다면 위치를 확인해주세요.</h5>
                            <dl>
                                <dt>
                                    <svg
                                        width="21"
                                        height="21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.362 1.793a6.646 6.646 0 00-6.638 6.638 6.571 6.571 0 001.337 3.983s.18.238.21.273l5.09 6.003 5.093-6.006c.027-.032.208-.27.208-.27v-.002A6.569 6.569 0 0017 8.432a6.646 6.646 0 00-6.638-6.639zm0 9.052a2.413 2.413 0 110-4.827 2.413 2.413 0 010 4.827z"
                                            fill="#191919"
                                        ></path>
                                    </svg>
                                    체인트리 본사
                                </dt>
                                <dd>
                                    울산광역시 중구 종가로 362-11
                                    울산과학기술진흥센터 3층 305호
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <figure
                        className="kakao-request-btn"
                        onClick={() =>
                            window.open(
                                'http://pf.kakao.com/_kMWxhn/friend',
                                '_blank'
                            )
                        }
                    >
                        <img
                            src={'/images/kakao_btn.png'}
                            alt="카카오 문의하기 버튼"
                        />
                    </figure>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Contact;
