import React from 'react';
import '../styles/TrGuideComponent.scss';
import TypingAnimation from './TypingAnimation';

const TrGuideComponent = ({
    onNext,
    onPrevious,
    training_description,
    selected_magickid,
}) => {
    const separators = /(\.|\n|!|\?|\~|@)/;
    const textParts = training_description
        .split(separators)
        .filter((part) => part.trim() !== '');

    return (
        <div className="tr-bg guide">
            <div className="inner">
                <div className="tr-box guide">
                    <div className="guide-long-txt">
                        {textParts.map((part, index) => {
                            if (
                                index < textParts.length - 1 &&
                                ['.', '!', '?', '~'].includes(
                                    textParts[index + 1]
                                )
                            ) {
                                return (
                                    <p key={index}>
                                        {part + textParts[index + 1]}
                                    </p>
                                );
                            } else if (
                                index < textParts.length - 1 &&
                                ['@'].includes(textParts[index + 1])
                            ) {
                                return <p key={index}>{part}</p>;
                            } else if (
                                ['.', '!', '?', '~', '@'].includes(part)
                            ) {
                                return null;
                            } else {
                                return <span key={index}>{part}</span>;
                            }
                        })}
                    </div>
                    {/* NOTE : 조이님 여기 타이핑 애니메이션 넣은고에요~! */}
                    {/* <TypingAnimation
                        fullText={training_description}
                        typingSpeed={100}
                    /> */}
                    <figure className="speaking-char">
                        <img
                            src={
                                selected_magickid === 'arial'
                                    ? '/images/arial.svg'
                                    : '/images/nikki.svg'
                            }
                            alt=""
                        />
                    </figure>
                </div>
                <div className="btn-container">
                    <div
                        className="button button-prev"
                        onClick={onPrevious}
                    >
                        ◀ 이전
                    </div>
                    <div
                        className="button button-next"
                        onClick={onNext}
                    >
                        다음 ▶
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrGuideComponent;
