import React, { useState, useRef, useMemo } from 'react';
import '../styles/TrSelectionComponent.scss';
import TrAlarmComponent from './TrAlarmComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ViewAllOptionsModal from './ViewAllOptionsModal';

const TrSelectionComponent = ({
    onNext,
    onPrevious,
    title,
    selection_list,
}) => {
    const [selectedButton, setSelectedButton] = useState({});
    const selection_titles = selection_list.selection_title;
    const selection_images = selection_list.selection_image;
    const [showAlert, setShowAlert] = useState(false);
    const swiperRef = useRef(null);
    const [isStepBtnsActive, setIsStepBtnsActive] = useState(false);
    const [showAllModal, setShowAllModal] = useState(false);

    // 선택지 랜덤으로 셋팅
    const [shuffledTitles, shuffledImages] = useMemo(() => {
        const combined = selection_titles.map((title, index) => ({
            title,
            image: selection_images[index],
        }));
        combined.sort(() => Math.random() - 0.5);
        return [
            combined.map(item => item.title),
            combined.map(item => item.image),
        ];
    }, [selection_titles, selection_images]);


    // 모달에서 선택된 값을 받아와 상태 업데이트
    const handleOptionSelect = (selectedOption) => {
        // setSelectedButton(selectedOption);
        // setShowAllModal(false);
        // setIsStepBtnsActive(true);
        const selectedIndex = selection_titles.findIndex(
            (title) =>
                title.kr === selectedOption.kr && title.en === selectedOption.en
        );
        setSelectedButton(selectedOption); // 선택된 옵션을 업데이트
        setIsStepBtnsActive(true); // 버튼 활성화

        // Swiper의 해당 인덱스로 이동
        if (swiperRef.current && selectedIndex !== -1) {
            swiperRef.current.swiper.slideTo(selectedIndex); // Swiper에서 선택된 슬라이드로 이동
        }

        setShowAllModal(false); // 모달 닫기
    };

    const handleButtonClick = (selectionKr, selectionEn) => {
        let selection = {
            kr: selectionKr,
            en: selectionEn,
        };
        setSelectedButton(selection);
        setShowAlert(false);
        setIsStepBtnsActive(true);
    };

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handleShowAllClick = () => {
        setShowAllModal(true);
    };

    const handleNextButtonClick = () => {
        if (!selectedButton.kr && !selectedButton.en) {
            setShowAlert(true); // 선택되지 않았을 때 알림 모달 표시
        } else {
            onNext(selectedButton); // 유효한 선택이 이루어지면 다음 단계로 진행
        }
    };

    // 변경된 함수: 빈 문자열을 추가하지 않도록 수정
    const addClassToWrapper = (swiper) => {
        if (swiper && swiper.wrapperEl) {
            const className = selection_titles.length <= 2 ? 'few-items' : null;
            if (className) {
                swiper.wrapperEl.classList.add(className);
            }
        }
    };

    return (
        <>
            <div className="tr-bg selection">
                <div className="inner">
                    <div className="tr-box selection">
                        <div className="text-wrap">
                            <p className="title-text">
                                <span> {title}을/를 골라볼까요?</span>
                            </p>
                            <span>
                                총 {selection_list.selection_title.length}개{' '}
                            </span>
                            <button
                                className="view-all-button"
                                onClick={handleShowAllClick}
                            >
                                <figure>
                                    <img
                                        src={'/images/list-white.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>옵션 전체보기</span>
                            </button>
                        </div>
                        <div className="arrow-btns">
                            <button
                                className="arrow arrow-prev"
                                onClick={handlePrevClick}
                            >
                                <img
                                    src={'/images/prev-arrow-red.svg'}
                                    alt=""
                                />
                            </button>
                            <button
                                className="arrow arrow-next"
                                onClick={handleNextClick}
                            >
                                <img
                                    src={'/images/next-arrow-red.svg'}
                                    alt=""
                                />
                            </button>
                        </div>

                        <Swiper
                            ref={swiperRef}
                            slidesPerView={3}
                            spaceBetween={20}
                            pagination={{
                                clickable: true,
                            }}
                            loop={true}
                            modules={[Pagination]}
                            onSwiper={addClassToWrapper}
                            className={`selction-swiper`}
                        >
                            {shuffledTitles.map((title, index) => {
                                const isSelected =
                                    selectedButton.en === title.en &&
                                    selectedButton.kr === title.kr;
                                return (
                                    <SwiperSlide
                                        key={index}
                                        className={`selections ${isSelected ? 'border' : ''
                                            }`}
                                        onClick={() =>
                                            handleButtonClick(
                                                title.kr,
                                                title.en
                                            )
                                        }
                                    >
                                        <figure>
                                            <img
                                                src={shuffledImages[index]}
                                                alt={title.kr}
                                                onError={(e) => {
                                                    e.target.src =
                                                        '/images/default-image.jpg';
                                                }}
                                            />
                                        </figure>
                                        <p>{title.kr}</p>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div
                        className={`step-btns ${isStepBtnsActive ? 'active' : ''
                            }`}
                    >
                        <div
                            className="button button-prev"
                            onClick={onPrevious}
                        >
                            ◀ 이전
                        </div>
                        <div
                            className="button button-next"
                            onClick={handleNextButtonClick}
                        >
                            다음 ▶
                        </div>
                    </div>
                </div>
            </div>
            {showAlert && (
                <TrAlarmComponent
                    onClose={() => setShowAlert(false)}
                    title={title}
                />
            )}
            {showAllModal && (
                <ViewAllOptionsModal
                    selection_list={selection_list}
                    onClose={() => setShowAllModal(false)}
                    onSelect={handleOptionSelect} // 선택된 값을 받아오는 함수
                />
            )}
        </>
    );
};

export default TrSelectionComponent;
