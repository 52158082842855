import React, { useState, useEffect, useRef } from 'react';
import '../styles/TrFinishSelectionComponent.scss';

const TrFinishSelectionComponent = ({
    onNext,
    onPrevious,
    selected_magickid,
    prompt_content,
    selected_kr_list,
    selectedType,
}) => {
    const [finalPrompt, setFinalPrompt] = useState('');
    const [typedText, setTypedText] = useState('');
    const [showFirstBubble, setShowFirstBubble] = useState(false);
    const [showSecondBubble, setShowSecondBubble] = useState(false);
    const [showAiBubble, setShowAiBubble] = useState(false);
    const aiBubbleRef = useRef(null);
    const secondBubbleRef = useRef(null);

    useEffect(() => {
        let updatedPrompt = '';

        if (prompt_content) {
            updatedPrompt = prompt_content;
            for (let i = 0; i < selected_kr_list.length; i++) {
                const placeholder = `[선택${i + 1}]`;
                updatedPrompt = updatedPrompt.replace(
                    placeholder,
                    selected_kr_list[i]
                );
            }
        } else {
            updatedPrompt = selected_kr_list.join(', ');
        }
        console.log('Updated Prompt:', updatedPrompt);
        setFinalPrompt(updatedPrompt);
        setTypedText(''); // 초기화하여 앞 글자가 빠지는 문제 해결

        setTimeout(() => setShowFirstBubble(true), 0);
    }, [selected_kr_list, prompt_content]);

    useEffect(() => {
        console.log('Final Prompt:', finalPrompt);
        if (finalPrompt && showFirstBubble) {
            let index = 0;
            const interval = setInterval(() => {
                if (index < finalPrompt.length) {
                    const char = finalPrompt.charAt(index);
                    setTypedText((prev) => {
                        // undefined라는 단어 걸러내기
                        const newText =
                            prev + (char !== 'undefined' ? char : '');
                        return newText.includes('undefined') ? prev : newText;
                    });
                    index++;
                } else {
                    clearInterval(interval);
                    // 타이핑이 끝난 후 showSecondBubble을 true로 설정
                    setTimeout(() => {
                        setShowSecondBubble(true);
                    }, 500);
                }
            }, 100); // 타이핑 속도 조절
            return () => clearInterval(interval);
        }
    }, [showFirstBubble, finalPrompt]);

    useEffect(() => {
        if (showSecondBubble) {
            setTimeout(() => {
                if (secondBubbleRef.current) {
                    secondBubbleRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
                // 두 번째 말풍선을 표시한 후 showAiBubble을 true로 설정
                setTimeout(() => {
                    setShowAiBubble(true);
                    setTimeout(() => {
                        if (aiBubbleRef.current) {
                            aiBubbleRef.current.scrollIntoView({
                                behavior: 'smooth',
                            });
                        }
                    }, 100); // 세 번째 말풍선 표시 후 스크롤 처리
                }, 1000); // 두 번째 말풍선이 표시된 후 2초 후에 세 번째 말풍선 표시
            }, 100);
        }
    }, [showSecondBubble]);

    return (
        <>
            <div
                className="tr-bg selcomplete"
                style={{ overflow: 'auto' }}
            >
                <div className="inner">
                    <div className="tr-box selcomplete">
                        <h3 className="complete-txt">선택이 완료 되었어요!</h3>
                        <div className="divider"></div>
                        <div className="express-bubbles">
                            {showFirstBubble && (
                                <div
                                    className="char-says-bubble-conts"
                                    ref={secondBubbleRef}
                                >
                                    <figure className="speaking-char-conts">
                                        <img
                                            src={
                                                selected_magickid === 'arial'
                                                    ? '/images/bubble-arial.svg'
                                                    : '/images/bubble-niki.svg'
                                            }
                                            alt=""
                                        />
                                    </figure>
                                    <p className="guide-long-txt-conts typing">
                                        모든 선택을 완료했어요! <br />
                                        <span className="prompt-finish-box">
                                            {typedText}
                                        </span>
                                    </p>
                                </div>
                            )}
                            {showSecondBubble && (
                                <div className="char-says-bubble-conts">
                                    <figure className="speaking-char-conts">
                                        <img
                                            src={
                                                selected_magickid === 'arial'
                                                    ? '/images/bubble-arial.svg'
                                                    : '/images/bubble-niki.svg'
                                            }
                                            alt=""
                                        />
                                    </figure>
                                    <p className="guide-long-txt-conts">
                                        내용으로 AI에게 만들어달라고 할게!
                                    </p>
                                </div>
                            )}
                            {showAiBubble && (
                                <div
                                    className="ai-says-bubble-conts"
                                    ref={aiBubbleRef}
                                >
                                    <p className="guide-long-txt-conts">
                                        내가 한번 만들어볼게!
                                    </p>
                                    <figure className="speaking-char-conts">
                                        <img
                                            src={`/images/${selectedType}-ai.svg`}
                                            alt=""
                                        />
                                    </figure>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="step-btns">
                        <div
                            className="button button-prev"
                            onClick={onPrevious}
                        >
                            ◀ 이전
                        </div>
                        <div
                            className="button button-next"
                            onClick={onNext}
                        >
                            다음 ▶
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrFinishSelectionComponent;
