import React, { useEffect, useState } from 'react';
import '../styles/TeacherTab.scss';
import UsageBtnComponent from './UsageBtnComponent';

// 2024.01.04 | props 넘겨받은 값으로 변경
const TeacherTab = ({ teacherData, historyList, allSchoolMagicbeanList }) => {
    console.log('teacherData: ', teacherData);
    console.log('historyList: ', historyList);
    console.log('allSchoolMagicbeanList: ', allSchoolMagicbeanList);
    // tab active
    const [activeTab, setActiveTab] = useState('tab_creations');

    // 2024-02-15 | '전체학급' 또는 '우리반'
    const [filterClass, setFilterClass] = useState('전체학급');
    // 2024-02-15 | '최신 순' 또는 '오래된 순'
    const [sortOrder, setSortOrder] = useState('최신순');
    // 2024-02-15 | '전체보기','사용콩','충전콩'
    const [filterType, setFilterType] = useState('전체보기');
    const [processedMagicbeanList, setProcessedMagicbeanList] = useState([]);
    // 2024-03-18 | 검색창에 입력된 텍스트 상태 관리
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchFormSubmit = (event) => {
        event.preventDefault();
    };

    // 2024-02-15 | 필터링 함수
    useEffect(() => {
        const processMagicbeanList = () => {
            let processedList = [...allSchoolMagicbeanList];

            if (filterClass !== '전체학급') {
                processedList = processedList.filter(
                    (item) => item.grade_class === filterClass
                );
            }

            if (filterType !== '전체보기') {
                processedList = processedList.filter((item) =>
                    filterType === '사용콩'
                        ? item.history_type === 'USE'
                        : filterType === '충전콩'
                        ? item.history_type !== 'USE'
                        : true
                );
            }

            if (sortOrder === '최신순') {
                processedList.sort(
                    (a, b) =>
                        new Date(b.history_date) - new Date(a.history_date)
                );
            } else if (sortOrder === '오래된 순') {
                processedList.sort(
                    (a, b) =>
                        new Date(a.history_date) - new Date(b.history_date)
                );
            }

            // 2024-03-18 | 검색기능 필터링 if문 추가
            if (searchQuery.trim() !== '') {
                processedList = processedList.filter(
                    (item) =>
                        item.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                        item.grade_class
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                        item.usage_description
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                );
            }

            return processedList;
        };

        setProcessedMagicbeanList(processMagicbeanList());
    }, [
        filterClass,
        sortOrder,
        filterType,
        allSchoolMagicbeanList,
        searchQuery,
    ]);

    // 선택된 탭 active
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const printDate = (date) => {
        const resultDate = date.split('T')[0];
        return resultDate;
    };

    return (
        <>
            <div className="toggle-tab-list">
                <button
                    onClick={() => handleTabClick('tab_creations')}
                    className={`toggle-btn ${
                        activeTab === 'tab_creations' ? 'active' : ''
                    }`}
                >
                    우리반 현황
                </button>
                <button
                    onClick={() => handleTabClick('tab_magicbeans')}
                    className={`toggle-btn ${
                        activeTab === 'tab_magicbeans' ? 'active' : ''
                    }`}
                >
                    우리학교 전체 콩 사용내역
                </button>
            </div>
            <div className="tab-contents">
                {activeTab === 'tab_creations' && (
                    <div className="tab-1-conts">
                        <div className="tab-head-conts">
                            <div className="conts-left">
                                <figure></figure>
                                <div className="teacher-info">
                                    <p>
                                        <em>{teacherData.school_name}</em>
                                        <em> {teacherData.grade_class}</em>반
                                    </p>
                                    <h3>
                                        <em>{teacherData.name}</em> 선생님
                                    </h3>
                                </div>
                            </div>
                            <div className="conts-right">
                                <p>
                                    학급 인원 :
                                    <em>
                                        {' '}
                                        {teacherData.number_of_students ?? '0'}
                                    </em>
                                    명
                                </p>
                            </div>
                        </div>
                        <ul className="table-caption-list">
                            <li>그림</li>
                            <li>글쓰기</li>
                            <li>그림동화</li>
                            <li>스토리랜드</li>
                        </ul>
                        <table className="student-table">
                            <thead>
                                <tr>
                                    <th rowSpan="2">번호</th>
                                    <th
                                        colSpan="3"
                                        rowSpan="2"
                                        className="left"
                                    >
                                        이름
                                    </th>
                                    <th
                                        colSpan="9"
                                        className="left bottom"
                                    >
                                        학생들이 저장한 작품 수
                                    </th>
                                    <th
                                        colSpan="12"
                                        className="left bottom"
                                    >
                                        학생들이 체험한 횟수
                                    </th>
                                    <th
                                        colSpan="2"
                                        rowSpan="2"
                                        className="left"
                                    >
                                        잔여 콩
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        그림
                                    </th>
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        글
                                    </th>
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        그림동화
                                    </th>
                                    {/* 2024-03-18 | th 추가 */}
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        그림 그리기 체험
                                    </th>
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        글쓰기 체험
                                    </th>
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        동화 만들기 체험
                                    </th>
                                    {/* NOTE: 2024-03-18 스토리랜드 동화읽이 열추가 (수정하고 주석지우고 푸시해주시면 좋겠습니당!) */}
                                    <th
                                        colSpan="3"
                                        className="left"
                                    >
                                        스토리랜드 체험
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {historyList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td colSpan="3">
                                            {item.name.length === 0
                                                ? '-'
                                                : item.name}
                                        </td>
                                        <td
                                            colSpan="3"
                                            className="pink-reverse"
                                        >
                                            <span className="pink-reverse">
                                                <em>
                                                    {
                                                        item.creation_drawing_count
                                                    }
                                                </em>
                                                개
                                            </span>
                                        </td>
                                        <td
                                            colSpan="3"
                                            className="yellow-reverse"
                                        >
                                            <span className="yellow-reverse">
                                                <em>
                                                    {
                                                        item.creation_writing_count
                                                    }
                                                </em>
                                                개
                                            </span>
                                        </td>
                                        <td
                                            colSpan="3"
                                            className="green-reverse"
                                        >
                                            <span className="green-reverse">
                                                <em>
                                                    {
                                                        item.creation_storybook_count
                                                    }
                                                </em>
                                                개
                                            </span>
                                        </td>
                                        <td
                                            colSpan="3"
                                            className="pink"
                                        >
                                            <span className="pink">
                                                <em>{item.drawing_count}</em>회
                                            </span>
                                        </td>
                                        <td
                                            colSpan="3"
                                            className="yellow"
                                        >
                                            <span className="yellow">
                                                <em>{item.writing_count}</em>회
                                            </span>
                                        </td>
                                        <td
                                            colSpan="3"
                                            className="green"
                                        >
                                            <span className="green">
                                                <em>
                                                    {item.storybook_like_count}
                                                </em>
                                                회
                                            </span>
                                        </td>
                                        {/* NOTE: 2024-03-18 스토리랜드 동화읽이 열추가 (수정하고 주석지우고 푸시해주시면 좋겠습니당!) */}
                                        <td
                                            colSpan="3"
                                            className="green-light"
                                        >
                                            <span className="green-light">
                                                <em>{item.storyland_count}</em>
                                                회
                                            </span>
                                        </td>
                                        <td colSpan="2">
                                            <em className="blue-bold">
                                                {item.total_magicbean}
                                            </em>
                                            개
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {activeTab === 'tab_magicbeans' && (
                    <div>
                        <div className="options-container">
                            <form
                                className="search-form"
                                onSubmit={handleSearchFormSubmit}
                            >
                                <input
                                    type="search"
                                    placeholder="검색어를 입력하세요"
                                    className="input-search"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                ></input>
                                <button type="submit">검색</button>
                            </form>
                            <div className="select-wrap">
                                <select
                                    onChange={(e) =>
                                        setFilterClass(e.target.value)
                                    }
                                >
                                    <option value="전체학급">전체학급</option>
                                    <option value="별님">별님반</option>
                                </select>
                                <select
                                    onChange={(e) =>
                                        setSortOrder(e.target.value)
                                    }
                                >
                                    <option value="최신순">최신순</option>
                                    <option value="오래된 순">오래된 순</option>
                                </select>
                                <select
                                    onChange={(e) =>
                                        setFilterType(e.target.value)
                                    }
                                >
                                    <option value="전체보기">전체보기</option>
                                    <option value="사용콩">사용콩</option>
                                    <option value="충전콩">충전콩</option>
                                </select>
                            </div>
                        </div>
                        <table className="all-school-situation">
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th
                                        colSpan="2"
                                        className="left"
                                    >
                                        이름
                                    </th>
                                    <th
                                        colSpan="2"
                                        className="left"
                                    >
                                        반
                                    </th>
                                    <th
                                        colSpan="2"
                                        className="left"
                                    >
                                        마법콩 이력
                                    </th>
                                    <th
                                        colSpan="2"
                                        className="left"
                                    >
                                        사용 마법콩
                                    </th>
                                    <th
                                        colSpan="4"
                                        className="left"
                                    >
                                        설명
                                    </th>
                                    <th
                                        colSpan="2"
                                        className="left"
                                    >
                                        날짜
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {processedMagicbeanList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td colSpan="2">{item.name}</td>
                                        <td colSpan="2">
                                            {item.grade_class}반
                                        </td>
                                        <td
                                            colSpan="2"
                                            className={
                                                item.history_type === 'USE'
                                                    ? 'text-red'
                                                    : 'text-blue'
                                            }
                                        >
                                            {item.history_type === 'USE'
                                                ? '사용'
                                                : '충전'}
                                        </td>
                                        <td
                                            colSpan="2"
                                            className={
                                                item.history_type === 'USE'
                                                    ? 'text-red'
                                                    : 'text-blue'
                                            }
                                        >
                                            {item.history_type === 'USE'
                                                ? `-${item.amount}`
                                                : `+${item.amount}`}
                                            개
                                        </td>
                                        <td colSpan="4">
                                            {item.usage_description}
                                        </td>
                                        <td colSpan="2">
                                            {printDate(item.history_date)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* <p className="info-txt">
                            * 최근 30개의 내역만 보입니다.
                        </p> */}
                    </div>
                )}
            </div>
            <UsageBtnComponent />
        </>
    );
};

export default TeacherTab;
// 2024.01.02 | 학생관리 등 선생님 페이지 제작
