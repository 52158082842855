// 2024-02-14 | 나의 콩 사용내역
import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import '../styles/History.scss';
// 2024-02-16 | TotalMaigcbean api
import { getTotalMagicbean } from '../api/MagicbeanAPI';
import { getMagicbeanHistory } from '../api/MagicbeanAPI';
import ErrorModal from '../components/ErrorModal';
import { ERROR } from '../utils/Constant';
import { StyleSheetContext } from 'styled-components';
import NewHeader from '../components/NewHeader';

const History = () => {
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    // 2024-02-15 | 원본 데이터를 저장할 새로운 상태 추가
    const [originalHistoryList, setOriginalHistoryList] = useState([]);
    // 2024-02-15 | 문자열 대신 배열로 변경
    const [historyList, setHistoryList] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // 2024-02-15 | 날짜 순서 상태
    const [sortOrder, setSortOrder] = useState('최신순');
    // 2024-02-15 | 유형 필터링 상태
    const [historyType, setHistoryType] = useState('전체보기');
    // 2024-02-16 | totalMiagicBean 상태
    const [totalMagicbean, setTotalMagicbean] = useState(null);

    useEffect(() => {
        callgetMagicbeanHistoryAPIFunc();
        callgetTotalMagicbeanAPIFunc();
    }, []);

    const callgetMagicbeanHistoryAPIFunc = async () => {
        console.log('USER_ID: ', USER_ID);
        const response = await getMagicbeanHistory(USER_ID);

        if (response.success) {
            console.log(response.data);
            // 2024-02-15 | 원본 데이터 상태 업데이트
            setOriginalHistoryList(response.data);
            // 2024-02-15 | 초기 표시를 위한 데이터 상태도 업데이트
            setHistoryList(response.data);
            if (response.data) setHistoryList(response.data);
            else {
                console.log(response.error);
                setErrorMessage(ERROR.busy);
            }
        } else {
            console.log(response.error.errMsg);
        }
    };

    const callgetTotalMagicbeanAPIFunc = async () => {
        const response = await getTotalMagicbean(USER_ID);

        if (response.success) {
            console.log(response.data);
            if (response.data) setTotalMagicbean(response.data);
            else setTotalMagicbean(0);
        } else {
            console.log(response.error.errMsg);
        }
    };

    // 2024-02-15 | useEffect 추가
    useEffect(() => {
        processHistoryList();
    }, [sortOrder, historyType]);

    // 2024-02-15 | 원본 데이터 바탕으로 분류 작업
    const processHistoryList = () => {
        let processed_list = [...originalHistoryList];

        if (historyType !== '전체보기') {
            processed_list = processed_list.filter((item) =>
                historyType === '사용 콩'
                    ? item.history_type === 'USE'
                    : item.history_type !== 'USE'
            );
        }

        if (sortOrder === '최신순') {
            processed_list.sort(
                (a, b) => new Date(b.history_date) - new Date(a.history_date)
            );
        } else if (sortOrder === '오래된 순') {
            processed_list.sort(
                (a, b) => new Date(a.history_date) - new Date(b.history_date)
            );
        }

        setHistoryList(processed_list);
    };

    const printDate = (dateString) => {
        // 입력된 UTC 시간 문자열을 Date 객체로 변환
        const date = new Date(dateString);

        // 한국 시간(KST)으로 변환된 날짜와 시간 추출
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // YYYY-MM-DD (HH:MM:SS) 형식으로 문자열 결합
        return `${year}-${month}-${day} (${hours}:${minutes}:${seconds})`;
    };

    return (
        <section className="contents">
            {/* <div className="left-contents">
                <Navigation />
            </div> */}
            {showErrorModal && (
                <ErrorModal
                    error_message={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                ></ErrorModal>
            )}
            <div className="right-contents history-detail">
                <NewHeader />
                <div className="inner">
                    {/* 2024-02-16 | remain-beans-container 추가 */}
                    <div className="remain-beans-container">
                        <h3 className="history-tit">
                            <em>{USER_NAME} 님</em>은 마법콩을 이렇게
                            사용했어요!
                        </h3>
                        <div className="remain-beans">
                            <figure>
                                <img
                                    src={'/images/bean.svg'}
                                    alt="매직콩"
                                />
                            </figure>
                            <p>
                                잔여 콩 :{' '}
                                <em className="blue-bold">{totalMagicbean}</em>{' '}
                                콩
                            </p>
                        </div>
                    </div>
                    {/* 2024-02-15 | options-container 태그 추가 */}
                    <div className="options-container">
                        <select
                            className="options"
                            onChange={(e) => setSortOrder(e.target.value)}
                        >
                            <option value="최신순">최신순</option>
                            <option value="오래된 순">오래된 순</option>
                        </select>
                        <select
                            className="options"
                            onChange={(e) => setHistoryType(e.target.value)}
                        >
                            <option value="전체보기">전체보기</option>
                            <option value="사용 콩">사용 콩</option>
                            <option value="충전 콩">충전 콩</option>
                        </select>
                    </div>
                    <table className="bean-history">
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>마법콩 이력</th>
                                <th>사용 마법콩</th>
                                <th colSpan="3">설명</th>
                                <th>날짜</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyList.length !== 0 &&
                                historyList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td
                                            className={
                                                item.history_type === 'USE'
                                                    ? 'text-red'
                                                    : 'text-blue'
                                            }
                                        >
                                            {item.history_type === 'USE'
                                                ? '사용'
                                                : '충전'}
                                        </td>
                                        <td
                                            className={
                                                item.history_type === 'USE'
                                                    ? 'text-red'
                                                    : 'text-blue'
                                            }
                                        >
                                            {item.history_type === 'USE'
                                                ? `-${item.amount}`
                                                : `+${item.amount}`}
                                            개
                                        </td>
                                        <td colSpan="3">
                                            {item.usage_description}
                                        </td>
                                        <td>{printDate(item.history_date)}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default History;
