import React, { useEffect, useState } from 'react';
import '../styles/Membership.scss';
import { useNavigate } from 'react-router-dom';

import LandScapeModal from '../components/LandScapeModal';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
    checkDuplicateEmail,
    checkValidUserID,
    teacherSignup,
} from '../api/UserAPI';

const Membership = () => {
    const navigate = useNavigate();
    // 이메일 주소 저장 (사용자가 email 입력하면 상태를 입력값으로 변경)
    const [user_id, setUserID] = useState('');
    const [idErrorMessage, setIdErrorMessage] = useState('');
    const [isUserIDValid, setIsUserIDValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [email, setEmail] = useState('');
    // 이메일 도메인 상태 관리
    const [emailDomain, setEmailDomain] = useState('');
    // 이메일 중복확인 메세지 저장
    const [emailDuplicateMessage, setEmailDuplicateMessage] = useState('');
    // 비밀번호 유효성 확인 (숫자+영문포함 8자리)
    const [password, setPassword] = useState('');
    // 비밀번호 확인
    const [confirmPassword, setConfirmPassword] = useState('');
    // 비밀번호 유효성 에러 확인
    const [passwordError, setPasswordError] = useState('');
    // 비밀번호 일치 여부 확인
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    // 이름
    const [name, setName] = useState('');
    // 휴대폰 번호 상태
    const [phoneNumber, setPhoneNumber] = useState('');
    // 휴대폰 번호 에러 확인
    const [phoneNumberError, setPhoneNumberError] = useState('');
    // 소속 학교
    const [school, setSchool] = useState('');
    // 반
    const [classroom, setClassroom] = useState('');
    // 랜덤 로고
    const [logoImage, setLogoImage] = useState();
    // 이용약관 전체 내용 보이기
    const [showTerm, SetShowTerm] = useState(false);
    // 개인정보처리방침 전체 내용 보이기
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    // 이용약관 체크 확인
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    // 개인정보처리방침 체크 확인
    const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
    // 이메일 강제 사용 상태
    const [isForceEmailUse, setIsForceEmailUse] = useState(false);
    const [privacyContent, setPrivacyContent] = useState('');
    const [termContents, setTermContents] = useState('');

    useEffect(() => {
        // 개인정보 처리방침
        const fetchPrivacyPolicy = async () => {
            try {
                const response = await fetch('/utils/PrivacyPolicy.txt');
                const text = await response.text();
                setPrivacyContent(text);
            } catch (error) {
                console.error('Failed to fetch the privacy policy file', error);
            }
        };

        // 이용약관
        const fetchTermsOfService = async () => {
            try {
                const response = await fetch('/utils/TermsOfService.txt');
                const text = await response.text();
                setTermContents(text);
            } catch (error) {
                console.error(
                    'Failed to fetch the terms of service file',
                    error
                );
            }
        };

        fetchPrivacyPolicy();
        fetchTermsOfService();
    }, []);

    // 이메일 임시 데이터
    const existingMockEmails = {
        email: ['test@naver.com', 'test@daum.net', 'test@google.com'],
    };

    const handleUserIDChange = (event) => {
        //NOTE: 숫자만 입력 가능하고 4자리,7자리 이후 - 추가
        const value = event.target.value;
        const nonNumeric = /[^0-9-]/g;

        // 입력된 값이 숫자와 하이픈만 포함하고 있는지 확인
        if (nonNumeric.test(value)) {
            setIdErrorMessage('* 숫자만 입력해주세요');
            return; // 에러가 발생했으므로 추가 처리를 중단
        } else {
            setIdErrorMessage(''); // 에러 메시지 초기화
        }

        // 입력 값에 대한 포맷 변경
        let formattedValue = value;
        if (formattedValue.length > 4 && formattedValue[4] !== '-') {
            formattedValue =
                formattedValue.slice(0, 4) + '-' + formattedValue.slice(4);
        }
        if (formattedValue.length > 8 && formattedValue[8] !== '-') {
            formattedValue =
                formattedValue.slice(0, 8) + '-' + formattedValue.slice(8);
        }
        if (formattedValue.length > 12) {
            formattedValue = formattedValue.slice(0, 12);
        }

        setUserID(formattedValue);
    };

    const checkUserID = async () => {
        const id = user_id.replace(/-/g, '');
        if (!id || id.length < 10) {
            alert('로그인 ID를 입력해주세요');
            return;
        }
        console.log(id);
        const result = await checkValidUserID(id);
        if (result.success) {
            console.log(result.data);
            if (result.data === 'possible') {
                setIdErrorMessage('* 로그인 ID가 확인되었습니다.');
                setIsUserIDValid(true);
            } else if (result.data === 'already') {
                setIdErrorMessage('* 이미 회원가입이 된 로그인 ID입니다.');
                setIsUserIDValid(false);
            } else {
                setIdErrorMessage(
                    '* 유효하지 않은 로그인 ID 입니다. \n 발급받은 로그인 ID가 없다면 도입문의 해주시기 바랍니다.'
                );
                setIsUserIDValid(false);
            }
        } else {
            console.log('err');
        }
    };

    // 이메일 입력 변경 핸들러 (사용자가 선택한 option으로 도메인 변경)
    const handleEmailInput = (event, field_type) => {
        if (field_type === 'address') {
            setEmail(event.target.value);
        } else if (field_type === 'domain') {
            setEmailDomain(event.target.value);
        }
    };

    // 이메일 중복 확인 핸들러
    const checkEmailDuplicate = async () => {
        if (!email || !emailDomain) {
            alert('전체 이메일을 입력해주세요');
            return;
        }

        // NOTE : 0826 이메일 중복확인 처리 하기 전 코드
        // const full_email = email + '@' + emailDomain;
        // const result = await checkDuplicateEmail(full_email);
        // if (result.success) {
        //     console.log(result.data);
        //     setEmailDuplicateMessage('* 사용 가능한 이메일입니다.');
        //     setIsEmailValid(true);
        // } else {
        //     setEmailDuplicateMessage('* 사용중인 이메일입니다.');
        //     setIsEmailValid(false);
        // }

        const full_email = email + '@' + emailDomain;
        const result = await checkDuplicateEmail(full_email);
        if (result.success) {
            setEmailDuplicateMessage('* 사용 가능한 이메일입니다.');
            setIsEmailValid(true);
        } else {
            const userConfirmation = window.confirm(
                '이메일이 중복됩니다. 이대로 사용 할까요?'
            );
            console.log('userConfirmation', userConfirmation);
            if (userConfirmation) {
                setIsForceEmailUse(true);
                setIsEmailValid(true);
            } else {
                setEmailDuplicateMessage('* 사용중인 이메일입니다.');
                setIsEmailValid(false);
            }
        }
    };

    // 비밀번호 유효성 검사
    const validatePassword = (pw) => {
        return pw.length >= 8 && /[a-zA-Z]/.test(pw) && /[0-9]/.test(pw);
    };

    // 비밀번호 변경
    const handlePasswordChange = (event) => {
        const pw = event.target.value;
        setPassword(pw);
        setPasswordError(
            validatePassword(pw)
                ? ''
                : '* 비밀번호는 숫자와 영문을 포함한 8자리 이상이어야 합니다.'
        );
    };

    // 비밀번호 확인
    const handleConfirmPasswordChange = (event) => {
        const confirm_pw = event.target.value;
        setConfirmPassword(confirm_pw);
        setConfirmPasswordError(
            confirm_pw === password ? '' : '* 비밀번호가 일치하지 않습니다.'
        );
    };

    // 휴대폰 번호 숫자만 입력되었는지 확인하는 핸들러
    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setPhoneNumber(value);
            setPhoneNumberError('');
        } else {
            setPhoneNumberError('* 숫자만 입력해 주세요.');
            setPhoneNumber('');
        }
    };

    // 이용약관 체크상태 확인하는 핸들러
    const handleTermsCheckboxChange = (event) => {
        setIsTermsChecked(event.target.checked);
    };

    // 개인정보처리방침 체크상태 확인하는 핸들러
    const handlePrivacyPolicyCheckboxChange = (event) => {
        setIsPrivacyPolicyChecked(event.target.checked);
    };

    // 폼 제출 이벤트 핸들러
    const handleSubmit = (event) => {
        event.preventDefault();

        // 모든 폼이 채워져있는지 확인 후 비어있는게 있다면 alert
        if (
            !email ||
            !emailDomain ||
            !password ||
            !confirmPassword ||
            !name ||
            !phoneNumber ||
            // !school ||
            // !classroom ||
            !isTermsChecked ||
            !isPrivacyPolicyChecked
        ) {
            alert('모든 필드를 입력하고, 필수 약관에 동의해주세요.');
            return;
        }

        // 이메일 전체 형태 확인
        const full_email = email + '@' + emailDomain;

        if (!/^[^@]+@[^@]+\.[^@]+$/.test(full_email)) {
            alert('유효하지 않은 이메일 주소입니다.');
            return;
        }

        // 비밀번호 일치 여부 확인
        if (password !== confirmPassword) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if (!isUserIDValid) {
            alert('로그인 ID를 확인해주세요.');
            return;
        }

        if (!isEmailValid && !isForceEmailUse) {
            alert('이메일을 확인해주세요.');
            return;
        }

        // 모든 폼이 채워졌다는 전제 하에 임시로 modal로 이동
        callSignupAPI();
    };

    const logos = [
        '/images/logo-1.svg',
        '/images/logo-2.svg',
        '/images/logo-4.svg',
    ];

    useEffect(() => {
        const random_index = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[random_index]);
    }, []);

    const handleDetailClick = () => {
        SetShowTerm(!showTerm);
    };
    const handlePrivacyPolicyClick = () => {
        setShowPrivacyPolicy(!showPrivacyPolicy);
    };

    const callSignupAPI = async () => {
        const id = user_id.replace(/-/g, '');
        const full_email = email + '@' + emailDomain;
        const result = await teacherSignup(
            id,
            full_email,
            name,
            password,
            phoneNumber,
            school,
            classroom,
            isTermsChecked,
            isPrivacyPolicyChecked
        );
        if (result.success) {
            localStorage.setItem('signup-name', name);
            navigate('/commodal');
        } else {
            alert(
                '잠시후에 다시 시도해주세요. 또 문제를 발생한 경우, 문의부탁드립니다. 불편을 드려 죄송합니다.'
            );
        }
    };

    return (
        <>
            <div
                id="wrap"
                className="membership-wrap"
            >
                <Header />
                <section className="join-section">
                    <div className="inner">
                        <h3 className="join-tit">회원가입</h3>
                        <div className="txt-container">
                            <h4>필수입력 정보</h4>
                            <p>필수 항목이므로 반드시 입력해주시기 바랍니다.</p>
                        </div>
                        <form
                            className="membership-form"
                            onSubmit={handleSubmit}
                        >
                            <div className="inputs">
                                <p>로그인 ID</p>
                                <input
                                    type="text"
                                    placeholder="로그인 ID 예시) 0001-234-567"
                                    value={user_id}
                                    onChange={handleUserIDChange}
                                />
                                <button
                                    type="button"
                                    className="overlap-chk id-chk"
                                    onClick={checkUserID}
                                >
                                    ID 확인
                                </button>
                                {idErrorMessage && (
                                    <p
                                        className={`error-message ${idErrorMessage ===
                                                '* 로그인 ID가 확인되었습니다.'
                                                ? 'message-success'
                                                : 'error-message'
                                            }`}
                                    >
                                        {idErrorMessage}
                                    </p>
                                )}
                            </div>
                            <div className="inputs">
                                <p>이메일</p>
                                <input
                                    className="email"
                                    type="text"
                                    placeholder="이메일"
                                    value={email}
                                    onChange={(event) =>
                                        handleEmailInput(event, 'address')
                                    }
                                />
                                <span className="at">@</span>
                                <input
                                    className="email"
                                    type="text"
                                    value={emailDomain}
                                    onChange={(event) =>
                                        handleEmailInput(event, 'domain')
                                    }
                                />
                                <select
                                    className="email-options"
                                    onChange={(event) =>
                                        handleEmailInput(event, 'domain')
                                    }
                                >
                                    <option value="">직접입력</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="daum.net">daum.net</option>
                                    <option value="gmail.com">gmail.com</option>
                                </select>
                                <button
                                    type="button"
                                    className="overlap-chk"
                                    onClick={checkEmailDuplicate}
                                >
                                    중복확인
                                </button>
                                {emailDuplicateMessage && (
                                    <p
                                        className={`error-message ${emailDuplicateMessage ===
                                                '* 사용 가능한 이메일입니다.'
                                                ? 'message-success'
                                                : 'error-message'
                                            }`}
                                    >
                                        {emailDuplicateMessage}
                                    </p>
                                )}
                            </div>
                            <div className="inputs">
                                <p>비밀번호</p>
                                <input
                                    type="password"
                                    placeholder="비밀번호"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                {passwordError && (
                                    <p className="error-message">
                                        {passwordError}
                                    </p>
                                )}
                            </div>
                            <div className="inputs">
                                <p>비밀번호</p>
                                <input
                                    type="password"
                                    placeholder="비밀번호 확인"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                                {confirmPasswordError && (
                                    <p className="error-message">
                                        {confirmPasswordError}
                                    </p>
                                )}
                            </div>
                            <div className="inputs">
                                <p>이름</p>
                                <input
                                    type="text"
                                    placeholder="이름"
                                    className="colored-input"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="inputs">
                                <p>휴대폰 번호</p>
                                <input
                                    type="tel"
                                    placeholder="휴대폰 번호"
                                    className="colored-input"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                                {phoneNumberError && (
                                    <p className="error-message">
                                        {phoneNumberError}
                                    </p>
                                )}
                            </div>
                            <div className="terms-container">
                                <div className="check-inputs flex">
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        onChange={handleTermsCheckboxChange}
                                        checked={isTermsChecked}
                                    />
                                    <label
                                        htmlFor="terms"
                                        className="term-box"
                                    >
                                        <span>
                                            이용약관
                                            <em>&nbsp;&#40;필수&#41;</em>
                                        </span>
                                    </label>
                                    <span
                                        className="term-detail"
                                        onClick={handleDetailClick}
                                    >
                                        자세히보기
                                    </span>
                                </div>
                                {showTerm && (
                                    <p className="term-total-box">
                                        {privacyContent}
                                    </p>
                                )}
                                <div className="check-inputs flex">
                                    <input
                                        type="checkbox"
                                        id="privacy"
                                        onChange={
                                            handlePrivacyPolicyCheckboxChange
                                        }
                                        checked={isPrivacyPolicyChecked}
                                    />
                                    <label
                                        htmlFor="privacy"
                                        className="term-box"
                                    >
                                        <span>
                                            개인정보처리방침
                                            <em>&nbsp;&#40;필수&#41;</em>
                                        </span>
                                    </label>
                                    <span
                                        className="term-detail"
                                        onClick={handlePrivacyPolicyClick}
                                    >
                                        자세히보기
                                    </span>
                                </div>
                                {showPrivacyPolicy && (
                                    <p className="term-total-box">
                                        {termContents}
                                    </p>
                                )}
                            </div>
                            <div className="form-btns">
                                <button className="join-btn">가입</button>
                                <button className="cancle-btn">취소</button>
                            </div>
                        </form>
                    </div>
                </section>
                <Footer />
            </div>
            <LandScapeModal />
        </>
    );
};

export default Membership;
