import React from 'react';
import '../styles/UsageModal.scss';

const UsageModal = ({ onClose }) => {
    return (
        <section className="usage-modal">
            <div className="inner">
                <h3>AI매직포유 콘텐츠 별 마법 콩 사용량 정책</h3>
                <figure>
                    <img
                        src={'/images/bean-usage.svg'}
                        alt=""
                    />
                </figure>
                <figure
                    className="close-modal-btn"
                    onClick={onClose}
                >
                    <img
                        src={'/images/close.png'}
                        alt=""
                    />
                </figure>
            </div>
        </section>
    );
};

export default UsageModal;
