import React, { useEffect, useRef, useState } from 'react';
import '../styles/TrWritingResultComponent.scss';
import RenderTextWithBreaks from './RenderTextWithBreaks';
import OptionChangeModal from './OptionChangeModal';

const TrWritingResultComponent = ({
    onRegenerate,
    onNext,
    onPrevious,
    selectedKRList,
    resultContent,
    resultContentList,
    onWordClick,
    selected_magickid,
}) => {
    const [selectedButton, setSelectedButton] = useState({});
    const randomReply = ['/images/bubble-niki.svg', '/images/bubble-arial.svg'];
    const randomReplyImage = Math.floor(Math.random() * randomReply.length);
    const _selectedKRList = selectedKRList.slice(1);
    const selectedKRPrompt = _selectedKRList.join(', ');
    const [showOptionChangeModal, setShowOptionChangeModal] = useState(false);
    const aiAnswerRef = useRef(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);

    console.log('selectedKRList: ', selectedKRList);
    console.log('resultContent: ', resultContent);
    console.log('resultContentList: ', resultContentList);
    console.log('selected_magickid', selected_magickid);

    const handleButtonCliick = (selectionKr, selectionEn) => {
        let selection = {
            kr: selectionKr,
            en: selectionEn,
        };
        setSelectedButton(selection);
    };

    useEffect(() => {
        if (aiAnswerRef.current) {
            aiAnswerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [resultContent]);

    // useEffect(() => {
    //     if (aiAnswerRef.current) {
    //         console.log('aiAnswerRef current:', aiAnswerRef.current);
    //         setTimeout(() => {
    //             aiAnswerRef.current.scrollIntoView({ behavior: 'smooth' });
    //         }, 100);
    //     }
    // }, [resultContent]);

    const handleOptionChangeConfirm = () => {
        setShowOptionChangeModal(false);
        onWordClick(selectedOptionIndex - 1);
    };
    const handleOptionChangeCancel = () => {
        setShowOptionChangeModal(false);
    };
    const handleWordClickWithConfirmation = (index) => {
        setSelectedOptionIndex(index + 1);
        setShowOptionChangeModal(true);
    };

    return (
        <div className="tr-bg writing-complete">
            <div className="inner">
                <div className="tr-box writing-complete">
                    <div className="complete-txt-container">
                        <p className="guide-txt">글이 완성되었어요!</p>
                        <div className="divider-line"></div>
                    </div>
                    <div className="tes">
                        <div className="result-char-bubble">
                            <figure>
                                <img
                                    src={
                                        selected_magickid === 'arial'
                                            ? '/images/bubble-arial.svg'
                                            : '/images/bubble-niki.svg'
                                    }
                                    alt="selected-magickid"
                                />
                            </figure>
                            {/* <div className="complete-bubble">
                                네가 고른 <em>{selectedKRPrompt}</em> 을
                                바탕으로 AI가 쓴 글이야.
                            </div> */}
                            <div className="complete-bubble">
                                네가 고른
                                <div className="keyword-container">
                                    {_selectedKRList.map((word, index) => (
                                        <span
                                            key={index}
                                            className="keyword"
                                            onClick={() =>
                                                handleWordClickWithConfirmation(
                                                    index
                                                )
                                            }
                                        >
                                            {word}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            className="ai-answer"
                            ref={aiAnswerRef}
                        >
                            <div className="user-reply reply">
                                <RenderTextWithBreaks text={resultContent} />
                            </div>
                            <figure>
                                <img
                                    src={'/images/writing-ai.svg'}
                                    alt="writing-ai"
                                />
                            </figure>
                        </div>
                        <button
                            className="regenerate-btn"
                            onClick={onRegenerate}
                        >
                            <figure>
                                <img
                                    src={'/images/remake.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>다시 만들기</span>
                        </button>
                    </div>
                </div>
                <div className="step-btns">
                    <div
                        className="button button-prev"
                        onClick={onPrevious}
                    >
                        ◀ 이전
                    </div>
                    <div
                        className="button button-next"
                        onClick={() => onNext(selectedButton)}
                    >
                        다음 ▶
                    </div>
                </div>
            </div>
            <OptionChangeModal
                show={showOptionChangeModal}
                onConfirm={handleOptionChangeConfirm}
                onCancel={handleOptionChangeCancel}
                optionText={selectedKRList[selectedOptionIndex]}
                selected_magickid={selected_magickid}
            />
        </div>
    );
};

export default TrWritingResultComponent;
