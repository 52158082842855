import React, { useEffect, useState } from 'react';
import '../styles/TeacherMain.scss';
import Navigation from '../components/Navigation';
import TeacherTab from '../components/TeacherTab';
import loginSessionCheck from '../components/LoginSessionCheck';
import LandScapeModal from '../components/LandScapeModal';
import {
    getDashboardStatus,
    getStudentHistory,
    getMagicbeanHistory,
} from '../api/TeacherAPI';
import ErrorModal from '../components/ErrorModal';
import { ERROR } from '../utils/Constant';
import NewHeader from '../components/NewHeader';

const TeacherMain = () => {
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const [status, setStatus] = useState({
        student_total_creation: 0,
        teacher_total_magicbean: 0,
    });
    const [studentHistory, setStudentHistory] = useState();
    const [magicbeanHistory, setMagicbeanHistory] = useState();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        callDashboardStatusAPI();
        callSudentHistoryAPI();
        callMagicBeanHistoryAPI();
    }, []);

    const callDashboardStatusAPI = async () => {
        console.log('USER_ID: ', USER_ID);
        const response = await getDashboardStatus(USER_ID);

        if (response.success) {
            console.log('DashboardStatus: ', response.data);
            if (response.data) setStatus(response.data);
            else {
                console.log(response.error);
                setErrorMessage(ERROR.busy);
            }
        } else {
            console.log(response.error.errMsg);
        }
    };

    const callSudentHistoryAPI = async () => {
        console.log('USER_ID: ', USER_ID);
        const response = await getStudentHistory(USER_ID);

        if (response.success) {
            console.log('StudentHistory: ', response.data);
            if (response.data) setStudentHistory(response.data);
            else {
                console.log(response.error);
                setErrorMessage(ERROR.busy);
            }
        } else {
            console.log(response.error.errMsg);
        }
    };

    const callMagicBeanHistoryAPI = async () => {
        console.log('USER_ID: ', USER_ID);
        const response = await getMagicbeanHistory(USER_ID);

        if (response.success) {
            console.log('MagicBeanHistory: ', response.data);
            if (response.data) setMagicbeanHistory(response.data);
            else {
                console.log(response.error);
                setErrorMessage(ERROR.busy);
            }
        } else {
            console.log(response.error.errMsg);
        }
    };

    return (
        <>
            <section className="contents">
                {/* <div className="left-contents">
                    <Navigation />
                </div> */}
                {showErrorModal && (
                    <ErrorModal
                        error_message={errorMessage}
                        onClose={() => setShowErrorModal(false)}
                    ></ErrorModal>
                )}
                <div className="right-contents teacher-main">
                    <NewHeader />
                    <div className="inner">
                        <ul className="situation-list">
                            <li>
                                우리반 학생들 작품 수{' '}
                                <em>{status.student_total_creation ?? '0'}</em>
                                개
                            </li>
                            <li>
                                선생님 잔여 콩{' '}
                                <em>{status.teacher_total_magicbean ?? '0'}</em>
                                개
                            </li>
                        </ul>
                        {/* 2024.01.04 | teacherMockData props 넘겨주기 */}
                        {studentHistory && magicbeanHistory && (
                            <TeacherTab
                                teacherData={studentHistory.teacher_info}
                                historyList={studentHistory.history}
                                allSchoolMagicbeanList={magicbeanHistory}
                            />
                        )}
                    </div>
                </div>
            </section>
            <LandScapeModal />
        </>
    );
};

export default loginSessionCheck(TeacherMain);
