import { TEACHER_ENDPOINT } from './utils/EndPoint';
import { performRequest } from './utils/RequestUtils';

export const getDashboardStatus = async (user_id) => {
    return performRequest('get', `${TEACHER_ENDPOINT}/status/${user_id}`);
};

export const getStudentHistory = async (user_id) => {
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/status/history/activity/${user_id}`
    );
};

export const getMagicbeanHistory = async (user_id) => {
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/status/history/magicbean/${user_id}`
    );
};

export const getPDFDownloadURL = async () => {
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/pdf`
    );
};

export const getTeachingPlanList = async (user_id) => {
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/plan/${user_id}`
    );
};


export const getTeachingPlanInfo = async (user_id, plan_id) => {
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/plan/${user_id}/${plan_id}`
    );
};


export const download30PlanHWP = async (user_id) => {
    const options = {
        responseType: 'blob' // responseType 설정
    };
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/download/plan/${user_id}`, null, options
    );
};

export const downloadMainWorksheet = async (user_id, worksheet_name) => {
    const options = {
        responseType: 'blob' // responseType 설정
    };
    return performRequest(
        'get',
        `${TEACHER_ENDPOINT}/download/worksheet/main/${user_id}/${worksheet_name}`, null, options
    );
};

export const downloadAllWorksheet = async (user_id, worksheet_urls) => {
    const postData = {
        user_id: user_id,
        worksheet_urls: worksheet_urls,
    };
    const options = {
        responseType: 'blob' // responseType 설정
    };
    return performRequest(
        'post',
        `${TEACHER_ENDPOINT}/download/worksheet/all`, postData, options
    );
};





