import React, { useState } from 'react';
import '../styles/TrStartComponent.scss';
import TrAlarmComponent from './TrAlarmComponent';

const TrStartComponent = ({ onNext, training_topic_title }) => {
    const [selectedButton, setSelectedItems] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isStartBtnActive, setIsStartBtnActive] = useState(false);

    const handleButtonClick = (buttonName) => {
        setSelectedItems(buttonName);
        setIsStartBtnActive(true);
    };

    const handleStartClick = () => {
        if (selectedButton) {
            onNext(selectedButton); // 부모 컴포넌트에 선택된 캐릭터 전달
        } else {
            setShowAlert(true);
        }
    };

    return (
        <>
            {showAlert && (
                <TrAlarmComponent
                    onClose={() => setShowAlert(false)}
                    title={'함께할 캐릭터'}
                />
            )}
            <div className="tr-bg start">
                <div className="inner">
                    <div className="tr-box">
                        <p className="guide-txt">
                            <em className="training-topic-title">
                                {training_topic_title}
                            </em>
                            을/를 함께할 <br />
                            캐릭터를 선택해주세요!
                        </p>
                        <div className="char-btns">
                            <div
                                className={`btn arial ${
                                    selectedButton === 'arial' ? 'border' : ''
                                }`}
                                onClick={() => handleButtonClick('arial')}
                            >
                                <img
                                    src={'/images/arial-btn.svg'}
                                    alt=""
                                />
                            </div>
                            <div
                                className={`btn nikki ${
                                    selectedButton === 'nikki' ? 'border' : ''
                                }`}
                                onClick={() => handleButtonClick('nikki')}
                            >
                                <img
                                    src={'/images/nikki-btn.svg'}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`start-btn ${
                            isStartBtnActive ? 'active' : ''
                        }`}
                        onClick={handleStartClick}
                    >
                        시작하기 ‣
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrStartComponent;
