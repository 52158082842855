import React from 'react';
import '../styles/TcDownLoadBtnComponent.scss';
import { useNavigate } from 'react-router-dom';
import { download30PlanHWP } from '../api/TeacherAPI';

const TcDownLoadBtnComponent = () => {
    const USER_ID = localStorage.getItem('userID');
    const navigate = useNavigate();

    const handleWorkSheetClick = () => {
        navigate('/worksheet');
    };

    // const handleDownload30Plan = async () => {
    //     try {
    //         const result = await download30PlanHWP(USER_ID);
    //         if (result.success) {
    //             console.log(result.data);
    //             const blob = new Blob([result.data], {
    //                 type: 'application/octet-stream',
    //             });
    //             const url = window.URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', `AI매직포유_지도계획_30차시.hwp`);
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //         } else {
    //             console.error(result);
    //         }
    //     } catch (error) {
    //         console.error('Error downloading files:', error);
    //     }
    // };

    const handleDownload24Plan = async () => {
        try {
            const result = await download30PlanHWP(USER_ID);
            if (result.success) {
                console.log(result.data);
                const blob = new Blob([result.data], {
                    type: 'application/octet-stream',
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `AI매직포유_지도계획_24차시.hwp`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    return (
        <div className="download-btn-cloumn-container">
            <div
                className="download-btn teaching-plan"
                onClick={handleDownload24Plan}
            >
                <figure className="picture-figure">
                    <img
                        src={'/images/teaching_plan_btn.svg'}
                        alt="teaching_plan_btn"
                    />
                </figure>
                <figure className="icon-figure">
                    <img
                        src={'/images/download-cloud.svg'}
                        alt="teaching_plan_btn"
                    />
                </figure>
                <div className="download-txt">
                    {/* <figure>
                        <img
                            src={'/images/download-white.svg'}
                            alt=""
                        />
                    </figure> */}
                    <span>지도계획 다운로드</span>
                </div>
            </div>
            <div
                className="download-btn worksheet"
                onClick={handleWorkSheetClick}
            >
                <figure className="picture-figure">
                    <img
                        src={'/images/worksheet_btn.svg'}
                        alt="worksheet_btn"
                    />
                </figure>
                <figure className="icon-figure">
                    <img
                        src={'/images/book-open.svg'}
                        alt="teaching_plan_btn"
                    />
                </figure>
                <div className="download-txt">
                    {/* <figure>
                        <img
                            src={'/images/download-black.svg'}
                            alt=""
                        />
                    </figure> */}
                    <span>교안 보러가기</span>
                </div>
            </div>
        </div>
    );
};

export default TcDownLoadBtnComponent;
