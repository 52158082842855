import React from 'react';
import '../styles/TcLoadingSpinnerComponent.scss';

const TcLoadingSpinnerComponent = () => {
    return (
        <div className="loading-spinner">
            <div className="spinner"></div>
            <p>리스트를 불러오는 중이에요 ☺︎</p>
        </div>
    );
};

export default TcLoadingSpinnerComponent;
