import React, { useEffect, useState } from 'react';
import '../styles/WorkTabs.scss';
import WideViewModal from '../components/WideViewModal';
import WritingDetailModal from './WritingDetailModal';
import DrawingList from './DrawingList';
import WritingList from './WritingList';
import {
    getUserCreationList,
    getSchoolCreationList,
    downloadCreation,
    deleteCreation,
} from '../api/WorkAPI';
import ContestConts from './ContestConts';
import StorybookList from './StorybookList';
import RemovalCompleteModal from './RemovalCompleteModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const WorkTabs = ({ page_type }) => {
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const IS_TEACHER = localStorage.getItem('isTeacher') === 'true';
    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;

    const [activeTab, setActiveTab] = useState('drawing'); // tab active
    const [modalOpen, setModalOpen] = useState(false); // 모달 상태
    const [selectedImage, setSelectedImage] = useState(null); // 선택된 이미지
    const [selectedWriting, setSelectedWriting] = useState(null); // 선택된 글
    const [randomImage, setRandomImage] = useState(''); // 랜덤 이미지
    const [writingModalOpen, setWritingModalOpen] = useState(false); // 글 상세 모달 상태

    const [creationList, setCreationList] = useState([]); // tab active
    const [selectedOption, setSelectedOption] = useState('최신순');
    const [selectedItems, setSelectedItems] = useState([]); // NOTE 0731 : 선택한 아이템들
    const [showCheckboxes, setShowCheckboxes] = useState(false); // NOTE 0705 : 체크박스 표시 여부
    const [removalModalOpen, setRemovalModalOpen] = useState(false); // NOTE 0708 : 삭제완료 모달 상태
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // NOTE 0717 : 검색어 상태
    const [searchResults, setSearchResults] = useState([]); // NOTE 0717 : 검색 결과 상태

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // NOTE : 항목 선택하는 함수
    const handleSelectItem = (itemId, isSelected) => {
        if (isSelected) {
            setSelectedItems([...selectedItems, itemId]);
        } else {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        }
    };

    const handleDeleteSelectedItems = async () => {
        const result = await deleteCreation(USER_ID, selectedItems);
        if (result.success) {
            setCreationList(
                creationList.filter((item) => !selectedItems.includes(item.id))
            );
            setSelectedItems([]);
            setShowCheckboxes(false);
            await callCreationListAPI();
            setRemovalModalOpen(true); // 삭제완료 모달 표시
            setShowDeleteConfirm(false); // 삭제 완료 후 확인 모달창 닫기
        } else {
            console.error(result.data);
        }
    };

    // NOTE 0708 : 삭제완료 모달 닫기
    const closeRemovalModal = () => {
        setRemovalModalOpen(false);
    };

    // NOTE 0705 : 선택 모드와 취소 모드 토글
    const handleSelectMode = () => {
        setShowCheckboxes(!showCheckboxes);
        setSelectedItems([]);
    };

    const handleSearch = () => {
        console.log('Search Query:', searchQuery); // 검색어 로그 출력
        console.log('Creation List:', creationList); // creationList 로그 출력

        const query = searchQuery.trim().toLowerCase();
        const results = creationList.filter(
            (item) =>
                (item.creation_title &&
                    item.creation_title.toLowerCase().includes(query)) ||
                (item.name && item.name.toLowerCase().includes(query))
        );
        console.log('Filtered Results:', results); // 검색 결과 콘솔 출력
        setSearchResults(results);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const openWritingModal = () => {
        setWritingModalOpen(true);
    };

    const gotoStory4U = (book_id) => {
        //NOTE: Story4U 읽기모드
        const params = {
            userID: USER_ID,
            userName: USER_NAME,
            bookID: book_id,
        };
        const query = new URLSearchParams(params).toString();
        window.open(
            `${story4uURL}:${story4uPort}/reading-aimagickid?${query}`,
            '_blank',
            'noopener,noreferrer'
        );
    };

    // writing-paper 랜덤 이미지 리스트
    const imageList = [
        '/images/poem-paper-blue.svg',
        '/images/poem-paper-red.svg',
        '/images/poem-paper-green.svg',
        '/images/poem-paper-yellow.svg',
    ];

    // 랜덤 이미지 생성
    const selectRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * imageList.length);
        setRandomImage(imageList[randomIndex]);
    };

    const callCreationListAPI = async () => {
        let result;
        if (page_type === 'my') {
            result = await getUserCreationList(USER_ID, activeTab);
        } else if (page_type === 'school') {
            result = await getSchoolCreationList(USER_ID, activeTab);
        }

        if (result.success) {
            console.log(result.data);
            setCreationList(result.data);
        } else {
            console.error(result.data);
        }
    };

    // 컴포넌트 마운트 시 랜덤 이미지 선택
    useEffect(() => {
        if (activeTab === 'storybook') {
            // alert("곧 오픈될 예정입니다.")
            // const params = { userID: USER_ID, userName: USER_NAME }
            // const query = new URLSearchParams(params).toString();
            // window.open(`${story4uURL}:${story4uPort}/myStorybook?${query}`, '_blank', 'noopener,noreferrer');
        }
        selectRandomImage();
        callCreationListAPI();
        setSelectedOption('최신순');
    }, [activeTab]);

    useEffect(() => {
        // 새로운 정렬된 리스트를 계산
        const sortedList = [...creationList].sort((a, b) => {
            return selectedOption === '최신순'
                ? b.creation_id - a.creation_id
                : a.creation_id - b.creation_id;
        });

        // 정렬된 리스트로 상태 업데이트
        setCreationList(sortedList);
    }, [selectedOption]);

    // 선택된 탭 active
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // 모달 열기
    const openModal = (result) => {
        if (activeTab === 'writing') {
            setSelectedWriting(result);
            setWritingModalOpen(true);
        } else if (activeTab === 'drawing') {
            setSelectedImage(result);
            setModalOpen(true);
        }
    };

    const openDeleteConfirmModal = () => {
        setShowDeleteConfirm(true);
    };

    const closeDeleteConfirmModal = () => {
        setShowDeleteConfirm(false);
    };

    const handleDownloadSelectedItems = async () => {
        const selectedUrls = selectedItems
            .map((itemId) => {
                const item = creationList.find(
                    (item) => String(item.creation_id) === String(itemId)
                );
                return item ? item.creation : null;
            })
            .filter((url) => url !== null)
            .join(',');

        try {
            const result = await downloadCreation(USER_ID, selectedUrls);
            if (result.success) {
                console.log(result.data)
                const blob = new Blob([result.data], {
                    type: 'application/zip',
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `user_${USER_ID}_selected_drawings.zip`
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    return (
        <>
            <div className="toggle-btn-list row">
                <button
                    onClick={() => {
                        handleTabClick('drawing');
                    }}
                    className={`btn toggle-btn ${activeTab === 'drawing' ? 'active' : ''
                        }`}
                >
                    <figure>
                        <img
                            src={'/images/paint.svg'}
                            alt=""
                        />
                    </figure>
                    그림
                </button>
                <button
                    onClick={() => {
                        handleTabClick('writing');
                    }}
                    className={`btn toggle-btn ${activeTab === 'writing' ? 'active' : ''
                        }`}
                >
                    <figure>
                        <img
                            src={'/images/pencil-con.svg'}
                            alt=""
                        />
                    </figure>
                    글
                </button>
                <button
                    onClick={() => {
                        handleTabClick('storybook');
                    }}
                    className={`btn toggle-btn ${activeTab === 'storybook' ? 'active' : ''
                        }`}
                >
                    <figure>
                        <img
                            src={'/images/book.svg'}
                            alt=""
                        />
                    </figure>
                    창작동화
                </button>
            </div>
            {IS_TEACHER && activeTab !== 'storybook' && (
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="검색어를 입력하세요"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={handleKeyDown} // 엔터 키 이벤트 핸들러 추가
                    />
                    <button onClick={handleSearch}>검색</button>
                </div>
            )}
            <select
                className="selectbox"
                value={selectedOption}
                onChange={handleSelectChange}
            >
                <option value="최신순">최신순</option>
                <option value="오래된 순">오래된 순</option>
            </select>
            {/* NOTE: 삭제하기 버튼 css 및 추가기능 개발 후 활성화 필요 */}
            {page_type !== 'school' && activeTab !== 'storybook' && (
                <div className="btn-container">
                    <button
                        className="btn-cancel"
                        onClick={handleSelectMode}
                    >
                        {showCheckboxes && (
                            <figure>
                                <img
                                    src={'/images/icon-cancel.svg'}
                                    alt=""
                                />
                            </figure>
                        )}
                        {showCheckboxes ? '취소하기' : '선택하기'}
                    </button>
                    {showCheckboxes && (
                        <button
                            // onClick={handleDeleteSelectedItems}
                            onClick={openDeleteConfirmModal}
                            disabled={selectedItems.length === 0}
                            className={`btn-delete ${selectedItems.length === 0
                                ? 'disabled'
                                : 'enabled'
                                }`}
                        >
                            <figure>
                                <img
                                    src={'/images/icon-delete.svg'}
                                    alt=""
                                />
                            </figure>
                            삭제하기
                        </button>
                    )}
                    {showCheckboxes && IS_TEACHER && (
                        <button
                            onClick={handleDownloadSelectedItems}
                            disabled={selectedItems.length === 0}
                            className={`btn-download ${selectedItems.length === 0
                                ? 'disabled'
                                : 'enabled'
                                }`}
                        >
                            <figure>
                                <img
                                    src={'/images/download-icon-small.svg'}
                                    alt=""
                                />
                            </figure>
                            한꺼번에 내려받기
                        </button>
                    )}
                </div>
            )}

            {showCheckboxes && (
                <div
                    className={`slide-up-menu ${showCheckboxes ? 'show' : ''}`}
                >
                    <button
                        className="btn-cancel"
                        onClick={handleSelectMode}
                    >
                        <figure>
                            <img
                                src={'/images/icon-cancel.svg'}
                                alt=""
                            />
                        </figure>
                        <span>취소하기</span>
                    </button>
                    <button
                        // onClick={handleDeleteSelectedItems}
                        onClick={openDeleteConfirmModal}
                        disabled={selectedItems.length === 0}
                        className={`btn-delete ${selectedItems.length === 0 ? 'disabled' : 'enabled'
                            }`}
                    >
                        <figure>
                            <img
                                src={'/images/icon-delete.svg'}
                                alt=""
                            />
                        </figure>
                        <span>삭제하기</span>
                    </button>
                    {IS_TEACHER && (
                        <button
                            onClick={handleDownloadSelectedItems}
                            disabled={selectedItems.length === 0}
                            className={`btn-download ${selectedItems.length === 0
                                ? 'disabled'
                                : 'enabled'
                                }`}
                        >
                            <figure>
                                <img
                                    src={'/images/download-icon-small.svg'}
                                    alt=""
                                />
                            </figure>
                            한꺼번에 내려받기
                        </button>
                    )}
                </div>
            )}
            <div className="tab-contents">
                {activeTab === 'drawing' &&
                    ((page_type === 'my' && (
                        <DrawingList
                            creationList={
                                searchQuery ? searchResults : creationList
                            }
                            openModal={openModal}
                            onSelectItem={handleSelectItem}
                            showCheckboxes={showCheckboxes}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                        />
                    )) ||
                        (page_type === 'school' && (
                            <ContestConts
                                creationList={
                                    searchQuery ? searchResults : creationList
                                }
                                openModal={openModal}
                            />
                        )))}
                {/* {activeTab === 'writing' && (
                    <WritingList
                        creationList={
                            searchQuery ? searchResults : creationList
                        }
                        randomImage={randomImage}
                        openModal={openModal}
                        page_type={page_type}
                        showCheckboxes={showCheckboxes}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        onSelectItem={handleSelectItem}
                    />
                )} */}
                {activeTab === 'writing' && (
                    <WritingList
                        creationList={
                            searchQuery ? searchResults : creationList
                        }
                        randomImage={randomImage}
                        openModal={openModal}
                        page_type={page_type}
                        showCheckboxes={showCheckboxes}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        onSelectItem={handleSelectItem}
                    />
                )}
                {activeTab === 'storybook' && (
                    <StorybookList
                        storybookList={creationList}
                        onReading={(book_id) => gotoStory4U(book_id)}
                    />
                )}
            </div>
            {modalOpen && (
                <WideViewModal
                    image={selectedImage}
                    onClose={() => setModalOpen(false)}
                    checkPrint={IS_TEACHER}
                />
            )}
            {writingModalOpen && (
                <WritingDetailModal
                    text={selectedWriting}
                    onClose={() => setWritingModalOpen(false)}
                    checkPrint={IS_TEACHER}
                />
            )}
            {removalModalOpen && (
                <RemovalCompleteModal onClose={closeRemovalModal} />
            )}
            {showDeleteConfirm && (
                <ConfirmDeleteModal
                    onConfirm={handleDeleteSelectedItems}
                    onCancel={closeDeleteConfirmModal}
                />
            )}
        </>
    );
};

export default WorkTabs;
