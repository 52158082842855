import React, { useEffect, useState } from 'react';
import '../styles/Information.scss';
import Header from '../components/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import InqueryButton from '../components/InqueryButton';

const Information = () => {
    const navigate = useNavigate();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

    const faqData = [
        {
            question:
                '선택형이 아닌, 내가 원하는 입력으로 생성형 AI를 사용할 수 없나요?',
            answer: '네 그렇습니다. 현재는 안전한 콘텐츠 생성을 위하여 직접 입력하는 기능은 제공하지 않습니다.',
        },
        {
            question: '내가 만든 작품을 인쇄할 수 있나요?',
            answer: '네. 인쇄기능은 선생님모드에서만 제공합니다. 선생님으로 로그인하셔서 인쇄해보세요.',
        },
    ];

    const handleManualClick = (manualType) => {
        navigate(`/test?manual=${manualType}`);
    };

    const toggleAccordion = (index) => {
        const new_index = openAccordionIndex === index ? null : index;
        setOpenAccordionIndex(new_index);
    };

    useEffect(() => {
        AOS.init();
    });

    useEffect(() => {
        //NOTE: 스크롤 초기화
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id="wrap">
            <Header />
            <section className="solution-section-1">
                <div
                    className="inner flex"
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <h2 className="tit">
                            즐거운 AI와의 첫 만남,
                            <br />
                            AI 매직포유
                        </h2>
                        <p>다양한 콘텐츠와 함께 생성형 AI를 즐겨보아요!</p>
                        <button
                            className="requst-btn"
                            onClick={() => {
                                navigate('/contact');
                            }}
                        >
                            도입문의
                        </button>
                    </div>
                    <div className="right">
                        <figure>
                            <img
                                src={'/images/solution/solution_1.svg'}
                                alt="section-1-image"
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="solution-section-2">
                <figure className="circle-empty">
                    <img
                        src={'/images/circle-empty-2.svg'}
                        alt="circle"
                    />
                </figure>
                <figure className="circle">
                    <img
                        src={'/images/circle-2.svg'}
                        alt="circle"
                    />
                </figure>
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <h2>디지털 기초소양</h2>
                        <ul>
                            <li>
                                플랫폼을 이용한 체험 교육으로
                                <br className="pc-only" /> 디지털 환경에 적응
                            </li>
                            <li>
                                미래 세대가 갖춰야 할 핵심역량
                                <br className="pc-only" />{' '}
                                <em>디지털 문해력과 AI 문해력 학습</em>
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <figure>
                            <img
                                src={'/images/solution/solution_2.svg'}
                                alt="section-2-image"
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="solution-section-3">
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <figure>
                            <img
                                src={'/images/solution/solution_3_1.svg'}
                                alt="section-3-image"
                            />
                        </figure>
                    </div>
                    <div className="right">
                        <h2>안전한 교육적 컨텐츠</h2>
                        <ul>
                            <li>
                                프롬프트를 직접 입력하지 않고 선택하는 방식으로
                                원천적으로 유해 정보 생성 방지
                            </li>
                            <li>안전한 교육 컨텐츠 제공</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="solution-section-4">
                <figure className="circle-big">
                    <img
                        src={'/images/circle-3-big.svg'}
                        alt=""
                    />
                </figure>
                <figure className="circle-small">
                    <img
                        src={'/images/circle-3-small.svg'}
                        alt=""
                    />
                </figure>
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <h2>능동적인 학습 활동</h2>
                        <ul>
                            <li>
                                생성형 AI 체험 활동을 통해 자신의 선택이
                                결과물에 미치는 영향을 직접 경험
                            </li>
                            <li>흥미 유발 및 지속적인 학습동기 부여</li>
                        </ul>
                    </div>
                    <div className="right">
                        <figure>
                            <img
                                src={'/images/solution/solution_4.svg'}
                                alt="section-4-image"
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="solution-section-5">
                <div className="inner">
                    <h2 className="tit">FAQ</h2>
                    <ul className="accordian-list">
                        {faqData.map((item, index) => (
                            <li
                                className="accordion-item"
                                key={index}
                            >
                                <h2 className="accordion-header">
                                    <button
                                        type="button"
                                        className={`accordion-button${
                                            openAccordionIndex === index
                                                ? ' show'
                                                : ''
                                        }`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        {item.question}
                                    </button>
                                </h2>
                                <div
                                    className={`accordion-collapse${
                                        openAccordionIndex === index
                                            ? ' show'
                                            : ''
                                    }`}
                                >
                                    <div className="accordion-body">
                                        {item.answer}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
            <section className="go-guide-section white">
                <div className="inner">
                    <h3>더 많은 활용 방법을 알고싶다면?</h3>
                    <figure className="arrow">
                        <img
                            src={'/images/down-arrow.svg'}
                            alt=""
                        />
                    </figure>
                    <div className="guide-container">
                        <div
                            className="guide-btn"
                            onClick={() => handleManualClick('teacher')}
                        >
                            선생님 메뉴얼
                        </div>
                        <div
                            className="guide-btn"
                            onClick={() => handleManualClick('drawing')}
                        >
                            그림 그리기 AI 메뉴얼
                        </div>
                        <div
                            className="guide-btn"
                            onClick={() => handleManualClick('writing')}
                        >
                            글 만들기 AI 메뉴얼
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <InqueryButton />
        </div>
    );
};

export default Information;
