import React, { useEffect, useState } from 'react';
import '../../styles/terms/Terms.scss';

const PrivacyPolicy = ({ onClose }) => {
    const [privacyContent, setPrivacyContent] = useState([]);

    useEffect(() => {
        const fetchPrivacyPolicy = async () => {
            try {
                const response = await fetch('/utils/PrivacyPolicy.txt');
                const text = await response.text();
                const parsedContent = parseTextToHtml(text);
                setPrivacyContent(parsedContent);
            } catch (error) {
                console.error('Failed to fetch the privacy policy file', error);
            }
        };

        fetchPrivacyPolicy();
    }, []);

    const parseTextToHtml = (text) => {
        const lines = text.split('\n');
        const result = [];

        lines.forEach((line, index) => {
            if (index === 0) {
                // 첫 번째 줄은 <h1>로 감싸기
                result.push(
                    <h1
                        className="first-title"
                        key={index}
                    >
                        {line}
                    </h1>
                );
            } else if (line.startsWith('1.') || line.startsWith('2.')) {
                // 섹션 제목은 <h2>로 감싸기
                result.push(
                    <h2
                        className="section-title"
                        key={index}
                    >
                        {line}
                    </h2>
                );
            } else if (line.match(/^\d\)/)) {
                // 번호가 붙은 리스트 항목은 <li>로 감싸기
                result.push(<li key={index}>{line}</li>);
            } else if (line.trim() === '') {
                // 빈 줄은 무시
            } else {
                // 나머지는 <p>로 감싸기
                result.push(<p key={index}>{line}</p>);
            }
        });

        return result;
    };

    return (
        <div className="terms-section">
            <div className="terms-header">
                <h1>개인정보처리방침</h1>
                <button
                    className="close-btn"
                    onClick={onClose}
                >
                    X
                </button>
            </div>
            <div className="inner">
                <div className="contents-all">{privacyContent}</div>
            </div>
            <div className="close-cover">
                <button onClick={onClose}>닫기</button>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
