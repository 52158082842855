import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/DrawingTraining.scss';

import ErrorModal from '../components/ErrorModal';
import loginSessionCheck from '../components/LoginSessionCheck';

import { getTrainingInfo, callChatGPT, callFalAI } from '../api/ClassAPI';
import { saveCreation } from '../api/WorkAPI';
import { ERROR } from '../utils/Constant';
import TrStartComponent from '../components/TrStartComponent';
import TrGuideComponent from '../components/TrGuideComponent';
// import TrSelectionComponent from '../components/TrSelectionComponent';
import TrAfterSelectionComponent from '../components/TrAfterSelectionComponent';
import TrFinishSelectionComponent from '../components/TrFinishSelectionComponent';
// merry : TrDrawingResult 이거 고치기
import TrWritingResultComponent from '../components/TrWritingResultComponent';
// merry : DrawingTalking으로 놔두는게 맞는지 WritingTalking으로 고치는게 맞는지 확인해보기!!!
import TrDiscussionTalkingComponent from '../components/TrDiscussionTalkingComponent';
// import CompleteModal from '../components/CompleteModal';
import Loading from '../components/LongLoading';
import ShortLoading from '../components/Loading';
import TrCompleteComponent from '../components/TrCompleteComponent';
import TrWritingSelectionComponent from '../components/TrWritingSelectionComponent';
import TrainingHeader from '../components/TrainingHeader';
import SchoolTitle from '../components/SchoolTitle';
// NOTE : 0716 Merry Test
import TrQuestionRelay from '../components/TrQuestionRelay';

const WritingTraining = () => {
    const navigation = useNavigate();

    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const USER_SCHOOLNAME = localStorage.getItem('userSchoolName');
    const USER_TYPE = localStorage.getItem('userType');
    const { trainingId, trainingType } = useParams(); //NOTE: trainingType 없애기

    const [step, setStep] = useState(0);
    const [finalStep, setFinalStep] = useState(0);
    const [selectionStep, setSelectionStep] = useState(0);
    const [isRevisiting, setIsRevisiting] = useState(false);
    const [revisitingIndex, setRevisitingIndex] = useState(null);

    const [selectedmagickid, setSelectedMagickidName] = useState('');
    const [trainingTopicTitle, setTrainingTopicTitle] = useState('');
    const [trainingDescription, setTrainingDescription] = useState('');
    const [discussionTopic, setDiscussionTopic] = useState('');
    const [discussionExample, setDiscussionExample] = useState('');
    const [trainingCategory, setTrainingCategory] = useState('');
    const [promptContent, setPromptContent] = useState('');
    const [selectionList, setSelectionList] = useState(null);
    const [selectedTitleKr, setSelectedTitleKr] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState([]);
    const [AIResult, setAIResult] = useState(null);
    const [AIResultList, setAIResultList] = useState([]);
    // merry : writing으로 고치기
    // const drawingAudioRef = useRef(null);
    const writingAudioRef = useRef(null);

    const [showLoading, setShowLoading] = useState(false);
    const [showSaveLoading, setShowSaveLoading] = useState(false);
    // const [showComplete, setShowComplete] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    // const [logoImage, setLogoImage] = useState();

    // merryTest
    const [selectedInfo, setSelectedInfo] = useState([]);

    console.log('selectedTitleKr', selectedTitleKr);

    useEffect(() => {
        callTrainingInfoAPIFunc(trainingType, trainingId);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const executeAsync = async () => {
            if (isRevisiting) {
                console.log('wowowowowo', isRevisiting);
                await executeAI();
                setStep(finalStep - 1);
                setIsRevisiting(false);
            }
        };

        executeAsync();
    }, [selectedTitleKr]);

    const handleRegenerate = () => {
        setStep(2);
        setSelectionStep(0);
        setSelectedTitleKr([]);
        setSelectedPrompt([]);
    };

    const handleRestart = () => {
        setStep(0);
        setSelectionStep(0);
        setSelectedTitleKr([]);
        setSelectedPrompt([]);
        setAIResult([]);
        setSelectedMagickidName('');
    };

    const handleGoHubWriting = () => {
        navigation('/whub/writing');
    };

    const callTrainingInfoAPIFunc = async (training_type, training_id) => {
        const response = await getTrainingInfo(training_type, training_id);

        if (response.success) {
            console.log('@@1', response.data);
            if (training_type == 'writing') {
                console.log('@@', response.data);
                setSelectionList(
                    response.data.training_prompt_json.selection_list
                );
                setPromptContent(response.data.training_prompt_json.content);
                setTrainingTopicTitle(response.data.training_topic_title);
                setTrainingDescription(response.data.training_description);
                setDiscussionTopic(response.data.discussion_topic);
                setDiscussionExample(response.data.discussion_example);
                setFinalStep(
                    response.data.training_prompt_json.selection_list.length *
                    2 +
                    4
                );
                setTrainingCategory(response.data.training_category);
                console.log(
                    'final step: ',
                    response.data.training_prompt_json.selection_list.length *
                    2 +
                    4
                );
            }
        } else {
            setShowErrorModal(true);
            console.log(response.error.errMsg);
        }
    };

    // 오른쪽 버튼 클릭 핸들러 원본
    // const handleNextButtonClick = async (selected_info) => {
    //     if (step === 0) {
    //         setSelectedMagickidName(selected_info);
    //     }
    //     if (
    //         step >= 2 &&
    //         step % 2 == 0 &&
    //         selectionStep < selectionList.length
    //     ) {
    //         setSelectionStep((prevIndex) => {
    //             return ++prevIndex;
    //         });
    //         setSelectedPrompt((prev) => {
    //             if (selectionStep === prev.length) {
    //                 return [...prev, selected_info.en];
    //             } else {
    //                 return [...prev];
    //             }
    //         });
    //         // 기존 로직
    //         setSelectedTitleKr((prev) => {
    //             if (selectionStep === prev.length) {
    //                 return [...prev, selected_info];
    //             } else {
    //                 return [...prev.slice(0, -1), selected_info];
    //             }
    //         });
    //     } else if (step === finalStep - 1) {
    //         await handleSaveButtonClick(AIResultList);
    //     }
    //     setStep((prevIndex) => {
    //         if (prevIndex === finalStep - 1) return finalStep;
    //         else return ++prevIndex;
    //     });
    // };

    // test2
    // const handleNextButtonClick = async (selected_info) => {
    //     if (isRevisiting) {
    //         const updatedPrompt = [...selectedPrompt];
    //         const updatedTitleKr = [...selectedTitleKr];

    //         if (revisitingIndex === 0) {
    //             // 첫 번째 항목을 다시 방문한 경우
    //             updatedPrompt[0] = selected_info.en;
    //             updatedTitleKr[0] = selected_info.kr;
    //         } else {
    //             // 첫 번째 항목이 아닌 경우
    //             updatedPrompt.shift(); // 0번째 인덱스 제거
    //             updatedTitleKr.shift(); // 0번째 인덱스 제거
    //             updatedPrompt[revisitingIndex - 1] = selected_info.en;
    //             updatedTitleKr[revisitingIndex - 1] = selected_info.kr;
    //         }

    //         setSelectedPrompt(updatedPrompt);
    //         setSelectedTitleKr(updatedTitleKr);
    //         console.log('Updated selectedPrompt:', updatedPrompt);
    //         console.log('Updated selectedTitleKr:', updatedTitleKr);

    //         setRevisitingIndex(null);
    //     } else {
    //         if (step === 0) {
    //             setSelectedMagickidName(selected_info);
    //         }
    //         if (
    //             step >= 2 &&
    //             step % 2 == 0 &&
    //             selectionStep < selectionList.length
    //         ) {
    //             setSelectionStep((prevIndex) => {
    //                 return ++prevIndex;
    //             });
    //             setSelectedPrompt((prev) => {
    //                 if (selectionStep === prev.length) {
    //                     return [...prev, selected_info.en];
    //                 } else {
    //                     return [...prev];
    //                 }
    //             });
    //             // 기존 로직
    //             setSelectedTitleKr((prev) => {
    //                 if (selectionStep === prev.length) {
    //                     return [...prev, selected_info];
    //                 } else {
    //                     return [...prev.slice(0, -1), selected_info];
    //                 }
    //             });
    //         } else if (step === finalStep - 1) {
    //             await handleSaveButtonClick(AIResultList);
    //         }
    //         setStep((prevIndex) => {
    //             if (prevIndex === finalStep - 1) return finalStep;
    //             else return ++prevIndex;
    //         });
    //     }
    // };

    const handleNextButtonClick = async (selected_info) => {
        if (isRevisiting) {
            const updatedTitleKr = [...selectedTitleKr];

            // 0번째 인덱스 제거
            updatedTitleKr.shift();

            console.log(
                'updatedPromptupdatedPromptupdatedPrompt',
                updatedTitleKr
            );

            // 선택지 배열에서 다시 방문한 인덱스의 값을 업데이트
            updatedTitleKr[revisitingIndex] = selected_info;

            // 상태 업데이트
            setSelectedTitleKr(updatedTitleKr);
            console.log('Updated selectedTitleKr:', updatedTitleKr);

            // 다시 방문 상태 초기화
            setRevisitingIndex(null);
        } else {
            if (step === 0) {
                setSelectedMagickidName(selected_info);
            }
            if (
                step >= 2 &&
                step % 2 === 0 &&
                selectionStep < selectionList.length
            ) {
                setSelectionStep((prevIndex) => ++prevIndex);
                setSelectedPrompt((prev) => {
                    if (selectionStep === prev.length) {
                        return [...prev, selected_info.en];
                    } else {
                        return [...prev];
                    }
                });
                setSelectedTitleKr((prev) => {
                    if (selectionStep === prev.length) {
                        return [...prev, selected_info];
                    } else {
                        return [...prev.slice(0, -1), selected_info];
                    }
                });
            } else if (step === finalStep - 1) {
                await handleSaveButtonClick(AIResultList);
            }
            setStep((prevIndex) => {
                if (prevIndex === finalStep - 1) return finalStep;
                else return ++prevIndex;
            });
        }
    };

    //         setSelectedMagickidName(selected_info);
    //     }
    //     if (
    //         step >= 2 &&
    //         step % 2 == 0 &&
    //         selectionStep < selectionList.length
    //     ) {
    //         setSelectionStep((prevIndex) => {
    //             return ++prevIndex;
    //         });
    //         setSelectedPrompt((prev) => {
    //             if (selectionStep === prev.length) {
    //                 return [...prev, selected_info.en];
    //             } else {
    //                 return [...prev];
    //             }
    //         });
    //         setSelectedTitleKr((prev) => {
    //             let updatedPrev = [...prev];
    //             if (isRevisiting) {
    //                 updatedPrev = updatedPrev.slice(1); // 0번째 인덱스 삭제
    //             }
    //             if (selectionStep === updatedPrev.length) {
    //                 return [...updatedPrev, selected_info];
    //             } else {
    //                 return [...updatedPrev.slice(0, -1), selected_info];
    //             }
    //         });

    //         setSelectedTitleKr((prev) => {
    //             let updatedPrev = [...prev];
    //             if (isRevisiting) {
    //                 updatedPrev = updatedPrev.slice(1); // 0번째 인덱스 삭제
    //             }
    //             console.log('Updated selectedTitleKr:', updatedPrev);
    //             if (selectionStep === updatedPrev.length) {
    //                 return [...updatedPrev, selected_info];
    //             } else {
    //                 return [...updatedPrev.slice(0, -1), selected_info];
    //             }
    //         });
    //     } else if (step === finalStep - 1) {
    //         await handleSaveButtonClick(AIResultList);
    //     }
    //     setStep((prevIndex) => {
    //         if (prevIndex === finalStep - 1) return finalStep;
    //         else return ++prevIndex;
    //     });
    // };

    // 왼쪽 버튼 클릭 핸들러
    const handlePreviousButtonClick = (selected_info) => {
        if (step > 2 && selectionStep <= selectionList.length) {
            setSelectionStep((prevIndex) => {
                if (selectionStep != 0) return --prevIndex;
                else return prevIndex;
            });
            setSelectedPrompt((prev) => {
                if (selectedPrompt.length > 0) return prev.slice(0, -1);
                else return [...prev];
            });
            setSelectedTitleKr((prev) => {
                if (selectedPrompt.length > 0) return prev.slice(0, -1);
                else return [...prev];
            });
        }
        setStep((prevIndex) => {
            if (prevIndex === 0) return 0;
            else if (
                step > 2 &&
                step % 2 == 0 &&
                selectionStep <= selectionList.length
            ) {
                let newIndex = prevIndex - 2;
                return newIndex;
            } else return --prevIndex;
        });
    };

    const handleEndQuesionRelay = async (question) => {
        // setAIResultList([question]);
        // handleSaveButtonClick([question]);
        setStep(finalStep + 1);
    };

    const executeAI = async () => {
        setShowLoading(true);

        //NOTE: 프롬프트에 자신의 닉네임 추가
        selectedTitleKr.unshift(USER_NAME);

        let body = {
            prompt: selectedTitleKr,
        };
        console.log('@@prompt => ', body);

        const response = await callChatGPT(
            USER_ID,
            trainingType,
            trainingId,
            body
        );
        if (response.success) {
            console.log('callChatGPT => ', response.data);
            setTimeout(() => {
                console.log('AIResult => ', response.data[0].url);
                const result = response.data.replace(/"/g, '');
                setAIResult(result);
                setAIResultList((prev) => [...prev, result]);
                // setStep((prevIndex) => {
                //     return ++prevIndex;
                // });
                setStep(finalStep - 1); // 결과 화면으로 이동

                if (writingAudioRef.current) {
                    writingAudioRef.current.play().catch((error) => {
                        console.error('오디오 재생에 실패했습니다.', error);
                    });
                }
                setShowLoading(false);
                // setShowPromptDiscussionBox(true);
            }, 10000); // 10000ms = 10초
        } else {
            console.log(response.error.errMsg);
            console.log(response.error);
            setShowLoading(false);
            if (response.error.errCode === 'Magicbean_ERR_00') {
                setShowErrorModal(true);
                setErrorMessage(response.error.errMsg);
            } else {
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
            }
        }
    };

    const handleSaveButtonClick = async (selectedResults) => {
        console.log('selectedResults: ', selectedResults);
        setShowSaveLoading(true); // 로딩 시작

        // 결과를 저장하기 위한 반복문
        for (const selectedResult of selectedResults) {
            const response = await saveCreation(
                USER_ID,
                trainingType,
                trainingTopicTitle,
                selectedResult,
                trainingId
            );

            if (!response.success) {
                console.log(response.error);
                setShowSaveLoading(false); // 로딩 상태 종료
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
                return; // 오류가 발생하면 루프를 빠져나오고 함수를 종료합니다.
            }

            console.log('Save => ', response.data);
        }

        setShowSaveLoading(false); // 로딩 상태 종료
        // setShowComplete(true);
    };

    const handleWordClick = (index) => {
        setIsRevisiting(true);
        setRevisitingIndex(index);
        setStep(index * 2 + 2);
        setSelectionStep(index);
    };

    console.log('다시 방문??22222', isRevisiting);
    console.log('revisitingIndex?????22222', revisitingIndex);

    return (
        <>
            <TrainingHeader hubPath="/whub/writing" />
            <SchoolTitle school_name={USER_SCHOOLNAME} user_type={USER_TYPE} />

            {showLoading && (
                <Loading
                    type={trainingType}
                    selectedTitleKr={selectedTitleKr.slice(1)}
                ></Loading>
            )}
            {showSaveLoading && <ShortLoading></ShortLoading>}
            {showErrorModal && (
                <ErrorModal
                    error_message={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                ></ErrorModal>
            )}
            {/* {showComplete && (
                <CompleteModal
                    user_name={USER_NAME}
                    onClose={() => setShowComplete(false)}
                ></CompleteModal>
            )} */}
            <audio
                ref={writingAudioRef}
                src="/audio/twinkle-writing.mp3"
            />

            {step === 0 && (
                <TrStartComponent
                    onNext={handleNextButtonClick}
                    training_topic_title={trainingTopicTitle}
                />
            )}
            {step === 1 && (
                <TrGuideComponent
                    onNext={handleNextButtonClick}
                    onPrevious={handlePreviousButtonClick}
                    training_description={trainingDescription}
                    selected_magickid={selectedmagickid}
                />
            )}
            {/* NOTE : 0716 Merry Test */}
            {/* {step >= 2 && step % 2 == 0 && step < finalStep - 2 && (
                <TrWritingSelectionComponent
                    onNext={handleNextButtonClick}
                    onPrevious={handlePreviousButtonClick}
                    title={selectionList[selectionStep].category}
                    selection_list={selectionList[selectionStep]}
                    selected_magickid={selectedmagickid}
                />
            )} */}
            {step >= 2 &&
                step % 2 == 0 &&
                step < finalStep - 2 &&
                selectionList &&
                selectionStep < selectionList.length &&
                trainingCategory === '수수께끼' ? (
                <TrQuestionRelay
                    onNext={handleNextButtonClick}
                    onPrevious={handlePreviousButtonClick}
                    title={selectionList[selectionStep].category}
                    training_id={trainingId}
                    selection_list={selectionList[selectionStep]}
                    selected_magickid={selectedmagickid}
                    onEnd={handleEndQuesionRelay}
                />
            ) : step >= 2 &&
                step % 2 == 0 &&
                step < finalStep - 2 &&
                selectionList &&
                selectionStep < selectionList.length ? (
                <TrWritingSelectionComponent
                    onNext={handleNextButtonClick}
                    onPrevious={handlePreviousButtonClick}
                    title={selectionList[selectionStep].category}
                    selection_list={selectionList[selectionStep]}
                    selected_magickid={selectedmagickid}
                />
            ) : null}

            {step >= 2 && step % 2 != 0 && step < finalStep - 2 && (
                <>
                    <TrAfterSelectionComponent
                        onNext={handleNextButtonClick}
                        onPrevious={handlePreviousButtonClick}
                        prev_title={
                            selectionStep > 0 &&
                                selectionList.length > selectionStep - 1
                                ? selectionList[selectionStep - 1].category
                                : ''
                        }
                        next_title={
                            selectionStep >= 0 &&
                                selectionStep < selectionList.length
                                ? selectionList[selectionStep].category
                                : ''
                        }
                        // merrtTest
                        selectedTitleKr={selectedTitleKr[selectionStep - 1]}
                        selected_magickid={selectedmagickid}
                    />
                </>
            )}
            {step === finalStep - 2 && (
                <>
                    <TrFinishSelectionComponent
                        onNext={executeAI}
                        onPrevious={handlePreviousButtonClick}
                        selected_magickid={selectedmagickid}
                        prompt_content={promptContent}
                        // selected_kr_list={selectedTitleKr}
                        selected_kr_list={selectedTitleKr}
                        selectedType="writing"
                    />
                </>
            )}
            {step === finalStep - 1 && (
                <>
                    <TrWritingResultComponent
                        onRegenerate={handleRegenerate}
                        onResult={handleSaveButtonClick}
                        onNext={handleNextButtonClick}
                        selectedKRList={selectedTitleKr}
                        resultContent={AIResult}
                        resultContentList={AIResultList}
                        selected_magickid={selectedmagickid}
                        onWordClick={handleWordClick}
                    />
                </>
            )}
            {step === finalStep && (
                <>
                    {/* NOTE: 2024-05-13 Writing 컴포넌트 필요 (그림이 아니라, 글이 보이게) */}
                    <TrDiscussionTalkingComponent
                        onNext={handleNextButtonClick}
                        onPrevious={handlePreviousButtonClick}
                        selected_magickid={selectedmagickid}
                        discussionTopic={discussionTopic}
                        discussionExample={discussionExample}
                        AIResultList={AIResultList}
                        TalkingType="writing"
                    />
                </>
            )}
            {step > finalStep && (
                <>
                    <TrCompleteComponent
                        onRestart={handleRestart}
                        onEnd={handleGoHubWriting}
                        selected_magickid={selectedmagickid}
                    />
                </>
            )}
        </>
    );
};

export default WritingTraining;
