import React, { useEffect, useState } from 'react';
import '../styles/LandScapeModal.scss';

const LandScapeModal = () => {
    const checkOrientation = () => {
        return window.matchMedia('(orientation: portrait)').matches;
    };

    const [isVisible, setIsVisible] = useState(() => {
        const userClosedModal = localStorage.getItem('userClosedModal');
        return userClosedModal ? false : checkOrientation();
    });

    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem('userClosedModal', 'true');
    };

    useEffect(() => {
        const handleResize = () => {
            const userClosedModal =
                localStorage.getItem('userClosedModal') === 'true';
            if (!userClosedModal) {
                setIsVisible(checkOrientation());
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const modalElement = document.querySelector('.landscape-modal');
        if (modalElement) {
            modalElement.style.display = isVisible ? 'block' : 'none';
        }
    }, [isVisible]);

    return (
        <section className="landscape-modal">
            <div className="inner">
                <figure>
                    <img
                        src={'/images/landscape-mk.svg'}
                        alt="landscape"
                    />
                </figure>
                <div className="land-txt-container">
                    <p>
                        매직포유를 더 크고 넓게 보려면 태블릿을 가로로 해볼까요?
                    </p>
                    <button onClick={handleClose}>확인</button>
                </div>
            </div>
        </section>
    );
};

export default LandScapeModal;
