import React, { useEffect } from 'react';
import '../styles/RemovalCompleteModal.scss';

const RemovalCompleteModal = ({ onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 2000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <section className="removal-section">
            <div className="inner">
                <div className="removal-box">
                    <figure>
                        <img
                            src={'/images/delete-complete.svg'}
                            alt=""
                        />
                    </figure>
                    <p>작품이 삭제 되었어요!</p>
                    <button onClick={onClose}>확인</button>
                </div>
            </div>
        </section>
    );
};

export default RemovalCompleteModal;
