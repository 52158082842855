import React, { useState, useEffect, useRef } from 'react';
import '../styles/TrDiscussionTalkingComponent.scss';

const TrDiscussionTalkingComponent = ({
    onNext,
    onPrevious,
    discussionTopic,
    selected_magickid,
    AIResultList,
    TalkingType,
    discussionExample,
}) => {
    const [selectedButton, setSelectedButton] = useState({});
    const [discussionButton, setDiscussionButton] = useState(false);
    const [pictureButton, setPictureButton] = useState(false);
    const aimagickid = {
        arial: '/images/bubble-arial.svg',
        nikki: '/images/bubble-niki.svg',
    };
    const discussionBubbleRef = useRef(null);
    const pictureBubbleRef = useRef(null);
    const writingBubbleRef = useRef(null);
    const exampleRef = useRef(null);

    console.log('@@@discussionTopic', discussionTopic);
    console.log('TalkingType', TalkingType);
    console.log('AIResultList', AIResultList);
    console.log('discussionExample', discussionExample);
    console.log('discussionTopic', discussionTopic);

    useEffect(() => {
        if (discussionButton && discussionBubbleRef.current) {
            discussionBubbleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [discussionButton]);

    useEffect(() => {
        if (pictureButton && exampleRef.current) {
            exampleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pictureButton]);

    useEffect(() => {
        if (pictureButton && writingBubbleRef.current) {
            writingBubbleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pictureButton]);

    return (
        // NOTE: 2024-05-02 전체 글씨크기 키움 필요.
        <div className="tr-bg drawing-talking">
            <div className="inner">
                <div className="tr-box drawing-talking">
                    <div className="complete-txt-container">
                        <p className="title-text">함께 이야기 해볼까요?</p>
                        <div className="divider-line"></div>
                    </div>
                    <div className="bubble-area">
                        <div
                            className="result-char-bubble"
                            ref={discussionBubbleRef}
                        >
                            <figure>
                                <img
                                    src={aimagickid[selected_magickid]}
                                    alt="캐릭터"
                                />
                            </figure>
                            <div className="complete-bubble">
                                자, AI로 만든 그림을 보고나니 어때?
                                <br />
                                우리 내가 알려주는 주제로 이야기해볼까?
                            </div>
                        </div>
                        {/* NOTE: 2024-05-02 수정 필요 : 안에 버튼이면 좋겠어요. */}
                        <div
                            className="user-reply reply"
                            ref={pictureBubbleRef}
                        >
                            <button onClick={() => setDiscussionButton(true)}>
                                해볼래요!
                            </button>
                        </div>
                        {discussionButton && (
                            <>
                                <div className="result-char-bubble">
                                    <figure>
                                        <img
                                            src={aimagickid[selected_magickid]}
                                            alt=""
                                        />
                                    </figure>
                                    <div className="complete-bubble">
                                        {discussionTopic}
                                    </div>
                                </div>
                                {TalkingType === 'drawing' && (
                                    <>
                                        <div className="result-char-bubble">
                                            <figure>
                                                <img
                                                    src={
                                                        aimagickid[
                                                            selected_magickid
                                                        ]
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <div className="complete-bubble">
                                                그림을 보면서 얘기해볼까?!
                                            </div>
                                        </div>
                                        <div className="user-reply reply">
                                            <button
                                                onClick={() =>
                                                    setPictureButton(true)
                                                }
                                            >
                                                좋아요!
                                            </button>
                                        </div>
                                        {pictureButton && (
                                            <>
                                                <div
                                                    className="result-char-bubble"
                                                    ref={exampleRef}
                                                >
                                                    {' '}
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble">
                                                        {discussionTopic}
                                                    </div>
                                                </div>
                                                <div className="result-char-bubble">
                                                    {' '}
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble">
                                                        아래 예시를 보면서 같이
                                                        이야기 해보자!
                                                    </div>
                                                </div>
                                                <div className="result-char-bubble">
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble">
                                                        <p>
                                                            "
                                                            {
                                                                discussionExample[0]
                                                            }
                                                            "
                                                        </p>
                                                        <p>
                                                            "
                                                            {
                                                                discussionExample[1]
                                                            }
                                                            "
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="result-char-bubble compare"
                                                    ref={pictureBubbleRef}
                                                >
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble compare">
                                                        <p>
                                                            이제 그림을 보면서
                                                            함께 이야기해볼까?
                                                        </p>
                                                        {AIResultList.map(
                                                            (image, index) => {
                                                                if (
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                ) {
                                                                    const nextImage =
                                                                        AIResultList[
                                                                            index +
                                                                                1
                                                                        ];
                                                                    return (
                                                                        <div
                                                                            className="compare-pictures"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <figure className="picture picture_1">
                                                                                <img
                                                                                    src={
                                                                                        image
                                                                                    }
                                                                                    alt={`Comparison Image ${index}`}
                                                                                />
                                                                            </figure>
                                                                            {nextImage && (
                                                                                <figure className="picture picture_2">
                                                                                    <img
                                                                                        src={
                                                                                            nextImage
                                                                                        }
                                                                                        alt={`Comparison Image ${
                                                                                            index +
                                                                                            1
                                                                                        }`}
                                                                                    />
                                                                                </figure>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                                return null; // 홀수 인덱스의 이미지는 처리하지 않습니다.
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                {TalkingType === 'writing' && (
                                    <>
                                        <div className="result-char-bubble">
                                            <figure>
                                                <img
                                                    src={
                                                        aimagickid[
                                                            selected_magickid
                                                        ]
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                            <div className="complete-bubble">
                                                글을 보면서 얘기해볼까?!
                                            </div>
                                        </div>
                                        <div className="user-reply reply">
                                            <button
                                                onClick={() =>
                                                    setPictureButton(true)
                                                }
                                            >
                                                좋아요!
                                            </button>
                                        </div>
                                        {pictureButton && (
                                            <>
                                                <div
                                                    className="result-char-bubble"
                                                    ref={exampleRef}
                                                >
                                                    {' '}
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble">
                                                        {discussionTopic}
                                                    </div>
                                                </div>
                                                <div className="result-char-bubble">
                                                    {' '}
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble">
                                                        아래 예시를 보면서 같이
                                                        이야기 해보자!
                                                    </div>
                                                </div>
                                                <div className="result-char-bubble">
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble">
                                                        <p>
                                                            "
                                                            {
                                                                discussionExample[0]
                                                            }
                                                            "
                                                        </p>
                                                        <p>
                                                            "
                                                            {
                                                                discussionExample[1]
                                                            }
                                                            "
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="result-char-bubble compare"
                                                    ref={writingBubbleRef}
                                                >
                                                    <figure>
                                                        <img
                                                            src={
                                                                aimagickid[
                                                                    selected_magickid
                                                                ]
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="complete-bubble compare">
                                                        <p>
                                                            AI가 쓴 글이 어때?
                                                        </p>
                                                        {/* {AIResultList.map(
                                                            (image, index) => {
                                                                if (
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                ) {
                                                                    const nextImage =
                                                                        AIResultList[
                                                                            index +
                                                                                1
                                                                        ];
                                                                    return (
                                                                        <div
                                                                            className="compare-texts"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <div className="text-result">
                                                                                <p className="text-result-contents">
                                                                                    {
                                                                                        AIResultList
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null; // 홀수 인덱스의 이미지는 처리하지 않습니다.
                                                            }
                                                        )} */}
                                                        {AIResultList.map(
                                                            (result, index) => (
                                                                <div
                                                                    className="compare-texts"
                                                                    key={index}
                                                                >
                                                                    <div className="text-result">
                                                                        <p className="text-result-contents">
                                                                            {
                                                                                result
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="step-btns">
                    <div
                        className="button button-prev"
                        onClick={onPrevious}
                    >
                        ◀ 이전
                    </div>
                    <div
                        className="button button-next"
                        onClick={() => onNext(selectedButton)}
                    >
                        다음 ▶
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrDiscussionTalkingComponent;
