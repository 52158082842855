import React, { useState, useEffect } from 'react';

const TextToSpeech = ({ text, enabledButton }) => {
    const [isPaused, setIsPaused] = useState(false);
    const [utterance, setUtterance] = useState(null);

    useEffect(() => {
        if (text != null) {
            text = text.replaceAll('@', '');
        }

        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(text);

        // 새로운 텍스트로 음성 합성을 시작합니다.
        if (text && !enabledButton) {
            synth.cancel(); // 현재 진행 중인 음성 합성을 중지합니다.
            synth.speak(utterance); // 새로운 텍스트로 음성 합성을 시작합니다.
        }

        return () => {
            synth.cancel(); // 컴포넌트가 언마운트되거나 text가 변경될 때, 음성 합성을 중지합니다.
        };
    }, [text]);

    const handlePlay = () => {
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(text);
        if (isPaused) {
            synth.resume();
        }
        synth.speak(utterance);
        setIsPaused(false);
    };

    const handlePause = () => {
        const synth = window.speechSynthesis;
        synth.pause();
        setIsPaused(true);
    };

    const handleStop = () => {
        const synth = window.speechSynthesis;
        synth.cancel();
        setIsPaused(false);
    };

    // 2024-02-06 | return 간소화 및 이미지 태그로 변경
    return enabledButton ? (
        <div className="play-state-btn">
            <button onClick={handlePlay}>
                <img
                    src={
                        isPaused
                            ? '/images/music-off.svg'
                            : '/images/music-on.svg'
                    }
                    alt={isPaused ? '멈추기' : '재생하기'}
                />
            </button>
            <button onClick={handleStop}>
                <img
                    src={'/images/music-off.svg'}
                    alt=""
                />
            </button>
        </div>
    ) : null;
    // return ( <></> );
};

export default TextToSpeech;
