import React from 'react';

function RenderTextWithBreaks({ text }) {
    const separators = /(\.|\n|!|\?|@)/;
    const textParts = text
        .split(separators)
        .filter((part) => part.trim() !== '');
    return (
        <div className="writing-text-result">
            {' '}
            {textParts.map((part, index) => {
                // 마지막 요소가 아니고, 다음 요소가 구분자인 경우, 현재 요소와 구분자를 붙여서 출력
                if (
                    index < textParts.length - 1 &&
                    ['.', '!', '?', '~'].includes(textParts[index + 1])
                ) {
                    return <p key={index}>{part + textParts[index + 1]}</p>;
                }
                else if (index < textParts.length - 1 &&
                    ['@'].includes(textParts[index + 1])) {
                    return <p key={index}>{part}</p>;
                }
                // 구분자 요소를 건너뛰기 위한 처리
                else if (['.', '!', '?', '~', '@'].includes(part)) {
                    return null; // 구분자는 이미 이전 요소에 붙여서 처리했으므로 여기서는 출력하지 않음
                }
                // 일반 텍스트 요소
                else {
                    return <span key={index}>{part}</span>;
                }
            })}
        </div>
    );
}

export default RenderTextWithBreaks;

// import React from 'react';

// function RenderTextWithBreaks({ text }) {
//     const separators = /(\.|\n|!|\?|\~)/;
//     const textParts = text.split(separators).filter((part) => part.trim() !== '');

//     return (
//         <>
//             {textParts.map((part, index) => {
//                 const isFirstElement = index === 0;
//                 const isLastElement = index === textParts.length - 1;
//                 const isSeparator = part === '.' || part === '!' || part === '?' || part === '~';

//                 // 인라인 스타일 정의
//                 const style = (isFirstElement || isLastElement) ? { textIndent: '2em' } : {};

//                 if (isSeparator && index < textParts.length - 1) {
//                     return <p style={style} key={index}>{part + textParts[index + 1]}</p>;
//                 } else if (isSeparator) {
//                     return null;
//                 } else {
//                     return <span style={style} key={index}>{part}</span>;
//                 }
//             })}
//         </>
//     );
// }

// export default RenderTextWithBreaks;
