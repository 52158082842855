import React from 'react';
import '../styles/Loading.scss';
import RenderTextWithBreaks from './RenderTextWithBreaks';

const Loading = ({ flag, selected_magickid }) => {
    console.log('selected_magickid', selected_magickid);

    const getLoadingImageSrc = (magickid) => {
        switch (magickid) {
            case 'nikki':
                return '/images/char-3.svg';
            case 'arial':
                return '/images/loading-char.svg';
            default:
                return '/images/char-3.svg';
        }
    };

    return (
        <>
            <section className="loading-section">
                <div className="inner">
                    <figure className="loading-robot">
                        <img
                            src={getLoadingImageSrc(selected_magickid)}
                            alt="loading-robot"
                        />
                    </figure>
                    {/* {type==="writing" && <p className="loading-txt"><RenderTextWithBreaks text={loading_writing_text[random_index_writing]} /></p>}
                    {type==="drawing" && <p className="loading-txt"><RenderTextWithBreaks text={loading_drawing_text[random_index_drawing]} /></p>} */}
                    {flag === 'generative' ? (
                        <p className="loading-txt">
                            AI가 수수께끼를 위한 질문을 생각하고 있어요. 조금만
                            기다려주세요!
                        </p>
                    ) : (
                        <p className="loading-txt">
                            만든 작품을 저장하고 있어요. 조금만 기다려주세요!
                        </p>
                    )}
                </div>
            </section>
        </>
    );
};

export default Loading;
