import React from 'react';
import '../styles/ConfirmDeleteModal.scss';

const ConfirmDeleteModal = ({ onConfirm, onCancel }) => {
    return (
        <section className="confirm-section">
            <div className="inner">
                <div className="confirm-box">
                    <p>정말로 삭제할까요?</p>
                    <div className="confirm-btn-container">
                        <button
                            className="btn-cancel"
                            onClick={onCancel}
                        >
                            <figure>
                                <img
                                    src={'/images/icon-cancel.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>취소하기</span>
                        </button>
                        <button
                            className="btn-delete"
                            onClick={onConfirm}
                        >
                            <figure>
                                <img
                                    src={'/images/icon-delete.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>삭제하기</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ConfirmDeleteModal;
