import React from 'react';
import '../styles/ContestConts.scss';
import TextToSpeech from './TextToSpeech';
import { useNavigate } from 'react-router-dom';
import { checkSession } from '../utils/SessionCheck';

const StorybookList = ({ storybookList, onReading }) => {
    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const LOGIN_TIME = localStorage.getItem('loginTime');
    const navigation = useNavigate();

    console.log('@@@@@@@@@@@ storybookList: ', storybookList);
    const printDate = (date) => {
        if (!date) return;
        const result_date = date.split('T');
        return result_date[0];
    };

    const handleStory4UClick = () => {
        if (checkSession()) {
            const params = {
                userID: USER_ID,
                userName: USER_NAME,
                loginTime: LOGIN_TIME,
                page: 'selecting',
            };
            const query = new URLSearchParams(params).toString();
            window.open(
                `${story4uURL}:${story4uPort}/check?${query}`,
                '_blank',
                'noopener,noreferrer'
            );
        } else {
            navigation('/logout');
        }
    };

    if (storybookList.length === 0) {
        return (
            <>
                <div className="empty-contents">
                    <figure className="story">
                        <img
                            src={'/images/twins_5_test.svg'}
                            alt=""
                        />
                    </figure>
                    <p>
                        아직 그림동화 작품이 없어요!
                        <br className="mobile-only" />
                        <span>작품을 만들러 가볼까요?</span>
                    </p>
                    <button onClick={handleStory4UClick}>
                        동화만들기 체험하러 가기
                    </button>
                </div>
            </>
        );
    }
    return (
        <ul className="story4u-contents">
            {storybookList.map((creation, index) => {
                return (
                    <li
                        key={index}
                        className="con-item"
                    >
                        <figure
                            className="book-cover"
                            onClick={() => onReading(creation.book_id)}
                        >
                            <img
                                src={creation.bookcover}
                                alt="동화책 커버"
                            />
                        </figure>
                        <figure
                            className="book-bottom"
                            onClick={() => onReading(creation.book_id)}
                        >
                            <img
                                src="/images/book-cover-bottom.svg"
                                alt="동화책 책 모양 배경"
                            />
                        </figure>
                        <div
                            className="book-name-container"
                            onClick={() => onReading(creation.book_id)}
                        >
                            <h3 className="book-title">
                                {creation.title}
                                <div className="book-title-stroke">
                                    {creation.title}
                                </div>
                            </h3>
                            <p className="work-name">
                                [ {creation.name} ]
                                <div className="book-title-stroke">
                                    [ {creation.name} ]
                                </div>
                            </p>
                            <p className="work-date">
                                {printDate(creation.creation_date)}
                                <div className="book-title-stroke">
                                    {printDate(creation.creation_date)}
                                </div>
                            </p>
                        </div>
                        <TextToSpeech
                            text={creation.title}
                            enabledButton={true}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default StorybookList;
