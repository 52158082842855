import React from 'react';
import '../styles/OptionChangeModal.scss';

const OptionChangeModal = ({
    show,
    onConfirm,
    onCancel,
    optionText,
    selected_magickid,
}) => {
    if (!show) return null;

    const imageSrc =
        selected_magickid === 'arial'
            ? '/images/await-arial.png'
            : '/images/await-nikki.png';

    return (
        <div className="modal-overlay">
            <figure
                className={`await-char ${
                    selected_magickid === 'nikki' ? 'nikki-class' : ''
                }`}
            >
                <img
                    src={imageSrc}
                    alt=""
                />
            </figure>
            <div className="modal-content">
                <h3>
                    <span>"{optionText}"</span> 선택지를 바꿀까요?
                </h3>
                <div className="modal-buttons">
                    <button
                        className="change-yes"
                        onClick={onConfirm}
                    >
                        <figure>
                            <img
                                src={'/images/o.svg'}
                                alt=""
                            />
                        </figure>
                        <span>예</span>
                    </button>
                    <button
                        className="change-no"
                        onClick={onCancel}
                    >
                        <figure>
                            <img
                                src={'/images/x.svg'}
                                alt=""
                            />
                        </figure>
                        <span>아니오</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OptionChangeModal;
