import React, { useEffect } from 'react';
import '../styles/AnswerWrongModal.scss';

const AnswerWrongModal = ({ onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 1500); // NOTE : 1.5초만에 닫히는걸로 해놨는데 타임 더 길게 가져갈지 상의하기!

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <section className="wrong-section">
            <div className="inner">
                <h2>정답을 맞추지 못했어요! 다시 풀어볼까요?</h2>
                <figure>
                    <img
                        src={'/images/wrong.svg'}
                        alt="Wrong"
                    />
                </figure>
                <button onClick={onClose}>다시 정답 맞추러가기</button>
            </div>
        </section>
    );
};

export default AnswerWrongModal;
