import React, { useEffect } from 'react';
import '../styles/KeyFunctions.scss';
import Header from '../components/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import InqueryButton from '../components/InqueryButton';

const KeyFunctions = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleManualClick = (manualType) => {
        navigate(`/test?manual=${manualType}`);
    };

    return (
        <div id="wrap">
            <Header />
            <section className="key-section-1">
                <div
                    className="inner flex"
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <h2 className="tit">
                            쉽고 재미있는 AI 놀이터,
                            <br />
                            AI 매직포유
                        </h2>
                        <p>즐겁고 안전한 체험으로 AI와 친구가 되어보아요.</p>
                        <button
                            className="requst-btn"
                            onClick={() => {
                                navigate('/contact');
                            }}
                        >
                            도입문의
                        </button>
                    </div>
                    <div className="right">
                        <figure>
                            <img
                                src={'/images/function/function-1.svg'}
                                alt="section-1-image"
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="key-section-2">
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <div className="en-txt">
                            <figure>
                                <img
                                    src={'/images/function/palette.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>Drawing AI</span>
                        </div>
                        <h2>그리기 AI</h2>
                        <ul>
                            <li>
                                캐릭터, 풍경, 상상 그리기 등
                                <br className="pc-only" /> 다양한 체험 진행
                            </li>
                            <li>
                                그리기 AI와 함께 상상 속
                                <br className="pc-only" />{' '}
                                <em>나만의 세상을 펼처보아요.</em>
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <figure className="floating-image">
                            <img
                                src={'/images/function/function-2-1.svg'}
                                alt=""
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="key-section-3">
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <figure className="floating-image">
                            <img
                                src={'/images/function/function-3.svg'}
                                alt=""
                            />
                        </figure>
                    </div>
                    <div className="right">
                        <div className="en-txt">
                            <figure>
                                <img
                                    src={'/images/function/book.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>Creative fairy tale</span>
                        </div>
                        <h2>창작동화</h2>
                        <ul>
                            <li>
                                나의 선택으로 나만의 그림동화를
                                <br className="pc-only" /> 만들어보아요.
                            </li>
                            <li>
                                세상을 동심으로 물들일 동화작가가
                                <br className="pc-only" />{' '}
                                <em>AI매직포유로 되어보아요.</em>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* 여기에 스토리랜드 key-section-6 */}
            <section className="key-section-6">
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="left">
                        <div className="en-txt">
                            <figure>
                                <img
                                    src={'/images/function/storyland.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>Story Land</span>
                        </div>
                        <h2>스토리랜드</h2>
                        <ul>
                            <li>
                                생성형 AI로 만든 멋진 동화들을
                                <br className="pc-only" />
                                감상 해보세요!
                            </li>
                            <li>
                                시리즈 동화부터 단편 동화까지
                                <br className="pc-only" />{' '}
                                <em>재미있는 동화를 마음껏 볼 수 있어요.</em>
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <figure className="floating-image">
                            <img
                                src={'/images/function/function-6.svg'}
                                alt=""
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="key-section-4">
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="right">
                        <figure className="floating-image">
                            <img
                                src={'/images/function/function-4-1.svg'}
                                alt=""
                            />
                        </figure>
                    </div>
                    <div className="left">
                        <div className="en-txt">
                            <figure>
                                <img
                                    src={'/images/function/edit.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>Writing AI</span>
                        </div>
                        <h2>글 만들기 AI</h2>
                        <ul>
                            <li>
                                대화, 편지, 삼행시 등
                                <br className="pc-only" /> 다양한 글쓰기 체험
                                진행
                            </li>
                            <li>
                                글 만들기 AI와 함께라면
                                <br className="pc-only" />{' '}
                                <em>나는야, 언어마술사</em>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="key-section-5">
                <div
                    className="inner flex"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                >
                    <div className="right">
                        <div className="en-txt">
                            <figure>
                                <img
                                    src={'/images/function/target.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>Discussion</span>
                        </div>
                        <h2>생각의 확장</h2>
                        <ul>
                            <li>
                                창작 결과물에 대해 친구들과 함께
                                <br className="pc-only" /> 토의해보며 생각을
                                확장해요.
                            </li>
                            <li>
                                친구들의 작품과 내작품, AI작품과 내작품을
                                <br className="pc-only" />{' '}
                                <em>비교하며 상상력을 키워요.</em>
                            </li>
                        </ul>
                    </div>
                    <div className="left">
                        <figure className="floating-image">
                            <img
                                src={'/images/function/function-5.svg'}
                                alt=""
                            />
                        </figure>
                    </div>
                </div>
            </section>
            <section className="go-guide-section gray">
                <div className="inner">
                    <h3>더 많은 활용 방법을 알고싶다면?</h3>
                    <figure className="arrow">
                        <img
                            src={'/images/down-arrow.svg'}
                            alt=""
                        />
                    </figure>
                    <div className="guide-container">
                        <div
                            className="guide-btn"
                            onClick={() => handleManualClick('teacher')}
                        >
                            선생님 메뉴얼
                        </div>
                        <div
                            className="guide-btn"
                            onClick={() => handleManualClick('drawing')}
                        >
                            그림 그리기 AI 메뉴얼
                        </div>
                        <div
                            className="guide-btn"
                            onClick={() => handleManualClick('writing')}
                        >
                            글 만들기 AI 메뉴얼
                        </div>
                    </div>
                </div>
            </section>
            <InqueryButton />
            <Footer />
        </div>
    );
};

export default KeyFunctions;
