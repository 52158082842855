import React from 'react';
import '../styles/TrFavoriteComponent.scss';
import { useNavigate } from 'react-router-dom';

const TrFavoriteComponent = ({ data }) => {
    const navigate = useNavigate();

    const handleItemClick = (item) => {
        const { training_type, training_id } = item;
        const path =
            training_type === 'drawing'
                ? `/drawingtraining/drawing/${training_id}`
                : `/writingtraining/writing/${training_id}`;
        navigate(path);
    };

    console.log('data', data);

    return (
        <div className="inner">
            <ul className="fav-list-all">
                {data.map((item) => (
                    <li
                        key={item.id}
                        className={`fav-list-cont ${
                            item.training_type === 'drawing' ? 'drawing' : ''
                        }`}
                        onClick={() => handleItemClick(item)}
                    >
                        <div className="fav-image">
                            <figure>
                                <img
                                    src={item.training_image}
                                    alt={item.text}
                                />
                            </figure>
                            <figure className="fav-heart">
                                <img
                                    src={'/images/heart-filled.svg'}
                                    alt="Unfavorite"
                                />
                            </figure>
                        </div>
                        <div className="fav-text">
                            {item.training_topic_title}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TrFavoriteComponent;
