import React, { useState, useEffect } from 'react';
import '../styles/Home.scss';
import Navigation from '../components/Navigation';
import HomeDashboardConts from '../components/HomeDashboardConts';
import { getTotalMagicbean } from '../api/MagicbeanAPI';
import Floating from '../components/Floating';
import loginSessionCheck from '../components/LoginSessionCheck';
import LandScapeModal from '../components/LandScapeModal';
import { useNavigate } from 'react-router-dom';
import { checkSession } from '../utils/SessionCheck';
import NewHeader from '../components/NewHeader';
import TcDownLoadBtnComponent from '../components/TcDownLoadBtnComponent';
import TermsUpdateCheckModal from '../components/TermsUpdateCheckModal';
import { getTermsCheck, updateTermsCheck } from '../api/UserAPI';

const Home = () => {
    const navigate = useNavigate();
    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const USER_TYPE = localStorage.getItem('userType');
    const USER_SCHOOLNAME = localStorage.getItem('userSchoolName');
    const IS_TEACHER = localStorage.getItem('isTeacher') === 'true';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [logoImage, setLogoImage] = useState('');

    const logos = [
        '/images/logo-6.svg',
        '/images/logo-5.svg',
        '/images/logo-3.svg',
    ];

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[randomIndex]);
    }, []);


    useEffect(() => {
        callgetTermsCheckAPIFunc(); //NOTE: 2024-09-06 개인정보처리방침, 이용약관 재동의를 위한 호출(모두 true가 되면 삭제)
    }, []);

    const callgetTermsCheckAPIFunc = async () => {
        const response = await getTermsCheck(USER_ID);

        if (response.success) {
            console.log("@@@@", response.data);
            if (!response.data.tos_check || !response.data.pp_check) setIsModalOpen(true);
        } else {
            //NOTE: 에러메시지 필요
            console.log(response.error);
        }
    };


    // 모달 닫기
    const handleModalClose = () => {
        console.log("@@close")
        callupdateTermsCheckAPIFunc();
    };

    const callupdateTermsCheckAPIFunc = async () => {
        console.log("@@callupdateTermsCheckAPIFunc")
        const response = await updateTermsCheck(USER_ID);

        if (response.success) {
            setIsModalOpen(false);
        } else {
            //NOTE: 에러메시지 필요
            console.log(response.error);
        }
    };

    // 약관동의 전체 체크 되었는지 확인
    const handleAllChecked = (checked) => {
        setIsAllChecked(checked);
        console.log('isAllChecked', isAllChecked);

    };

    return (
        <>
            <section className="contents home">
                <div className="right-contents home">
                    <NewHeader />
                    <div className="inner">
                        <div className="tit-container flex">
                            <h3 className="tit">
                                {USER_TYPE == 'others' ? (
                                    <>
                                        <em>{USER_SCHOOLNAME}</em>
                                    </>
                                ) : (
                                    <>
                                        {IS_TEACHER
                                            ? '환영합니다. '
                                            : '수업을 열심히 듣고 있네요, '}
                                        <em>{USER_NAME}</em>{' '}
                                        {IS_TEACHER && '선생'}님!
                                    </>
                                )}
                            </h3>
                        </div>
                        <ul className="board-container">
                            <HomeDashboardConts />
                        </ul>
                    </div>
                </div>
                <Floating />
                {IS_TEACHER && <TcDownLoadBtnComponent />}
            </section>
            <LandScapeModal />
            {/* NOTE 개인정보처리방침 모달 */}
            <TermsUpdateCheckModal
                show={isModalOpen}
                onClose={handleModalClose}
                onAllChecked={handleAllChecked}
            />
        </>
    );
};

export default loginSessionCheck(Home);
